/* eslint-disable no-param-reassign */

import { parseCellValue } from '../../../../ValuationsAllocation/util';

const reverseParserCalibration = ({ cells, tableData }) => {
  const calibration_date = cells.A2.value || '';
  const transaction_allocation_method = cells.A3.value || '';
  const public_comps_approach_calibration = cells.A9.value || 0;
  const selectedFinancialVersion = cells.A10.value || 0;
  const ltmRevenue = cells.A11.value || 0;
  const ntmRevenue = cells.A12.value || 0;
  const ltmEbitda = cells.A13.value || 0;
  const ntmEbitda = cells.A14.value || 0;
  const plusCash = cells.A5.value || 0;
  const lessDebt = cells.A6.value || 0;

  const updatedEquityCellValue = parseCellValue({ cell: cells.A7, fieldDecimalPlaces: 0 });
  const updatedEnterpriseCellValue = parseCellValue({ cell: cells.A4, fieldDecimalPlaces: 0 });

  tableData.calibration.calibration_date = calibration_date;
  tableData.calibration.transaction_allocation_method = transaction_allocation_method;
  tableData.calibration.enterprise_value_inputs = updatedEnterpriseCellValue;
  tableData.calibration.equity_value_inputs = updatedEquityCellValue;
  tableData.calibration.public_comps_approach_calibration = public_comps_approach_calibration;

  if (selectedFinancialVersion !== 0) {
    tableData.calibration.financials_version = selectedFinancialVersion;
  } else {
    tableData.calibration.financials_version = null;
  }

  if (tableData.calibration.data_updated === true) {
    tableData.calibration.ltm_revenue_inputs = ltmRevenue;
    tableData.calibration.ntm_revenue_inputs = ntmRevenue;
    tableData.calibration.ltm_ebitda_inputs = ltmEbitda;
    tableData.calibration.ntm_ebitda_inputs = ntmEbitda;
  }
  tableData.calibration.data_updated = true;

  tableData.calibration.plus_cash_inputs = Number(plusCash);
  tableData.calibration.less_debt_inputs = Number(lessDebt);
};

export default reverseParserCalibration;
