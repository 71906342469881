import React from 'react';
import { largeCurrencyFormat, smallCurrencyFormat, weightingPercentFormat } from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { SelectValueViewer } from 'components';
import { underScoreSpaces } from 'pages/Allocation/allocations/utilities/util';
import { EquityAllocationCellCustomData } from 'pages/ValuationsAllocation/allocation/EquityAllocation/config';
import {
  getCollapsableColumns,
  handleHeaderTitleColumn,
} from 'pages/ValuationsAllocation/approaches/ValuationSummary/config/customParser/utils';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import {
  VALUATION_SUMMARY_KEY,
  SHEET_ALIASES_CONSTANTS as VALUATION_SUMMARY_SHEET_ALIASES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { AllocationMethodSelector } from 'pages/ValuationsAllocation/components';
import { generateColumnKey, generateTotalExpression, getExpression, getNumberValue, getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { CellParserParams, EquityAllocationParserParams } from './types';
import {
  handleCapTableSelection,
  handleFutureEquityValue,
  handleMaturity,
  handleRiskFreeRate,
  handleVolatility,
} from './utils';

const {
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  EQUITY_ALLOCATION_SPREADSHEET_TOTAL,
  EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const { getAdjustedColumnNumber, EQUITY_ALLOCATION_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;
const { ENTERPRISE_VALUE, EQUITY_VALUE } = EQUITY_ALLOCATION_SPREADSHEET_HEADERS;

const VALUE_ALLOCATED_TO_SECURITY_CLASS_ROW_NUMBER = 11;

const cellParser = (params: CellParserParams) => {
  const {
    allocationMethodsOptions,
    allocationScenariosQuantity,
    allSecuritiesExpression,
    alphabet,
    colIndex,
    column,
    isUniformCurrency,
    row,
    rowIndex,
  } = params;

  const colNumber = colIndex + 1;
  const rowNumber = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + rowNumber;

  const {
    backsolveDate,
    isApproachWithOPM,
    scenarioId,
    scenarioMethod,
    scenarioRef,
    scenarioType,
    value: columnValue,
  } = getObjectValue(column[row.alias]);

  const { isPresentValuePerShare, isValueAllocatedToSecurityClass } = getObjectValue(row.data);

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  const adjustedColumnNumber = getAdjustedColumnNumber({ colNumber, isUniformCurrency });

  const scenarioEquityValueCustomKey = generateColumnKey({
    id: scenarioId,
    name: VALUATION_SUMMARY_SHEET_ALIASES_CONSTANTS.VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
    prefix: columnLegend,
  });

  // Total Value Allocated to Security Class
  const totalValueAllocatedToSecurityClassExpression = getExpression({
    columnLegend,
    expr: `SUM(${allSecuritiesExpression})`,
  });

  // Parse Cell based on Row alias
  switch (row.alias) {
    case EQUITY_ALLOCATION_SPREADSHEET_HEADER_TITLE:
      handleHeaderTitleColumn({ allocationScenariosQuantity, cell, colNumber, isUniformCurrency });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD:
      cell.dataEditor = props => (
        <AllocationMethodSelector {...props} allocationMethodsOptions={allocationMethodsOptions} />
      );
      cell.forceComponent = true;
      cell.gridType = 'string';
      cell.isRequired = true;
      cell.valueViewer = props => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: SelectValueViewer is not typed
        <SelectValueViewer {...props} options={allocationMethodsOptions} />
      );
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION:
      handleCapTableSelection({ cell, scenarioType });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS:
    case EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS:
    case EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE:
      cell.hidden = true;
      cell.isRequired = false;
      cell.value = '';
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_MATURITY:
      handleMaturity({ cell, isApproachWithOPM, scenarioMethod, scenarioType });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE:
      handleRiskFreeRate({ cell, isApproachWithOPM, scenarioMethod });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY:
      handleVolatility({ cell, isApproachWithOPM, scenarioMethod, scenarioType });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE:
      handleFutureEquityValue({ cell, scenarioType });
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE:
      cell.expr = `=${VALUATION_SUMMARY_KEY}.${scenarioEquityValueCustomKey}`;
      cell.format = largeCurrencyFormat;
      cell.gridType = 'number';
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY:
      cell.dbDecimalPlaces = 1;
      cell.dbType = 'number';
      cell.defaultZero = true;
      cell.format = weightingPercentFormat;
      cell.gridType = 'percentage';
      break;

    case EQUITY_ALLOCATION_SPREADSHEET_TOTAL:
      cell.expr = `=${totalValueAllocatedToSecurityClassExpression}`;
      cell.format = largeCurrencyFormat;
      cell.gridType = 'number';
      break;

    // Rest of the Rows
    default:
      if (isPresentValuePerShare || isValueAllocatedToSecurityClass) {
        cell.format = largeCurrencyFormat;
        cell.gridType = 'number';
        cell.value = getNumberValue(cell.value as number);

        if (isPresentValuePerShare) {
          cell.customKey = `${underScoreSpaces(row.value)}_${columnLegend}`;
          cell.format = smallCurrencyFormat;
        }
      }
      break;
  }

  // Custom Data for Equity Allocation
  cell.data = {
    ...getObjectValue(cell.data),
    backsolveDate,
    isApproachWithOPM,
    scenarioId,
    scenarioRef,
  } as EquityAllocationCellCustomData;

  // Handle Approaches Cells
  if ([ENTERPRISE_VALUE.columnNumber, EQUITY_VALUE.columnNumber].includes(adjustedColumnNumber)) {
    cell.hidden = true;
    cell.isRequired = false;
    cell.readOnly = true;
    cell.value = '';
  }

  // Handle Collapsable Columns
  const { columnId, isParent, parentColumn } = getCollapsableColumns({ colNumber, isUniformCurrency });

  // Set default Cell values
  const {
    allowNegativeValue = true,
    className = '',
    colSpan = 1,
    component = null,
    customKey = null,
    data = {},
    dbDecimalPlaces = 0,
    dbType = 'string',
    defaultZero = false,
    expr = '',
    forceComponent = false,
    format = null,
    gridType = 'string',
    hidden = false,
    readOnly = false,
    value = '',
  } = getObjectValue(cell);

  return {
    [cell.key]: {
      ...cell,
      allowNegativeValue,
      className,
      colSpan,
      columnId,
      columnLegend,
      component,
      customKey,
      data,
      dbDecimalPlaces,
      dbType,
      defaultZero,
      expr: getExpression({ expr, columnLegend }),
      forceComponent,
      format,
      gridType,
      hidden,
      isParent,
      parentColumn,
      readOnly,
      value,
    } as Cell,
  };
};

const customParser = (params: EquityAllocationParserParams) => {
  const { allocationMethodsOptions, allocationScenarios, columns, isUniformCurrency, rowConfig, securities } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];
  const columnsLength = columns.length;

  const allocationScenariosQuantity = allocationScenarios.length;

  const allSecuritiesExpression = generateTotalExpression({
    items: securities,
    skippedIndexes: VALUE_ALLOCATED_TO_SECURITY_CLASS_ROW_NUMBER + 1,
  });

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allocationMethodsOptions,
          allocationScenariosQuantity,
          allSecuritiesExpression,
          alphabet,
          colIndex,
          column,
          columnsLength,
          isUniformCurrency,
          row,
          rowIndex,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
