import { isNil } from 'lodash';
import { NewAllocationScenario } from 'common/types/allocation';
import {
  ALLOCATION_ALLOCATION_METHOD_PREFIX,
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_CSE_KEY,
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHOD_OPM_KEY,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { generateColumnKey, getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { CreateColumns, ValuationSummaryColumn, ValuationSummaryColumnCellData } from './types';

const {
  VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
} = SHEET_ALIASES_CONSTANTS;

const { VALUATION_SUMMARY_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;

const createColumns: CreateColumns = params => {
  const { allocationScenarios, configurations, deletedApproachesIds, isUniformCurrency } = params;

  // Cap Table Enterprise Value
  const capTableEnterpriseValue = configurations?.reduce((accumulator, current) => {
    const { approach, enterpriseValueReference } = getObjectValue(current);
    const { approach_type: approachTypeName, panelId } = getObjectValue(approach);

    return {
      ...accumulator,
      [getStringValue(panelId)]: {
        approachType: getStringValue(approachTypeName),
        panelId: getStringValue(panelId),
        value: getStringValue(enterpriseValueReference),
      } as ValuationSummaryColumnCellData,
    };
  }, {}) as ValuationSummaryColumn;

  // Enterprise Value
  const enterpriseValue = configurations?.reduce((accumulator, current) => {
    const { approach, enterpriseValueReference } = getObjectValue(current);
    const { approach_type: approachTypeName, panelId } = getObjectValue(approach);

    return {
      ...accumulator,
      [getStringValue(panelId)]: {
        approachType: getStringValue(approachTypeName),
        panelId: getStringValue(panelId),
        value: getStringValue(enterpriseValueReference),
      } as ValuationSummaryColumnCellData,
    };
  }, {}) as ValuationSummaryColumn;

  // Equity Value
  const equityValue = configurations?.reduce((accumulator, current) => {
    const { approach } = getObjectValue(current);
    const { approach_type: approachTypeName, panelId } = getObjectValue(approach);

    return {
      ...accumulator,
      [getStringValue(panelId)]: {
        approachType: getStringValue(approachTypeName),
        panelId: getStringValue(panelId),
        value: null, // Calculated by expression (Enterprise Value - Debt + Cash)
      } as ValuationSummaryColumnCellData,
    };
  }, {}) as ValuationSummaryColumn;

  const approachesColumns = [enterpriseValue, equityValue];

  // If Cap Table and Financials are not the same currency, add Cap Table Enterprise Value to Column before Enterprise Value (Financials)
  if (!isUniformCurrency) {
    // Push capTableEnterpriseValue to Column on second position
    approachesColumns.splice(
      VALUATION_SUMMARY_SPREADSHEET_HEADERS.ENTERPRISE_VALUE.columnNumber - 1, // Position
      0, // Delete 0 elements
      capTableEnterpriseValue // Add capTableEnterpriseValue
    );
  }

  // Allocation Scenarios
  const allocationScenariosColumns = getArrayValue(
    allocationScenarios.map(scenario => {
      const {
        allocations_scenarios_weights: scenarioWeights,
        approach_uuid: scenarioApproachId,
        equity_value: scenarioEquityValue,
        id: scenarioId,
        scenario_method: scenarioMethod,
        scenario_ref: scenarioRef,
        scenario_type: scenarioType,
        weighting_probability: weightingProbability,
      } = getObjectValue(scenario as NewAllocationScenario);

      const currentScenario = getObjectValue(scenario as NewAllocationScenario);

      // Handle Removed Approaches
      const updatedScenariosWeights = getArrayValue(
        scenarioWeights?.filter(weight => !deletedApproachesIds.includes(getNumberValue(weight?.valuation_approach_id)))
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: allocations_scenarios_weights is not readonly
      currentScenario.allocations_scenarios_weights = updatedScenariosWeights;

      // Allocation Method
      let allocationMethodKey: string | null = null;

      // Set Allocation Method Key based on Scenario Type
      switch (scenarioType) {
        case ALLOCATION_SCENARIO_TYPE_BACKSOLVE:
        case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT:
        case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES:
          allocationMethodKey = !isNil(scenarioApproachId)
            ? generateColumnKey({
              id: getStringValue(scenarioApproachId),
              prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
            })
            : null;
          break;

        // Handle Current Value Scenario Type
        default:
          switch (scenarioMethod) {
            case ALLOCATION_SCENARIO_METHOD_WATERFALL:
              allocationMethodKey = ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY;
              break;

            case ALLOCATION_SCENARIO_METHOD_CSE:
              allocationMethodKey = ALLOCATION_SCENARIO_METHOD_CSE_KEY;
              break;

            case ALLOCATION_SCENARIO_METHOD_OPM:
              allocationMethodKey = ALLOCATION_SCENARIO_METHOD_OPM_KEY;
              break;

            default:
              break;
          }
          break;
      }

      // Approaches Scenarios Weights
      const approachesScenariosWeights = {} as ValuationSummaryColumn;

      configurations?.forEach(configuration => {
        const { approach } = getObjectValue(configuration);
        const {
          equity_value: approachEquityValue,
          id: approachId,
          panel_id: approachPanelId,
          panelId,
        } = getObjectValue(approach);

        const approachWeight = scenarioWeights?.find(
          scenarioWeight => scenarioWeight?.approach_uuid === approachPanelId
        );

        // Handle Unsaved Approach Weight
        if (isNil(approachWeight) && approachId && approachPanelId) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: allocations_scenarios_weights is not readonly
          currentScenario.allocations_scenarios_weights = [
            ...getArrayValue(scenarioWeights),
            {
              allocation_scenario_id: scenarioId,
              approach_uuid: getStringValue(approachPanelId),
              equity_value: getStringValue(approachEquityValue ?? '0'),
              valuation_approach_id: getNumberValue(approachId),
              weight: '0',
            },
          ];
        }

        let approachScenarioWeight = getNumberValue(approachWeight?.weight);

        // Handle Backsolve, Future Exit, Specified Share Values Weights
        if (
          [
            ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
            ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
            ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
          ].includes(scenarioType)
          && scenarioApproachId?.toString() === approachPanelId?.toString()
        ) {
          // Approach Scenario Weight to 100%
          approachScenarioWeight = 1;
        }

        approachesScenariosWeights[getStringValue(panelId)] = {
          panelId: getStringValue(panelId),
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: approachScenarioWeight,
        } as ValuationSummaryColumnCellData;
      });

      return {
        ...approachesScenariosWeights,
        [VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: allocationMethodKey,
        } as ValuationSummaryColumnCellData,
        [VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: getNumberValue(scenarioEquityValue),
        } as ValuationSummaryColumnCellData,
        [VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: getNumberValue(weightingProbability),
        } as ValuationSummaryColumnCellData,
      } as ValuationSummaryColumn;
    })
  );

  return [...approachesColumns, ...allocationScenariosColumns];
};

export default createColumns;
