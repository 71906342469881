/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RefFileDocGen = {
  filename: string;
  file_type: RefFileDocGen.file_type;
  file_path: string;
  guid: string;
};

export namespace RefFileDocGen {
  export enum file_type {
    CSV = 'csv',
    DOC = 'doc',
    DOCX = 'docx',
    DOCM = 'docm',
    GIF = 'gif',
    JPEG = 'jpeg',
    JPG = 'jpg',
    KEY = 'key',
    NUMBERS = 'numbers',
    PAGES = 'pages',
    PDF = 'pdf',
    PNG = 'png',
    PPT = 'ppt',
    PPTX = 'pptx',
    PPSM = 'ppsm',
    PPTM = 'pptm',
    RTF = 'rtf',
    TXT = 'txt',
    XLS = 'xls',
    XLSX = 'xlsx',
    XLSM = 'xlsm',
    XLSB = 'xlsb',
    ZIP = 'zip',
  }
}
