import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getObjectValue } from 'utillities';
import getRowConfigCalibrationOutputs from './getRowConfigCalibrationOutputs';
import parser from './parser';
import reverseParserCalibrationOutputs from './reverseParserCalibrationOutputs';
import rowTransformerCalibrationOutputs from './rowTransformerCalibrationOutputs';
import createColumnsCalibrationPerformance from '../performance_metrics/config/createColumns/createColumnsCalibrationPerformance';

const createCalibrationOutputsData = ({ calibration, approach, name, isDisabled, financials, comparisons }) => {
  const { valuations_approach_gpc: valuationsApproachGpc } = getObjectValue(approach);

  const columnsPerformance = createColumnsCalibrationPerformance({
    valuationsApproachGpc,
    financials,
    gpcComparisonByDate: comparisons,
  });

  const columns = rowTransformerCalibrationOutputs([]);

  const rowConfig = getRowConfigCalibrationOutputs({
    calibration,
    isDisabled,
    columnsPerformance,
  });

  return new SpreadsheetConfig({
    columns,
    rowConfig,
    parser,
    name,
    tableData: { calibration, columnsPerformance, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    allowConfirmAndDeleteColumn: false,
    reverseParser: reverseParserCalibrationOutputs,
    allowCopyColumn: false,
    allowReorderColumns: false,
    allowCopyRows: !isDisabled,
    allowAddSingleRow: !isDisabled,
    allowDeleteRow: !isDisabled,
  });
};

export default createCalibrationOutputsData;
