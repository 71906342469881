/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import { assign, each, isNil, isNull, isUndefined, mapValues, orderBy } from 'lodash';
import { all, create } from 'mathjs';
import { mathGlobalScope } from 'common/constants';
import { BREAKPOINT_INFINITY } from 'common/constants/cap-table/capTable';
import { BLANK_VALUE } from 'common/constants/general';
import { GRID_NUMBER_CHECKBOX, LEDGER } from 'common/constants/gridType';
import { largeCurrencyFormat } from 'common/formats/formats';
import LinkedList from 'services/LinkedList';
import { doesNotExistValue } from 'utillities/doesNotExistValue';
import formatNumbers from 'utillities/formatNumbers';
import isExpression from 'utillities/isExpression';
import * as customFormulas from '../common/formulas/math.js';

export const validateExp = (trailKeys, expr, state) => {
  let valid = true;

  // extract cell keys from the expression
  const matches = expr ? expr.toString().match(/[(?!)\n[a-zA-Z0-9_]+/g) : [];
  // Remove duplicates in this character class.

  if (matches) {
    matches.forEach(match => {
      if (trailKeys.indexOf(match) > -1) {
        valid = false;
      } else if (state[match]) {
        valid = validateExp([...trailKeys, match], state[match].expr, state);
      }
    });
  }
  return valid;
};

const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const isExactMatch = (str, match) => new RegExp(`\\b${escapeRegExp(match)}\\b`).test(str);

export const shouldAddCurrencySymbol = value => {
  if (isNil(value)) {
    return false;
  }
  value = value.toString();
  if (value.includes(BREAKPOINT_INFINITY)) return false;
  // If the first character is a currency symbol, do not add another one
  const regex = new RegExp(/^\d/);
  return regex.test(value[0]);
};

export const computeExpr = (key, expr, scope = {}, state = {}, dbDecimalPlaces = null) => {
  let value = null;

  // Verify if is not an expression
  if (Array.isArray(expr) || !isExpression(expr.toString())) {
    return {
      // className: '',
      value: expr,
      expr,
    };
  }

  try {
    // If is an expression render new value with math js
    const math = create(all);
    math.import(customFormulas);

    value = math.evaluate(expr.substring(1), scope) || 0; // The expressions could 0/0 and get NaN
    if (!doesNotExistValue(value) && state[key]?.isTotal) {
      value = shouldAddCurrencySymbol(value)
        ? `${formatNumbers({
          format: largeCurrencyFormat,
          currencyCode: state[key]?.currencyCode || null,
          value,
        })}`
        : value;
    }
    value = value === Infinity ? 0 : value;
    if (dbDecimalPlaces) {
      value = value.toFixed(dbDecimalPlaces);
    }
  } catch (e) {
    value = null;
  }
  if (!isNull(value) && validateExp([key], expr, state)) {
    return {
      // className: 'equation',
      value,
      expr,
    };
  }

  return {
    // className: 'error',
    value: 0,
  };
};

const parseCellValue = cell => {
  if (!isUndefined(cell)) {
    if (cell.isTotal) return shouldAddCurrencySymbol(cell.value) ? `${cell.value[0]}${cell.value}` : cell.value;
    if (cell.gridType === 'gridDate') return cell.value;
    if (cell.gridType === GRID_NUMBER_CHECKBOX) return cell;
    if (cell.gridType === LEDGER) return cell.value;
    // eslint-disable-next-line no-restricted-globals
    if (isUndefined(cell) || isNaN(cell.value)) return 0;
    return parseFloat(Number(cell.value));
  }
  return 0;
};

// After computing an expression we need to update
// the scope for the next cell in the list
const updateScope = (key, value, scope) => {
  scope[key] = value;
};

const checkNestedCells = (nestedCells, newState, changeCell, scope, state) => {
  let current = nestedCells.head;
  // Iterate the nested cells until there isn't any nested cell
  while (!isNull(current)) {
    const key = current.data;
    const newCell = newState[key];
    const relatedExpr = newCell.expr;
    const { dbDecimalPlaces } = newCell;

    if (key !== changeCell.key && relatedExpr) {
      // Compute the expression of the related cell
      const computedExpr = computeExpr(key, relatedExpr, scope, state, dbDecimalPlaces);
      newState[key] = assign({}, newCell, computedExpr); // Updating the new state
      updateScope(key, newState[key].value, scope); // Updating the scope
    }

    current = current.next;
  }
};

export const cellUpdate = (newState, changeCell, expr, state, extraScope, nestedCells) => {
  if (newState && changeCell && (expr || nestedCells)) {
    const { key, dbDecimalPlaces } = changeCell;
    const cellsToScope = { ...newState };

    // Use the last cell in the scope to get the number of rows
    const cellsToScopeKeys = Object.keys(cellsToScope);
    const maxKey = cellsToScopeKeys[cellsToScopeKeys.length - 1].substring(1);

    const cellsScope = mapValues(cellsToScope, cell => parseCellValue(cell));

    let updatedCell = null;

    /* cellScope should be after extraScope in order to override
    any outdated value that could be passed on the extraScope */
    /* For custom breakpoints, add $0 as the total of column A to the scope.
    This will be used to update the range when one of the series is updated. */
    const scope = {
      ...extraScope,
      ...cellsScope,
      ...mathGlobalScope,
      [`A${maxKey}`]: '$0\n',
    };

    if (expr || (changeCell.allowEmptyValues && expr === BLANK_VALUE)) {
      // compute expression
      updatedCell = assign({}, changeCell, computeExpr(key, expr, scope, state, dbDecimalPlaces));

      // update the state
      // eslint-disable-next-line no-param-reassign
      newState[key] = updatedCell;
    } else {
      updatedCell = changeCell;
    }

    updateScope(key, parseCellValue(updatedCell), scope);

    // find related cells
    if (nestedCells) {
      checkNestedCells(nestedCells, newState, changeCell, scope, state);
    }
  }

  return newState;
};

// This function will help us to find the related cells for a given cell
// Which means the cells that need to be recalculated when the base cell change
const findRelatedCells = ({ key: baseKey, expr, cells }) => {
  const relatedCells = [];

  each(cells, (cell, key) => {
    if (!isNil(cell.expr) && cell.expr !== '' && typeof cell.expr === 'string') {
      if (
        // if it's a valid expression
        (Array.isArray(expr) || isExpression(cell.expr.toString()))
        // if the expression is related to the updated cell
        && isExactMatch(cell.expr, baseKey)
        // if the cell is not the updated one
        && key !== baseKey
      ) {
        relatedCells.push(cell.key);
      }
    }
  });

  return relatedCells;
};

const getNestedCells = (cell, tmpState) => {
  // This is a list that contains all the cells keys that
  // need to be updated when the current cell changes (A1 -> B2 -> C1 -> C2)
  const nestedCells = new LinkedList();

  // Regardless of the nested cells, there are mathematical operations that should take priority.
  // The evalOrder property of each row (if present) indicates which cells should be updated first.
  const cellsWithEvalOrder = Object.values(tmpState).filter(
    c =>
      c.columnLegend === cell.columnLegend
      && !isUndefined(c.evalOrder)
      // Exclude cells with manual inputs (e.g. shares fully diluted in unissued options)
      && typeof c.value !== 'string'
  );

  const sortedCells = orderBy(cellsWithEvalOrder, 'evalOrder').map(c => c.key);
  sortedCells.forEach(key => nestedCells.add(key));

  const addNestedCell = sourceCell => {
    // Find the array of keys of the related cells
    const relatedCells = findRelatedCells({ ...sourceCell, cells: tmpState });

    // Iterate over all the relacted cells to add them to the list of changes
    relatedCells.forEach(key => {
      // Add the related cell to the next position of the list
      nestedCells.add(key);

      // Repeat process until we have all the related cells in the list
      addNestedCell(tmpState[key]);
    });
  };

  // Add the current cell to the head of the list
  addNestedCell(cell);
  return nestedCells;
};

export const onCellsChanged = async (changes, state, extraScope) => {
  // Make a copy of the state
  const tmpState = { ...state };

  // Check each cell in the state
  await changes.forEach(({ cell, value }) => {
    const nestedCells = getNestedCells(cell, tmpState);
    cellUpdate(tmpState, cell, value, state, extraScope, nestedCells);
  });

  // check each cell with a formula not related to any cell with "@" and
  // also check the related cells to that cell with not related formula
  Object.entries(tmpState).forEach(([, cell]) => {
    // if the cell has readOnly=false, then there is no need to check here
    // because was checked on the above forEach
    if (cell.cellNotRelated && cell.readOnly) {
      const notRelatedNestedCells = getNestedCells(cell, tmpState);
      cellUpdate(tmpState, cell, cell.expr, state, extraScope, notRelatedNestedCells);
    }
  });

  // update the state
  return tmpState;
};
