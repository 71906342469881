/* eslint-disable no-param-reassign */

const reverseParserCalibrationOutputs = ({ cells, tableData }) => {
  tableData.calibration.ltm_revenue_multiple = isFinite(cells.A2.value) ? Number(cells.A2.value) : 0;
  tableData.calibration.ntm_revenue_multiple = isFinite(cells.A3.value) ? Number(cells.A3.value) : 0;
  tableData.calibration.ltm_revenue_multiple_percentile = isFinite(cells.A4.value) ? Number(cells.A4.value) : 0;
  tableData.calibration.ntm_revenue_multiple_percentile = isFinite(cells.A5.value) ? Number(cells.A5.value) : 0;
  tableData.calibration.ltm_revenue_multiple_premium = isFinite(cells.A6.value) ? Number(cells.A6.value) : 0;
  tableData.calibration.ntm_revenue_multiple_premium = isFinite(cells.A7.value) ? Number(cells.A7.value) : 0;
  tableData.calibration.ltm_ebitda_multiple = isFinite(cells.A9.value) ? Number(cells.A9.value) : 0;
  tableData.calibration.ntm_ebitda_multiple = isFinite(cells.A10.value) ? Number(cells.A10.value) : 0;
  tableData.calibration.ltm_ebitda_multiple_percentile = isFinite(cells.A11.value) ? Number(cells.A11.value) : 0;
  tableData.calibration.ntm_ebitda_multiple_percentile = isFinite(cells.A12.value) ? Number(cells.A12.value) : 0;
  tableData.calibration.ltm_ebitda_multiple_premium = isFinite(cells.A13.value) ? Number(cells.A13.value) : 0;
};

export default reverseParserCalibrationOutputs;
