import { PERFORMANCE_METRICS_SPREADSHEET_TITLE } from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics/performanceMetrics';

export const PERFORMANCE_METRICS_SPREADSHEET_ENTERPRISE_VALUE_TITLE = 'ENTERPRISE VALUE';
export const PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_TITLE = 'LTM REVENUE';
export const PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_MULTIPLE_TITLE = 'LTM REVENUE MULTIPLE';
export const PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_GROWTH_TITLE = 'LTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_TITLE = 'NTM REVENUE';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_MULTIPLE_TITLE = 'NTM REVENUE MULTIPLE';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_GROWTH_TITLE = 'NTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_TITLE = 'LTM EBITDA';
export const PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_MULTIPLE_TITLE = 'LTM EBITDA MULTIPLE';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_TITLE = 'NTM EBITDA';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_MULTIPLE_TITLE = 'NTM EBITDA MULTIPLE';
export const PERFORMANCE_METRICS_SPREADSHEET_GROSS_MARGIN_TITLE = 'GROSS MARGIN';
export const PERFORMANCE_METRICS_SPREADSHEET_EBITDA_MARGIN_TITLE = 'EBITDA MARGIN';

export const PERFORMANCE_METRICS_SPREADSHEET_RANK_TITLE = 'RANK';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_REVENUE_SIZE_TITLE = 'REVENUE SIZE';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_LTM_REVENUE_GROWTH_TITLE = 'LTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_NTM_REVENUE_GROWTH_TITLE = 'NTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_PROFITABILITY_TITLE = 'PROFITABILITY';

export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_TITLE = 'VOLATILITY';
export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_1_YEAR_TITLE = '1 YEAR';
export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_2_YEARS_TITLE = '2 YEARS';
export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_5_YEARS_TITLE = '5 YEARS';

export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_COLUMNS = Object.freeze({
  VOLATILITY_1_YEAR: 14,
  VOLATILITY_2_YEARS: 15,
  VOLATILITY_5_YEARS: 16,
});

export const PERFORMANCE_METRICS_SPREADSHEET_COLUMNS = Object.freeze({
  TITLE: 0,
  ENTERPRISE_VALUE: 1,
  LTM_REVENUE: 2,
  LTM_REVENUE_MULTIPLE: 3,
  LTM_REVENUE_GROWTH: 4,
  NTM_REVENUE: 5,
  NTM_REVENUE_MULTIPLE: 6,
  NTM_REVENUE_GROWTH: 7,
  LTM_EBITDA: 8,
  LTM_EBITDA_MULTIPLE: 9,
  NTM_EBITDA: 10,
  NTM_EBITDA_MULTIPLE: 11,
  GROSS_MARGIN: 12,
  EBITDA_MARGIN: 13,
});

export const PERFORMANCE_METRICS_SPREADSHEET_HEADERS = Object.freeze([
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_ENTERPRISE_VALUE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_MULTIPLE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_GROWTH_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_MULTIPLE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_GROWTH_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_MULTIPLE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_MULTIPLE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_GROSS_MARGIN_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_EBITDA_MARGIN_TITLE,
    subtitle: '',
  },
]);
