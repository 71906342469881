import React from 'react';
import { Typography } from '@material-ui/core';
import { HeaderWrapper } from './DocumentGenerationJobPageHeader.styles';
import { HEADER_CONSTANTS } from '../../constants';
import DocumentGenerationJobCreateButton from '../DocumentGenerationJobCreateButton/DocumentGenerationJobCreateButton';

const DocumentGenerationJobPageHeader = () => (
  <HeaderWrapper>
    <Typography variant="h1" style={{ fontWeight: 'bold' }}>
      {HEADER_CONSTANTS.TITLE}
    </Typography>
    <DocumentGenerationJobCreateButton />
  </HeaderWrapper>
);

export default DocumentGenerationJobPageHeader;
