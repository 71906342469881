import { SCENARIO_METHODS } from 'common/constants/allocations';
import { largeCurrencyFormat } from 'common/formats/formats';
import {
  VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
  VALUATIONS_SUMMARY_WEIGHTED_APPROACH,
  VALUATIONS_SUMMARY_WEIGHTED_APPROACH_PLURAL,
} from 'pages/Funds/common/constants/valuationsSummary';
import {
  formatNumbers,
  generateColumnKey,
  getApproachInitials,
  getBooleanValue,
  getNumberValue,
  getStringValue,
  getUniqueElementsByProp,
  shortDate,
} from 'utillities';
import { CreateColumns, ValuationsSummaryColumn } from './types';

const createColumns: CreateColumns = params => {
  const { allocationsStatuses, format, fundAllocations } = params;

  // Valuation Date
  const companiesValuationDate = fundAllocations?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getStringValue(shortDate(current?.allocation?.date)),
      },
    }),
    {}
  ) as ValuationsSummaryColumn;

  // Valuation Status
  const companiesValuationStatus = fundAllocations?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        id: getNumberValue(current?.allocation?.id), // Allocation ID
        value: getNumberValue(allocationsStatuses[getNumberValue(current.allocation?.id)]),
      },
    }),
    {}
  ) as ValuationsSummaryColumn;

  // Enterprise Valuation Method
  const companiesEnterpriseValuationMethod = fundAllocations?.reduce((accumulator, current) => {
    const weightedApproaches = current.allocation?.valuation?.valuations_approaches?.filter(
      valuationsApproach => valuationsApproach.is_weighted
    );

    const uniqueWeightedApproaches = getUniqueElementsByProp({
      elements: weightedApproaches,
      prop: 'approach_type',
    });
    const uniqueWeightedApproachesNames = getUniqueElementsByProp({
      elements: weightedApproaches,
      prop: 'name',
    });
    const uniqueWeightedApproachesInitials = uniqueWeightedApproaches?.map(approach_type =>
      getApproachInitials({ approach_type })
    );

    let enterpriseValuationMethod = '';
    let enterpriseValuationMethodInitials = '';
    let enterpriseValuationMethodTooltip = [] as string[];

    if (uniqueWeightedApproaches?.length > 1) {
      enterpriseValuationMethod = getStringValue(uniqueWeightedApproachesNames?.join(', '));
      enterpriseValuationMethodInitials = getStringValue(uniqueWeightedApproachesInitials?.join(', '));
      enterpriseValuationMethodTooltip = [
        `${VALUATIONS_SUMMARY_WEIGHTED_APPROACH_PLURAL}: ${enterpriseValuationMethod}.`,
      ];
    } else if (uniqueWeightedApproaches?.length) {
      enterpriseValuationMethod = getStringValue(uniqueWeightedApproachesNames[0]);
      enterpriseValuationMethodInitials = getStringValue(uniqueWeightedApproachesInitials[0]);
      enterpriseValuationMethodTooltip = [`${VALUATIONS_SUMMARY_WEIGHTED_APPROACH}: ${enterpriseValuationMethod}.`];
    }

    return {
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: getBooleanValue(current?.allocation?.has_only_custom_ownerships),
        value: enterpriseValuationMethodInitials,
        tooltipMessages: enterpriseValuationMethodTooltip,
      },
    };
  }, {}) as ValuationsSummaryColumn;

  // Enterprise Value
  const companiesEnterpriseValue = fundAllocations?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: getBooleanValue(current?.allocation?.has_only_custom_ownerships),
        value: getNumberValue(current?.enterprise_value) * Number(current?.exchange_rate_to_fund_currency ?? 1),
      },
    }),
    {}
  ) as ValuationsSummaryColumn;

  // Equity Value
  const companiesEquityValue = fundAllocations?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: getBooleanValue(current?.allocation?.has_only_custom_ownerships),
        value:
          getNumberValue(current.allocation?.current_equity_value)
          * Number(current?.exchange_rate_to_fund_currency ?? 1),
      },
    }),
    {}
  ) as ValuationsSummaryColumn;

  // Breakeven Equity Exit Value
  const companiesBreakevenEquityExitValue = fundAllocations?.reduce((accumulator, current) => {
    const breakevenEquityExitValueMin
      = getNumberValue(current?.exit_equity_value_min) * Number(current?.exchange_rate_to_fund_currency ?? 1);
    const breakevenEquityExitValueMax
      = getNumberValue(current?.exit_equity_value_max) * Number(current?.exchange_rate_to_fund_currency ?? 1);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: formatNumbers is not typed
    const breakevenEquityExitValueMinFormatted = formatNumbers({
      currency: format.currency,
      format: largeCurrencyFormat,
      value: breakevenEquityExitValueMin,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: formatNumbers is not typed
    const breakevenEquityExitValueMaxFormatted = formatNumbers({
      currency: format.currency,
      format: largeCurrencyFormat,
      value: breakevenEquityExitValueMax,
    });

    let breakevenEquityExitValue: number | string = breakevenEquityExitValueMax;
    let breakevenEquityExitValueTooltip = [] as string[];

    if (breakevenEquityExitValueMin !== breakevenEquityExitValueMax) {
      breakevenEquityExitValue = `${breakevenEquityExitValueMinFormatted} - ${breakevenEquityExitValueMaxFormatted}`;
      breakevenEquityExitValueTooltip = [breakevenEquityExitValue];
    }

    return {
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: getBooleanValue(current?.allocation?.has_only_custom_ownerships),
        value: breakevenEquityExitValue,
        tooltipMessages: breakevenEquityExitValueTooltip,
      },
    };
  }, {}) as ValuationsSummaryColumn;

  // Allocation Method
  const companiesAllocationMethod = fundAllocations?.reduce((accumulator, current) => {
    const scenarioMethods = Array.from(
      new Set(
        current?.allocation?.allocation_scenarios?.map(
          ({ scenario_method }) => SCENARIO_METHODS[getNumberValue(scenario_method)]
        )
      )
    );

    const allocationMethod = scenarioMethods.join(', ');
    const allocationMethodTooltip = [] as string[];

    return {
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: getBooleanValue(current?.allocation?.has_only_custom_ownerships),
        value: allocationMethod,
        tooltipMessages: allocationMethodTooltip,
      },
    };
  }, {}) as ValuationsSummaryColumn;

  // Current Fund Value
  const companiesCurrentFundValue = fundAllocations?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.unrealized_value) * Number(current?.exchange_rate_to_fund_currency ?? 1),
      },
    }),
    {}
  ) as ValuationsSummaryColumn;

  // % Change from Previous
  const companiesChangeFromPrevious = fundAllocations?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.id,
        name: current?.allocation?.company_measurement_date?.company?.slug,
        prefix: VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: Number(current.percentage_change),
      },
    }),
    {}
  ) as ValuationsSummaryColumn;

  // Total Breakeven Equity Exit Value
  const companiesTotalBreakevenEquityExitValue = fundAllocations?.reduce(
    (accumulator, current) =>
      accumulator
      + getNumberValue(current?.exit_equity_value_max) * Number(current?.exchange_rate_to_fund_currency ?? 1),
    0
  );

  return [
    companiesValuationDate,
    companiesValuationStatus,
    companiesEnterpriseValuationMethod,
    companiesEnterpriseValue,
    companiesEquityValue,
    companiesBreakevenEquityExitValue,
    companiesAllocationMethod,
    companiesCurrentFundValue,
    companiesChangeFromPrevious,
    companiesTotalBreakevenEquityExitValue,
  ];
};

export default createColumns;
