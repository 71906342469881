import { getNumberValue, getObjectValue } from 'utillities';
import {
  GetAdjustedColumnNumberParams,
  GetValuationSummaryHeaderParams,
  IsCapTableEnterpriseValueColumnParams,
} from './types';

export const VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_SCENARIOS_TITLE = 'Allocation Scenarios';
export const VALUATION_SUMMARY_SPREADSHEET_APPROACH_TYPE_TITLE = 'Approach Type';
export const VALUATION_SUMMARY_SPREADSHEET_ENTERPRISE_VALUE_TITLE = 'Enterprise Value';
export const VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_TITLE = 'Equity Value';
export const VALUATION_SUMMARY_SPREADSHEET_CASH_TITLE = 'Cash';
export const VALUATION_SUMMARY_SPREADSHEET_DEBT_TITLE = 'Debt';
export const VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE_TITLE = 'Scenario Equity Value';
export const VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY_TITLE = 'Scenario Weighting/Probability';
export const VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_TITLE = 'Scenario Weighting';
export const VALUATION_SUMMARY_SPREADSHEET_VALUATION_APPROACH_NAME_TITLE = 'Valuation Approach Name';
export const VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_ENTERPRISE_VALUE_TITLE = 'Weighted Enterprise Value';
export const VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_TITLE = 'Weighted Equity Value';

export const VALUATION_SUMMARY_SPREADSHEET_HEADERS = Object.freeze({
  VALUATION_APPROACH_NAME: {
    columnNumber: 0,
    title: VALUATION_SUMMARY_SPREADSHEET_VALUATION_APPROACH_NAME_TITLE,
  },
  ENTERPRISE_VALUE: {
    columnNumber: 1,
    title: VALUATION_SUMMARY_SPREADSHEET_ENTERPRISE_VALUE_TITLE,
  },
  EQUITY_VALUE: {
    columnNumber: 2,
    title: VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_TITLE,
  },
});

export const FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER
  = getNumberValue(Object.values(VALUATION_SUMMARY_SPREADSHEET_HEADERS).pop()?.columnNumber) + 1;

const NOT_UNIFORM_CURRENCY_EXCLUDED_COLUMNS = [
  VALUATION_SUMMARY_SPREADSHEET_HEADERS.VALUATION_APPROACH_NAME.columnNumber,
  VALUATION_SUMMARY_SPREADSHEET_HEADERS.ENTERPRISE_VALUE.columnNumber,
];

export const getValuationSummaryHeader = (params: GetValuationSummaryHeaderParams) => {
  const { colNumber, financialsCurrency = '', isUniformCurrency } = params;

  let isFinancialsEnterpriseValue = false;
  let shouldAdjustColumnNumber = false;

  if (!isUniformCurrency) {
    // Add Currency Code to the Financials Enterprise Value Title
    isFinancialsEnterpriseValue = colNumber === VALUATION_SUMMARY_SPREADSHEET_HEADERS.ENTERPRISE_VALUE.columnNumber;
    // Adjust the Column number if is not uniform currency and the column is not the Valuation Approach Name, Approach Type, or Enterprise Value (Financials)
    shouldAdjustColumnNumber = !NOT_UNIFORM_CURRENCY_EXCLUDED_COLUMNS.includes(colNumber);
  }

  const adjustedColumnNumber = shouldAdjustColumnNumber ? colNumber - 1 : colNumber;

  const valuationsSummaryHeader = Object.values(VALUATION_SUMMARY_SPREADSHEET_HEADERS).find(
    header => header?.columnNumber === adjustedColumnNumber
  );

  return {
    ...getObjectValue(valuationsSummaryHeader),
    title: isFinancialsEnterpriseValue
      ? `${financialsCurrency} ${valuationsSummaryHeader?.title}`
      : valuationsSummaryHeader?.title,
    columnNumber: adjustedColumnNumber,
  };
};

export const getAdjustedColumnNumber = (params: GetAdjustedColumnNumberParams) => {
  const { colNumber, includeAllColumns = false, isUniformCurrency } = params;

  if (isUniformCurrency) {
    return colNumber;
  }

  if (includeAllColumns) {
    return colNumber - 1;
  }

  // Adjust the Column number if is not uniform currency and the column is not the Valuation Approach Name, Approach Type, or Enterprise Value (Financials)
  return !NOT_UNIFORM_CURRENCY_EXCLUDED_COLUMNS.includes(colNumber) ? colNumber - 1 : colNumber;
};

export const isCapTableEnterpriseValueColumn = (params: IsCapTableEnterpriseValueColumnParams) => {
  const { colNumber, isUniformCurrency } = params;

  // If is not uniform currency and the column is not the Enterprise Value (Financials)
  return !isUniformCurrency && colNumber === VALUATION_SUMMARY_SPREADSHEET_HEADERS.ENTERPRISE_VALUE.columnNumber + 1;
};
