import React, { FC } from 'react';
import { VALUATIONS_APPROACH_TYPES } from 'pages/ValuationsAllocation/common/constants/valuations';
import { AddScenarioApproachButton } from 'pages/ValuationsAllocation/components/AddScenarioApproachButton';
import { NewScenarioApproachDialog } from 'pages/ValuationsAllocation/components/NewScenarioApproachDialog';
import { AddScenarioApproachDialogProps, ApproachTypes } from './types';

const AddScenarioApproachDialog: FC<AddScenarioApproachDialogProps> = props => {
  const {
    disableAddApproachButton,
    disableAddScenarioButton,
    handleAddApproach,
    handleAddScenario,
    handleCloseAddApproachDialog,
    handleOpenAddApproachDialog,
    openAddApproachDialog,
    approaches,
  } = props;

  return (
    <>
      <NewScenarioApproachDialog
        approachTypes={VALUATIONS_APPROACH_TYPES as ApproachTypes[]}
        disableAddScenarioButton={disableAddScenarioButton}
        handleApproachTypeClick={handleAddApproach}
        handleScenarioTypeClick={handleAddScenario}
        onDialogClose={handleCloseAddApproachDialog}
        openDialog={openAddApproachDialog}
        approaches={approaches}
      />

      <AddScenarioApproachButton disabled={disableAddApproachButton} onClick={handleOpenAddApproachDialog} />
    </>
  );
};

export default AddScenarioApproachDialog;
