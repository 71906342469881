/* eslint-disable import/prefer-default-export */
// PAGE STATES
export const INITIAL = 'INITIAL';
export const VALIDATING = 'VALIDATING';
export const VALIDATED = 'VALIDATED';

// FORMAT CELLS
export const PERCENT = 'percent';
export const PERCENTAGE = 'percentage';
export const NUMBER = 'number';
export const STRING = 'string';

// key codes for key events
export const CONTROL_CODE = 'Control';
export const PAGE_UP_CODE = 'PageUp';
export const PAGE_DOWN_CODE = 'PageDown';
export const BACKTICK_CODE = 'Backquote';
export const BACKTICK_CHAR = '`';
export const PERIOD_CODE = 'Period';
export const PERIOD_CHAR = '.';
export const N_CODE = 'KeyN';
export const N_CHAR = 'n';
export const EQUAL_CODE = 'Equal';
export const EQUAL_CHAR = '=';
export const SLASH_CODE = 'Slash';
export const SLASH_CHAR = '/';
export const MINUS_CODE = 'Minus';

// Tables
export const DEFAULT_ROWS_PER_PAGE = 5;
export const DEBOUNCE_DELAY = 1000;
export const GRAY_CELL = 'gray-cell';

// Allowed symbols
export const HYPHEN_SYMBOL = '-';

export const DATE_ALIAS = 'date';
export const BREAKEVEN_ERROR_CODE = 'breakeven_validation';

// Yes No
export const YES_STRING = 'Yes';
export const NO_STRING = 'No';

export const F2_KEY = 'F2';

// Max length for char fields
export const MAX_CHAR_LENGTH = 255;

export const NOT_APPLICABLE = 'N/A';
export const NOT_APPLICABLE_CALIBRATION = 0;

export const BLANK_VALUE = '';

// Common regular expressions
export const letterRegex = /[A-Za-z]+/;
export const numberRegex = /\d+/;
