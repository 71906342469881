import React from 'react';
import { Typography } from '@material-ui/core';
import DocumentGenerationJobPageHeader from './components/DocumentGenerationJobPageHeader/DocumentGenerationJobPageHeader';
import DocumentGenerationJobsTable from './components/DocumentGenerationJobTable/DocumentGenerationJobTable';
import { DEFAULT_LOADING_COMPANY } from './constants';
import { useStore } from '../../common/store';
import { UseStoreValues } from '../../common/types/store';

const DocumentGenerationJobs = () => {
  // any because companyInfo is not strongly typed
  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { companyInfo } = storeValue;

  if (!companyInfo?.id) return <Typography>{DEFAULT_LOADING_COMPANY}</Typography>;

  return (
    <div>
      <DocumentGenerationJobPageHeader />
      <DocumentGenerationJobsTable />
    </div>
  );
};

export default DocumentGenerationJobs;
