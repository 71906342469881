const rowTransformerCalibrationOutputs = data => {
  const firstColumn = {
    ltm_revenue_multiple_outputs: {
      value: data?.ltm_revenue_multiple || 0,
      id: 'ltm_revenue_multiple_outputs',
    },
    ntm_revenue_multiple_outputs: {
      value: data?.ntm_revenue_multiple || 0,
      id: 'ntm_revenue_multiple_outputs',
    },
    ltm_ebitda_multiple_outputs: {
      value: data?.ltm_ebitda_multiple || 0,
      id: 'ltm_ebitda_multiple_outputs',
    },
    ntm_ebitda_multiple_outputs: {
      value: data?.ntm_ebitda_multiple || 0,
      id: 'ntm_ebitda_multiple_outputs',
    },
  };

  return [firstColumn];
};

export default rowTransformerCalibrationOutputs;
