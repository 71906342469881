/**
 * @description Title cases input. INPUT_PROP -> Input Prop
 * @param input {string} accept s
 * @returns {string} String formatted with title case
 */
function titleCaseEnumValue(input: string): string {
  return input
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export default titleCaseEnumValue;
