import { largeCurrencyFormat } from '../../formats/formats';

export const PUBLIC_COMPS_APPROACH = 'public_comps_approach_calibration';
export const FINANCIALS_VERSION_CALIBRATION = 'financials_version';
export const performanceKeys = [
  'name',
  'enterprise_value',
  'ltm_revenue',
  'ltm_revenue_multiple',
  'ltm_revenue_growth',
  'ntm_revenue',
  'ntm_revenue_multiple',
  'ntm_revenue_growth',
  'ltm_ebitda',
  'ltm_ebitda_multiple',
  'ntm_ebitda',
  'ntm_ebitda_multiple',
  'gross_margin',
  'ebitda_margin',
  'cap_iq_id',
];
export const HIDDEN_ROW_NUMBER = 8;
export const emptyFinancials = {
  ltm_adjusted_ebitda: 0,
  ltm_ebitda_margin: 0,
  ltm_ebitda: 0,
  ltm_gross_margin: 0,
  ltm_revenue_growth_rate: 0,
  ltm_revenue: 0,
  ntm_adjusted_ebitda: 0,
  ntm_ebitda: 0,
  ntm_revenue_growth_rate: 0,
  ntm_revenue: 0,
  total_cash_equivalents: 0,
  total_debt: 0,
  use_adjusted_ebitda: false,
};

export const commonInputFields = {
  gridType: 'number',
  format: largeCurrencyFormat,
  ignoreRowCopy: true,
  isClassNameRequired: true,
  className: 'row-label-indented',
};
