import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { ApiCreateDocumentGenerationJob, ApiService, CompanyDocuments } from 'api';
import { useStore } from 'common/store';
import { FormDialog } from 'components';
import { useDocuments, useResponse } from 'services/hooks';
import DocumentGenerationJobsCreateModalProps from './DocumentGenerationJobCreateModal.props';
import { CREATE_MODAL_CONSTANTS, QUERY_CONSTANTS } from '../../constants';

const DocumentGenerationJobCreateModal = ({ ...rest }: DocumentGenerationJobsCreateModalProps) => {
  const queryClient = useQueryClient();

  const [preCreatedJob, setPreCreatedJob] = useState<ApiCreateDocumentGenerationJob>({
    reference_files: [],
    processing_type: ApiCreateDocumentGenerationJob.processing_type.CAP_TABLE,
  });
  const { errorNotification } = useResponse();

  const { apiCompanyDocumentGenerationJobsNewCreate } = ApiService;
  const { getDocuments, companyDocuments } = useDocuments() as unknown as {
    getDocuments: (companyId: number, measurementDataId: number) => Promise<void>;
    companyDocuments: CompanyDocuments[];
  };

  // any as use store is not typed
  const [{ companyInfo }] = useStore() as any;

  const memoizedGetDocuments = useMemo(() => getDocuments, [getDocuments]);

  const handleCreate = useCallback(async () => {
    try {
      await apiCompanyDocumentGenerationJobsNewCreate(companyInfo?.id, preCreatedJob as any);
      queryClient.invalidateQueries({
        queryKey: [QUERY_CONSTANTS.getDocumentGenerationJobs, companyInfo.id, 0],
        exact: false,
      });
      // any as the exception is not typed
    } catch (err: any) {
      const errorMessage = err?.response?.data?.error;
      errorNotification(errorMessage || CREATE_MODAL_CONSTANTS.ERROR);
    }
  }, [preCreatedJob, companyInfo, queryClient, apiCompanyDocumentGenerationJobsNewCreate, errorNotification]);

  useEffect(() => {
    const getData = async () => {
      await memoizedGetDocuments(companyInfo.id, companyInfo.measurement_data_id);
    };

    getData();
    // need this disable getDocuments has a rerendering issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);

  return (
    <FormDialog
      customButtonLabel={CREATE_MODAL_CONSTANTS.ACTION_BUTTON}
      onClose={() => rest.onClose && rest.onClose({}, 'backdropClick')}
      open={rest.open}
      title={CREATE_MODAL_CONSTANTS.TITLE}
      onSave={handleCreate}
      isValid={preCreatedJob.reference_files.length > 0 && !!preCreatedJob.processing_type}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <div>
          <Typography variant="h3">{CREATE_MODAL_CONSTANTS.DOCUMENT_ACTION}</Typography>
          {companyDocuments && (
            <Autocomplete
              multiple
              id="checkboxes-documents"
              options={companyDocuments.flatMap(doc => doc.files)}
              disableCloseOnSelect
              onChange={(_, v) =>
                setPreCreatedJob({
                  ...preCreatedJob,
                  reference_files: v?.map(file => file?.file.id?.toString()) as any,
                })
              }
              getOptionLabel={option => option?.file.filename ?? ''}
              renderOption={(option, state) => (
                <>
                  <Checkbox checked={state.selected} key={option?.file.id} />
                  {`${option?.file.file_path}/${option?.file.filename}.${option?.file.file_type}`}
                </>
              )}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={CREATE_MODAL_CONSTANTS.DOCUMENT_SELECT_PLACEHOLDER}
                />
              )}
              getOptionSelected={(option, value) => value?.file.id === option?.file.id}
            />
          )}
        </div>
        <div>
          <Typography variant="h3">{CREATE_MODAL_CONSTANTS.TYPE_ACTION}</Typography>
          <Select
            value={preCreatedJob.processing_type}
            onChange={e =>
              setPreCreatedJob(prev => ({
                ...prev,
                processing_type: e.target.value as ApiCreateDocumentGenerationJob.processing_type,
              }))
            }
            variant="outlined"
            style={{ width: '100%' }}>
            {CREATE_MODAL_CONSTANTS.OPTIONS.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </FormDialog>
  );
};

export default DocumentGenerationJobCreateModal;
