import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Tooltip, withStyles } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { isEmpty } from 'lodash';
import { MessageBox } from 'components';
import AutomaticUpdates from 'pages/Valuations/components/CompGroups/components/AutomaticUpdates';
import CompGroupDialogContext from 'pages/Valuations/components/CompGroups/components/common/CompGroupDialogContext';
import Companies from 'pages/Valuations/components/CompGroups/components/Companies';
import PublicCompsList from 'pages/Valuations/components/CompGroups/components/PublicCompsList';

const EditCompGroupForm = () => {
  const TOOLTIP_TEXT
    = 'Automatic Updates will automatically keep the list of companies in this Public Comps valuation synced with the selected Comp Group.';
  const [compGroupId, setCompGroupId] = useState(null);
  const {
    compGroupList,
    defaultCompGroupId,
    compGroupSelected,
    setCompGroupSelected,
    setValid,
    isGPC,
    setCompaniesToDelete,
    setCompGroupToDelete,
  } = useContext(CompGroupDialogContext);

  const getCompGroupSelectedInfo = useCallback(
    compGroupInfo => {
      if (compGroupInfo) {
        return {
          id: compGroupInfo.id,
          name: compGroupInfo.name,
          companies: isGPC ? compGroupInfo.gpc_comps : compGroupInfo.pt_comps,
          use_latest_comp_group_version: compGroupInfo.checked,
          originalData: compGroupInfo,
        };
      }
      return {
        use_latest_comp_group_version: null,
      };
    },
    [isGPC]
  );

  const setCompGroupSelectedData = useCallback(
    compGroupIdToSelect => {
      if (compGroupIdToSelect === -1) {
        setCompGroupSelected({});
        setValid(false);
      } else {
        const selected = compGroupList.find(compGroup => compGroup.id === compGroupIdToSelect);
        setCompaniesToDelete([]);
        setCompGroupToDelete(null);
        const compGroupSelectedInfo = getCompGroupSelectedInfo(selected);
        setCompGroupSelected(compGroupSelectedInfo);
        setValid(true);
      }
    },
    [
      compGroupList,
      getCompGroupSelectedInfo,
      setCompGroupSelected,
      setCompGroupToDelete,
      setCompaniesToDelete,
      setValid,
    ]
  );

  useEffect(() => {
    if (Object.keys(compGroupSelected).length === 0) {
      setCompGroupId(null);
    }
  }, [compGroupSelected]);

  useEffect(() => {
    if (defaultCompGroupId) {
      setCompGroupSelectedData(defaultCompGroupId);
    }
  }, [defaultCompGroupId, setCompGroupSelectedData]);

  useEffect(() => {
    if (compGroupId) {
      setCompGroupSelectedData(compGroupId);
    }
  }, [compGroupId, setCompGroupSelectedData]);

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.white,
      color: theme.palette.black,
      borderRadius: '8px',
      fontSize: '14px',
      maxWidth: '260px',
      boxShadow: '0px 0px 20px 0px grey',
      padding: '15px',
    },
    popper: {
      zIndex: '1300 !important',
    },
  }))(Tooltip);

  return (
    <Grid container>
      {isEmpty(compGroupList) ? (
        <MessageBox title={'There are no Comps Groups'} tagline={'Please go to Comp Group Page'} hasFrame={false} />
      ) : (
        <>
          <Grid container style={{ justifyContent: 'end' }}>
            <HtmlTooltip title={TOOLTIP_TEXT} placement="top">
              <ErrorOutlineOutlinedIcon
                style={{
                  color: '#037DE8',
                  fontSize: '1.125rem',
                  justifyContent: 'end',
                }}
              />
            </HtmlTooltip>
          </Grid>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={8}>
              <PublicCompsList setCompGroupId={setCompGroupId} />
            </Grid>
            <Grid item xs={4}>
              <AutomaticUpdates />
            </Grid>
            <Grid item xs={12}>
              <Companies />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EditCompGroupForm;
