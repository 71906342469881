import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormDialog } from 'components';
import { ValuationsFormState } from 'pages/ValuationsAllocation/types';
import { getStringValue, gridShortDate } from 'utillities';
import { ApproachForm } from './components';
import { ApproachDialogProps } from './types';

const FORM_DEFAULT_VALUES = Object.freeze({
  isValid: true,
  values: {},
  errors: {},
} as ValuationsFormState);

const ApproachDialog: FC<ApproachDialogProps> = props => {
  const {
    approach,
    calibration,
    editMode,
    handleOnClose,
    handleOnSave,
    measurementDate,
    open,
    shouldDisplayDateSelector,
    title,
  } = props;

  const [formState, setFormState] = useState<ValuationsFormState>(FORM_DEFAULT_VALUES);

  const formIsValid = useMemo(() => Boolean(formState.isValid), [formState]);

  const handleOnClick = useCallback(() => handleOnSave(formState), [formState, handleOnSave]);

  // Set Form values from Approach
  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        name: approach ? getStringValue(approach?.name) : calibration?.name,
        valuation_date: gridShortDate(approach?.valuation_date ?? measurementDate),
        is_calibration: approach?.is_calibration,
      },
    }));
  }, [approach, calibration, measurementDate]);

  const handleOnChange = (updatedFormState: ValuationsFormState) => setFormState(updatedFormState);

  return (
    <FormDialog
      editMode={editMode}
      isValid={formIsValid}
      onClose={handleOnClose}
      onSave={handleOnClick}
      open={open}
      title={title}>
      <ApproachForm
        formState={formState}
        onFormChange={handleOnChange}
        shouldDisplayDateSelector={shouldDisplayDateSelector}
      />
    </FormDialog>
  );
};

export default ApproachDialog;
