import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { getObjectValue } from '../../../../../utillities';
import { VALUATION_SUMMARY_KEY } from '../../../../ValuationsAllocation/common/constants/valuationSummary';
import {
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY,
} from '../../../../ValuationsAllocation/common/constants/valuationSummary/sheetAliases';
import { getFinalValueByAlias, getPlusDebtAndLessCashInputsTable, validateHiddenRows } from '../utils';

const parser = async ({ columns, rowConfig, tableData }) => {
  let cells = {};
  const { calibration, isDisabled } = tableData;
  const calibrationData = calibration;
  const alphabet = alphabetGenerator([], columns.length);
  const columnsLength = columns.length;

  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    rowConfig.forEach((row, rowIdx) => {
      const rowNumber = rowIdx + 1;
      const key = columnLegend + rowNumber;
      const colNumber = columnIndex + 1;
      const isLastColumn = colNumber === columnsLength;
      const { alias } = getObjectValue(row);
      let { gridType, expr, rowNumberPair, exprPair, readOnly } = row;

      let value = getFinalValueByAlias(column[alias]?.value ?? '', gridType, calibrationData, alias);
      const { format } = row;

      let { style } = column;
      style = validateHiddenRows(rowNumber, style);

      const enterPriseValueExpr = `=equity_value_inputs+${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY}-${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`;
      const equityValueExpr = `=enterprise_value_inputs-${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY}+${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`;
      const isEnterpriseValueCalculated = false;
      switch (alias) {
        case VALUATION_SUMMARY_SPREADSHEET_LESS_CASH:
          ({ gridType, readOnly, value } = getPlusDebtAndLessCashInputsTable(
            isDisabled,
            isLastColumn,
            gridType,
            calibrationData,
            readOnly,
            value,
            true
          ));
          break;
        case VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT:
          ({ gridType, readOnly, value } = getPlusDebtAndLessCashInputsTable(
            isDisabled,
            isLastColumn,
            gridType,
            calibrationData,
            readOnly,
            value,
            false
          ));
          break;
        case 'enterprise_value_inputs':
          expr = isEnterpriseValueCalculated ? enterPriseValueExpr : null;
          exprPair = enterPriseValueExpr;
          rowNumberPair = 7;
          break;
        case 'equity_value_inputs':
          expr = !isEnterpriseValueCalculated ? equityValueExpr : null;
          exprPair = equityValueExpr;
          rowNumberPair = 4;
          break;
        case 'ltm_revenue_inputs':
        case 'ntm_revenue_inputs':
        case 'ltm_ebitda_inputs':
        case 'ntm_ebitda_inputs':
          if (Number(calibrationData.financials_version) === 0 && !isDisabled) {
            readOnly = false;
          }
          break;
        case 'financials_version':
          if (value === '') {
            value = 0;
          }
          break;
        default:
          break;
      }

      const cell = {
        key,
        alias: row.alias || '',
        columnId: column.id,
        columnLegend,
        columnOrder: column.order,
        customFormat: column.customFormat || null,
        ignoreAutoScroll: row.ignoreAutoScroll,
        isNotNavigable: row.isNotNavigable,
        isParent: column.isParent,
        parentColumn: column.parentColumn,
        rowNumber,
        value,
        isGpcRow: false,
        readOnly,
        style,
        format,
        gridType,
        customKey: alias,
        expr,
        rowNumberPair,
        exprPair,
        dbType: 'string',
      };

      cells = {
        ...cells,
        [key]: cell,
      };
    });
  });

  return cells;
};

export default parser;
