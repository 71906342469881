/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddBulkDashboardUser } from '../models/AddBulkDashboardUser';
import type { AddNewDashboardUser } from '../models/AddNewDashboardUser';
import type { AddOrRemoveRequestItems } from '../models/AddOrRemoveRequestItems';
import type { AllocationCompanyModel } from '../models/AllocationCompanyModel';
import type { AllocationConclusionModel } from '../models/AllocationConclusionModel';
import type { AllocationModel } from '../models/AllocationModel';
import type { AllocationName } from '../models/AllocationName';
import type { AllocationScenarioModel } from '../models/AllocationScenarioModel';
import type { ApiCreateDocumentGenerationJob } from '../models/ApiCreateDocumentGenerationJob';
import type { CapTableDetailsModel } from '../models/CapTableDetailsModel';
import type { CapTableFundOwnership } from '../models/CapTableFundOwnership';
import type { CapTableName } from '../models/CapTableName';
import type { CapTableSecurities } from '../models/CapTableSecurities';
import type { ChoiceAnswerModel } from '../models/ChoiceAnswerModel';
import type { Company } from '../models/Company';
import type { CompanyDocuments } from '../models/CompanyDocuments';
import type { CompanyMeasurementDateModel } from '../models/CompanyMeasurementDateModel';
import type { CompanyPrimaryContact } from '../models/CompanyPrimaryContact';
import type { CompanyRequestedDocumentModel } from '../models/CompanyRequestedDocumentModel';
import type { CompanyRequestedInformation } from '../models/CompanyRequestedInformation';
import type { CompGroupListCreate } from '../models/CompGroupListCreate';
import type { Create409Report } from '../models/Create409Report';
import type { CreateCompanyInformationRequest } from '../models/CreateCompanyInformationRequest';
import type { CreateDocumentAndQuestionnaireTasks } from '../models/CreateDocumentAndQuestionnaireTasks';
import type { CreateFirmRequests } from '../models/CreateFirmRequests';
import type { CreateOnboardingTasks } from '../models/CreateOnboardingTasks';
import type { CreateOrDeleteDocumentReferenceObject } from '../models/CreateOrDeleteDocumentReferenceObject';
import type { CustomPrimaryUser } from '../models/CustomPrimaryUser';
import type { CustomUser } from '../models/CustomUser';
import type { DocumentRequestView } from '../models/DocumentRequestView';
import type { EffectiveTaxRateModel } from '../models/EffectiveTaxRateModel';
import type { EffectiveTaxRateWriteModel } from '../models/EffectiveTaxRateWriteModel';
import type { ExtendedCompanyInfo } from '../models/ExtendedCompanyInfo';
import type { FinancialStatementBasicData } from '../models/FinancialStatementBasicData';
import type { FinancialStatementFullWrite } from '../models/FinancialStatementFullWrite';
import type { FinancialStatementVersion } from '../models/FinancialStatementVersion';
import type { Firm } from '../models/Firm';
import type { FirmCompaniesRequestsProgress } from '../models/FirmCompaniesRequestsProgress';
import type { FirmCompany } from '../models/FirmCompany';
import type { FirmRequestedDocumentModel } from '../models/FirmRequestedDocumentModel';
import type { FirmStepper } from '../models/FirmStepper';
import type { FirmUserPermission } from '../models/FirmUserPermission';
import type { Fund } from '../models/Fund';
import type { FundName } from '../models/FundName';
import type { GPC } from '../models/GPC';
import type { IndividualRequestedFile } from '../models/IndividualRequestedFile';
import type { InformationRequestStatus } from '../models/InformationRequestStatus';
import type { MeasurementDateExistsReturn } from '../models/MeasurementDateExistsReturn';
import type { MeasurementDateName } from '../models/MeasurementDateName';
import type { MeasurementDateRollover } from '../models/MeasurementDateRollover';
import type { MeasurementDateRolloverReturn } from '../models/MeasurementDateRolloverReturn';
import type { NewAllocationVersionModel } from '../models/NewAllocationVersionModel';
import type { NewValuationAllocationVersion } from '../models/NewValuationAllocationVersion';
import type { Note } from '../models/Note';
import type { NoteGet } from '../models/NoteGet';
import type { NumberFriendlyCreateCompanyQuestion } from '../models/NumberFriendlyCreateCompanyQuestion';
import type { Portal } from '../models/Portal';
import type { PrimaryApproachMultiples } from '../models/PrimaryApproachMultiples';
import type { ProFormaCapTableModel } from '../models/ProFormaCapTableModel';
import type { RetrieveDocumentGenerationJob } from '../models/RetrieveDocumentGenerationJob';
import type { ScalarOpinionView } from '../models/ScalarOpinionView';
import type { SetReadyForAudit } from '../models/SetReadyForAudit';
import type { TaskDetailedProgress } from '../models/TaskDetailedProgress';
import type { TaskModel } from '../models/TaskModel';
import type { TransactionId } from '../models/TransactionId';
import type { UpdateCompanyQuestion } from '../models/UpdateCompanyQuestion';
import type { UpdateDecisionsView } from '../models/UpdateDecisionsView';
import type { UpdateDocumentAndQuestionnaireTasks } from '../models/UpdateDocumentAndQuestionnaireTasks';
import type { UpdateFirmQuestion } from '../models/UpdateFirmQuestion';
import type { UpdateIndividualQuestion } from '../models/UpdateIndividualQuestion';
import type { UserFirmRole } from '../models/UserFirmRole';
import type { UserPermission } from '../models/UserPermission';
import type { UserPermissionWithPath } from '../models/UserPermissionWithPath';
import type { ValuationAllocationModel } from '../models/ValuationAllocationModel';
import type { ValuationApproachModel } from '../models/ValuationApproachModel';
import type { ValuationModel } from '../models/ValuationModel';
import type { ValuationOpinionModule } from '../models/ValuationOpinionModule';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiService {
  /**
   * @returns AllocationScenarioModel
   * @throws ApiError
   */
  public static apiAllocationScenarioAllocationResultsRead(): CancelablePromise<AllocationScenarioModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/allocation-scenario/allocation-results',
    });
  }

  /**
   * @param data
   * @returns AllocationScenarioModel
   * @throws ApiError
   */
  public static apiAllocationScenarioAllocationResultsCreate(
    data: AllocationScenarioModel
  ): CancelablePromise<AllocationScenarioModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/allocation-scenario/allocation-results',
      body: data,
    });
  }

  /**
   * @param ctPk
   * @param data
   * @returns AllocationScenarioModel
   * @throws ApiError
   */
  public static apiAllocationScenarioBacksolveCreate(
    ctPk: string,
    data: AllocationScenarioModel
  ): CancelablePromise<AllocationScenarioModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/allocation-scenario/{ct_pk}/backsolve',
      path: {
        ct_pk: ctPk,
      },
      body: data,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiAllocationsCalculateBacksolveCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/allocations/calculate-backsolve',
    });
  }

  /**
   * @param data
   * @returns AllocationConclusionModel
   * @throws ApiError
   */
  public static apiAllocationsFinalUpdate(
    data: AllocationConclusionModel
  ): CancelablePromise<AllocationConclusionModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/allocations/final',
      body: data,
    });
  }

  /**
   * @param data
   * @returns AllocationConclusionModel
   * @throws ApiError
   */
  public static apiAllocationsFinalPartialUpdate(
    data: AllocationConclusionModel
  ): CancelablePromise<AllocationConclusionModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/allocations/final',
      body: data,
    });
  }

  /**
   * @param data
   * @returns NewAllocationVersionModel
   * @throws ApiError
   */
  public static apiAllocationsVersionCreate(
    data: NewAllocationVersionModel
  ): CancelablePromise<NewAllocationVersionModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/allocations/version',
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this allocation.
   * @returns AllocationModel
   * @throws ApiError
   */
  public static apiAllocationsRead(id: number): CancelablePromise<AllocationModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/allocations/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this allocation.
   * @param data
   * @returns AllocationModel
   * @throws ApiError
   */
  public static apiAllocationsUpdate(id: number, data: AllocationModel): CancelablePromise<AllocationModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/allocations/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this allocation.
   * @param data
   * @returns AllocationModel
   * @throws ApiError
   */
  public static apiAllocationsPartialUpdate(id: number, data: AllocationModel): CancelablePromise<AllocationModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/allocations/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @param data
   * @returns AllocationModel
   * @throws ApiError
   */
  public static apiAllocationsPublishedUpdate(id: string, data: AllocationModel): CancelablePromise<AllocationModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/allocations/{id}/published',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @param data
   * @returns AllocationModel
   * @throws ApiError
   */
  public static apiAllocationsPublishedPartialUpdate(
    id: string,
    data: AllocationModel
  ): CancelablePromise<AllocationModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/allocations/{id}/published',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiAllocationsScenariosUpdate(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/allocations/{id}/scenarios',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiAllocationsScenariosPartialUpdate(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/allocations/{id}/scenarios',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static apiCapTablesFundOwnershipDetailsDelete(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/cap-tables/fund-ownership-details/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param data
   * @returns ProFormaCapTableModel
   * @throws ApiError
   */
  public static apiCapTablesProFormaCreate(data: ProFormaCapTableModel): CancelablePromise<ProFormaCapTableModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/cap-tables/pro-forma',
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this cap table.
   * @returns CapTableDetailsModel
   * @throws ApiError
   */
  public static apiCapTablesRead(id: number): CancelablePromise<CapTableDetailsModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/cap-tables/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this cap table.
   * @param data
   * @returns CapTableDetailsModel
   * @throws ApiError
   */
  public static apiCapTablesUpdate(id: number, data: CapTableDetailsModel): CancelablePromise<CapTableDetailsModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/cap-tables/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this cap table.
   * @param data
   * @returns CapTableDetailsModel
   * @throws ApiError
   */
  public static apiCapTablesPartialUpdate(
    id: number,
    data: CapTableDetailsModel
  ): CancelablePromise<CapTableDetailsModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/cap-tables/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this cap table.
   * @returns CapTableDetailsModel
   * @throws ApiError
   */
  public static apiCapTablesAllBreakpointsRead(id: number): CancelablePromise<CapTableDetailsModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/cap-tables/{id}/all-breakpoints',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this cap table.
   * @param data
   * @returns CapTableDetailsModel
   * @throws ApiError
   */
  public static apiCapTablesAllBreakpointsUpdate(
    id: number,
    data: CapTableDetailsModel
  ): CancelablePromise<CapTableDetailsModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/cap-tables/{id}/all-breakpoints',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this cap table.
   * @param data
   * @returns CapTableDetailsModel
   * @throws ApiError
   */
  public static apiCapTablesAllBreakpointsPartialUpdate(
    id: number,
    data: CapTableDetailsModel
  ): CancelablePromise<CapTableDetailsModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/cap-tables/{id}/all-breakpoints',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiCapTablesCheckAssociatedMeasurementDateList(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/cap-tables/{id}/check-associated-measurement-date',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this fund ownership.
   * @returns CapTableFundOwnership
   * @throws ApiError
   */
  public static apiCapTablesFundOwnershipRead(id: number): CancelablePromise<CapTableFundOwnership> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/cap-tables/{id}/fund-ownership',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this fund ownership.
   * @param data
   * @returns CapTableFundOwnership
   * @throws ApiError
   */
  public static apiCapTablesFundOwnershipUpdate(
    id: number,
    data: CapTableFundOwnership
  ): CancelablePromise<CapTableFundOwnership> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/cap-tables/{id}/fund-ownership',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this fund ownership.
   * @param data
   * @returns CapTableFundOwnership
   * @throws ApiError
   */
  public static apiCapTablesFundOwnershipPartialUpdate(
    id: number,
    data: CapTableFundOwnership
  ): CancelablePromise<CapTableFundOwnership> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/cap-tables/{id}/fund-ownership',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @returns AllocationCompanyModel
   * @throws ApiError
   */
  public static apiCapTablesGotFinalAllocationRead(id: string): CancelablePromise<AllocationCompanyModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/cap-tables/{id}/got-final-allocation',
      path: {
        id: id,
      },
    });
  }

  /**
   * pk here is id of the company measurement date we're querying a primary captable for
   * @param id A unique integer value identifying this cap table.
   * @returns CapTableDetailsModel
   * @throws ApiError
   */
  public static apiCapTablesPrimaryRead(id: number): CancelablePromise<CapTableDetailsModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/cap-tables/{id}/primary',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param data
   * @returns CapTableSecurities
   * @throws ApiError
   */
  public static apiCapTablesSecuritiesUpdate(
    id: string,
    data: CapTableSecurities
  ): CancelablePromise<CapTableSecurities> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/cap-tables/{id}/securities',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @param data
   * @returns CapTableSecurities
   * @throws ApiError
   */
  public static apiCapTablesSecuritiesPartialUpdate(
    id: string,
    data: CapTableSecurities
  ): CancelablePromise<CapTableSecurities> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/cap-tables/{id}/securities',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param cmdId
   * @returns any
   * @throws ApiError
   */
  public static apiCompaniesFiscalEndDatesRead(cmdId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/companies/fiscal-end-dates/{cmd_id}',
      path: {
        cmd_id: cmdId,
      },
    });
  }

  /**
   * @param companyPk
   * @returns any
   * @throws ApiError
   */
  public static apiCompaniesAllocationValuesRead(companyPk: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/companies/{company_pk}/allocation-values',
      path: {
        company_pk: companyPk,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this company.
   * @returns Company
   * @throws ApiError
   */
  public static apiCompaniesRead(id: number): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/companies/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this company.
   * @param data
   * @returns Company
   * @throws ApiError
   */
  public static apiCompaniesUpdate(id: number, data: Company): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/companies/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this company.
   * @param data
   * @returns Company
   * @throws ApiError
   */
  public static apiCompaniesPartialUpdate(id: number, data: Company): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/companies/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this company.
   * @returns void
   * @throws ApiError
   */
  public static apiCompaniesDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/companies/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiCompaniesAllocationsList(
    id: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<AllocationName>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/companies/{id}/allocations',
      path: {
        id: id,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiCompaniesCapTablesList(
    id: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CapTableName>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/companies/{id}/cap-tables',
      path: {
        id: id,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id
   * @param mdId
   * @returns any
   * @throws ApiError
   */
  public static apiCompaniesHasSummaryRead(id: string, mdId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/companies/{id}/has-summary/{md_id}',
      path: {
        id: id,
        md_id: mdId,
      },
    });
  }

  /**
   * @param id
   * @param mdId
   * @returns any
   * @throws ApiError
   */
  public static apiCompaniesSummaryRead(id: string, mdId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/companies/{id}/summary/{md_id}',
      path: {
        id: id,
        md_id: mdId,
      },
    });
  }

  /**
   * @param id
   * @param date
   * @returns MeasurementDateExistsReturn
   * @throws ApiError
   */
  public static apiCompanyMeasurementDatesExistsRead(
    id: string,
    date: string
  ): CancelablePromise<MeasurementDateExistsReturn> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/company-measurement-dates/{id}/exists/{date}/',
      path: {
        id: id,
        date: date,
      },
    });
  }

  /**
   * @param mdPk
   * @returns CompanyMeasurementDateModel
   * @throws ApiError
   */
  public static apiCompanyMeasurementDatesByMeasurementDateRead(
    mdPk: string
  ): CancelablePromise<CompanyMeasurementDateModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/company-measurement-dates/{md_pk}/by_measurement_date/',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param companyId
   * @param search A search term.
   * @returns CustomPrimaryUser
   * @throws ApiError
   */
  public static apiCompanyUsersRead(companyId: string, search?: string): CancelablePromise<Array<CustomPrimaryUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/company-users/{company_id}',
      path: {
        company_id: companyId,
      },
      query: {
        search: search,
      },
    });
  }

  /**
   * @param companyId
   * @param page A page number within the paginated result set.
   * @param pageSize Number of results to return per page.
   * @returns any
   * @throws ApiError
   */
  public static apiCompanyDocumentGenerationJobsList(
    companyId: string,
    page?: number,
    pageSize?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<RetrieveDocumentGenerationJob>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/company/{company_id}/document-generation-jobs',
      path: {
        company_id: companyId,
      },
      query: {
        page: page,
        page_size: pageSize,
      },
    });
  }

  /**
   * @param companyId
   * @param data
   * @returns RetrieveDocumentGenerationJob
   * @throws ApiError
   */
  public static apiCompanyDocumentGenerationJobsNewCreate(
    companyId: string,
    data: ApiCreateDocumentGenerationJob
  ): CancelablePromise<RetrieveDocumentGenerationJob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/company/{company_id}/document-generation-jobs/new',
      path: {
        company_id: companyId,
      },
      body: data,
    });
  }

  /**
   * @param companyId
   * @param jobId
   * @returns RetrieveDocumentGenerationJob
   * @throws ApiError
   */
  public static apiCompanyDocumentGenerationJobsRead(
    companyId: string,
    jobId: string
  ): CancelablePromise<RetrieveDocumentGenerationJob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/company/{company_id}/document-generation-jobs/{job_id}',
      path: {
        company_id: companyId,
        job_id: jobId,
      },
    });
  }

  /**
   * @param data
   * @returns InformationRequestStatus
   * @throws ApiError
   */
  public static apiCreateInformationRequestStatusCreate(
    data: InformationRequestStatus
  ): CancelablePromise<InformationRequestStatus> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/create-information-request-status',
      body: data,
    });
  }

  /**
   * @param informationRequestStatusId
   * @param data
   * @returns TaskDetailedProgress
   * @throws ApiError
   */
  public static apiCreateInformationRequestUpdate(
    informationRequestStatusId: string,
    data: CreateDocumentAndQuestionnaireTasks
  ): CancelablePromise<Array<TaskDetailedProgress>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/create-information-request/{information_request_status_id}',
      path: {
        information_request_status_id: informationRequestStatusId,
      },
      body: data,
    });
  }

  /**
   * @param informationRequestStatusId
   * @param data
   * @returns CreateDocumentAndQuestionnaireTasks
   * @throws ApiError
   */
  public static apiCreateInformationRequestPartialUpdate(
    informationRequestStatusId: string,
    data: CreateDocumentAndQuestionnaireTasks
  ): CancelablePromise<CreateDocumentAndQuestionnaireTasks> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/create-information-request/{information_request_status_id}',
      path: {
        information_request_status_id: informationRequestStatusId,
      },
      body: data,
    });
  }

  /**
   * @param data
   * @returns Create409Report
   * @throws ApiError
   */
  public static apiDashboardCreate(data: Create409Report): CancelablePromise<Create409Report> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard',
      body: data,
    });
  }

  /**
   * @param questionId
   * @returns UpdateCompanyQuestion
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyQuestionRead(questionId: string): CancelablePromise<UpdateCompanyQuestion> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dashboard/companies/company-question/{question_id}',
      path: {
        question_id: questionId,
      },
    });
  }

  /**
   * @param questionId
   * @param data
   * @returns UpdateCompanyQuestion
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyQuestionUpdate(
    questionId: string,
    data: UpdateCompanyQuestion
  ): CancelablePromise<UpdateCompanyQuestion> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/dashboard/companies/company-question/{question_id}',
      path: {
        question_id: questionId,
      },
      body: data,
    });
  }

  /**
   * @param questionId
   * @param data
   * @returns UpdateCompanyQuestion
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyQuestionPartialUpdate(
    questionId: string,
    data: UpdateCompanyQuestion
  ): CancelablePromise<UpdateCompanyQuestion> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/dashboard/companies/company-question/{question_id}',
      path: {
        question_id: questionId,
      },
      body: data,
    });
  }

  /**
   * @param questionId
   * @returns void
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyQuestionDelete(questionId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/dashboard/companies/company-question/{question_id}',
      path: {
        question_id: questionId,
      },
    });
  }

  /**
   * @param requestedDocumentId
   * @returns CompanyRequestedDocumentModel
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyRequestedDocumentRead(
    requestedDocumentId: string
  ): CancelablePromise<CompanyRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dashboard/companies/company-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
    });
  }

  /**
   * @param requestedDocumentId
   * @param data
   * @returns CompanyRequestedDocumentModel
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyRequestedDocumentUpdate(
    requestedDocumentId: string,
    data: CompanyRequestedDocumentModel
  ): CancelablePromise<CompanyRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/dashboard/companies/company-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
      body: data,
    });
  }

  /**
   * @param requestedDocumentId
   * @param data
   * @returns CompanyRequestedDocumentModel
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyRequestedDocumentPartialUpdate(
    requestedDocumentId: string,
    data: CompanyRequestedDocumentModel
  ): CancelablePromise<CompanyRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/dashboard/companies/company-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
      body: data,
    });
  }

  /**
   * @param requestedDocumentId
   * @returns void
   * @throws ApiError
   */
  public static apiDashboardCompaniesCompanyRequestedDocumentDelete(
    requestedDocumentId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/dashboard/companies/company-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
    });
  }

  /**
   * @param data
   * @returns AddBulkDashboardUser
   * @throws ApiError
   */
  public static apiDashboardCompanyAddBulkUserCreate(
    data: AddBulkDashboardUser
  ): CancelablePromise<AddBulkDashboardUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/company/add-bulk-user/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns AddNewDashboardUser
   * @throws ApiError
   */
  public static apiDashboardCompanyAddUserCreate(data: AddNewDashboardUser): CancelablePromise<AddNewDashboardUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/company/add-user/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns NumberFriendlyCreateCompanyQuestion
   * @throws ApiError
   */
  public static apiDashboardCompanyCreateQuestionsCreate(
    data: NumberFriendlyCreateCompanyQuestion
  ): CancelablePromise<NumberFriendlyCreateCompanyQuestion> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/company/create-questions/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns CompanyRequestedDocumentModel
   * @throws ApiError
   */
  public static apiDashboardCompanyCreateRequestedDocumentsCreate(
    data: CompanyRequestedDocumentModel
  ): CancelablePromise<CompanyRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/company/create-requested-documents/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns AddOrRemoveRequestItems
   * @throws ApiError
   */
  public static apiDashboardCompanyModifyExistingRequestCreate(
    data: AddOrRemoveRequestItems
  ): CancelablePromise<AddOrRemoveRequestItems> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/company/modify-existing-request/',
      body: data,
    });
  }

  /**
   * @param data
   * @returns CompanyPrimaryContact
   * @throws ApiError
   */
  public static apiDashboardCompanyPrimaryContactCreate(
    data: CompanyPrimaryContact
  ): CancelablePromise<CompanyPrimaryContact> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/company/primary-contact/',
      body: data,
    });
  }

  /**
   * @param companyId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiDashboardCompanyRead(
    companyId: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<ScalarOpinionView>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dashboard/company/{company_id}',
      path: {
        company_id: companyId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param companyId
   * @param data
   * @returns CreateCompanyInformationRequest
   * @throws ApiError
   */
  public static apiDashboardCompanyCreateInformationRequestCreate(
    companyId: string,
    data: CreateCompanyInformationRequest
  ): CancelablePromise<CreateCompanyInformationRequest> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/company/{company_id}/create-information-request/',
      path: {
        company_id: companyId,
      },
      body: data,
    });
  }

  /**
   * @param companyId
   * @returns CompanyRequestedInformation
   * @throws ApiError
   */
  public static apiDashboardCompanyRequestedInformationRead(
    companyId: string
  ): CancelablePromise<CompanyRequestedInformation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dashboard/company/{company_id}/requested-information',
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * @param cmdId
   * @returns any
   * @throws ApiError
   */
  public static apiDashboardCurrentInformationRequestsRead(cmdId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dashboard/current-information-requests/{cmd_id}',
      path: {
        cmd_id: cmdId,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiDashboardPublicCompaniesCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dashboard/public-companies',
    });
  }

  /**
   * @param cmdId
   * @returns ValuationOpinionModule
   * @throws ApiError
   */
  public static apiDashboardRead(cmdId: string): CancelablePromise<ValuationOpinionModule> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dashboard/{cmd_id}',
      path: {
        cmd_id: cmdId,
      },
    });
  }

  /**
   * @param cmdId
   * @returns any
   * @throws ApiError
   */
  public static apiDashboardGpcCompsRead(cmdId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dashboard/{cmd_id}/gpc-comps',
      path: {
        cmd_id: cmdId,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this measurement date documents.
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsCompanyRead(
    id: number,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CompanyDocuments>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/company/{id}',
      path: {
        id: id,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param mdId
   * @param data
   * @returns CreateOrDeleteDocumentReferenceObject
   * @throws ApiError
   */
  public static apiDocumentsDocumentReferenceObjectsCreate(
    mdId: string,
    data: CreateOrDeleteDocumentReferenceObject
  ): CancelablePromise<CreateOrDeleteDocumentReferenceObject> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/document-reference-objects/{md_id}',
      path: {
        md_id: mdId,
      },
      body: data,
    });
  }

  /**
   * @param fileId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsDownloadRead(fileId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/download/{file_id}',
      path: {
        file_id: fileId,
      },
    });
  }

  /**
   * @param mdId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFeatureObjectsRead(mdId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/feature-objects/{md_id}',
      path: {
        md_id: mdId,
      },
    });
  }

  /**
   * @param mdPk
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFileUploadRequestRead(mdPk: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/file-upload-request/{md_pk}',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param mdPk
   * @param fileUuid
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFileUploadRequestRead1(mdPk: string, fileUuid: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/file-upload-request/{md_pk}/{file_uuid}',
      path: {
        md_pk: mdPk,
        file_uuid: fileUuid,
      },
    });
  }

  /**
   * @param mdPk
   * @param companyId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsHasDocumentsRead(mdPk: string, companyId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/has-documents/{md_pk}/{company_id}',
      path: {
        md_pk: mdPk,
        company_id: companyId,
      },
    });
  }

  /**
   * @param mdId
   * @param filePk
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsReferenceCreate(mdId: string, filePk: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/reference/{md_id}/{file_pk}',
      path: {
        md_id: mdId,
        file_pk: filePk,
      },
    });
  }

  /**
   * @param mdId
   * @param filePk
   * @returns void
   * @throws ApiError
   */
  public static apiDocumentsReferenceDelete(mdId: string, filePk: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/documents/reference/{md_id}/{file_pk}',
      path: {
        md_id: mdId,
        file_pk: filePk,
      },
    });
  }

  /**
   * @param mdId
   * @param filePk
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsReferenceObjectCreate(mdId: string, filePk: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/reference_object/{md_id}/{file_pk}',
      path: {
        md_id: mdId,
        file_pk: filePk,
      },
    });
  }

  /**
   * @param mdId
   * @param filePk
   * @returns void
   * @throws ApiError
   */
  public static apiDocumentsReferenceObjectDelete(mdId: string, filePk: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/documents/reference_object/{md_id}/{file_pk}',
      path: {
        md_id: mdId,
        file_pk: filePk,
      },
    });
  }

  /**
   * @param data
   * @returns DocumentRequestView
   * @throws ApiError
   */
  public static apiDocumentsRequestCreate(data: DocumentRequestView): CancelablePromise<DocumentRequestView> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/request',
      body: data,
    });
  }

  /**
   * @param data
   * @returns IndividualRequestedFile
   * @throws ApiError
   */
  public static apiDocumentsRequestedFileCreate(
    data: IndividualRequestedFile
  ): CancelablePromise<IndividualRequestedFile> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/requested-file',
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this requested file.
   * @param data
   * @returns IndividualRequestedFile
   * @throws ApiError
   */
  public static apiDocumentsRequestedFileUpdate(
    id: number,
    data: IndividualRequestedFile
  ): CancelablePromise<IndividualRequestedFile> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/documents/requested-file/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this requested file.
   * @param data
   * @returns IndividualRequestedFile
   * @throws ApiError
   */
  public static apiDocumentsRequestedFilePartialUpdate(
    id: number,
    data: IndividualRequestedFile
  ): CancelablePromise<IndividualRequestedFile> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/documents/requested-file/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this requested file.
   * @returns void
   * @throws ApiError
   */
  public static apiDocumentsRequestedFileDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/documents/requested-file/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * After each successful upload (to bucket) we hit this endpoint to create File and DocumentReference objects,
   * to update the RequestedFile status, and check if the entire task is done.
   * @param documentRequestId
   * @param hmacToken
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsRequestsFileUploadStatusCreate(
    documentRequestId: string,
    hmacToken: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/requests/file-upload-status/{document_request_id}/{hmac_token}',
      path: {
        document_request_id: documentRequestId,
        hmac_token: hmacToken,
      },
    });
  }

  /**
   * @param documentRequestId
   * @param hmacToken
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsRequestsValidateTokenRead(
    documentRequestId: string,
    hmacToken: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/requests/validate-token/{document_request_id}/{hmac_token}',
      path: {
        document_request_id: documentRequestId,
        hmac_token: hmacToken,
      },
    });
  }

  /**
   * @param documentRequestId
   * @param hmacToken
   * @param mdPk
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsRequestsSignedUrlRead(
    documentRequestId: string,
    hmacToken: string,
    mdPk: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/requests/{document_request_id}/{hmac_token}/signed-url/{md_pk}',
      path: {
        document_request_id: documentRequestId,
        hmac_token: hmacToken,
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param documentRequestId
   * @param hmacToken
   * @param taskId
   * @param data
   * @returns TaskModel
   * @throws ApiError
   */
  public static apiDocumentsRequestsTaskStatusUpdate(
    documentRequestId: string,
    hmacToken: string,
    taskId: string,
    data: TaskModel
  ): CancelablePromise<TaskModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/documents/requests/{document_request_id}/{hmac_token}/task-status/{task_id}',
      path: {
        document_request_id: documentRequestId,
        hmac_token: hmacToken,
        task_id: taskId,
      },
      body: data,
    });
  }

  /**
   * @param documentRequestId
   * @param hmacToken
   * @param taskId
   * @param data
   * @returns TaskModel
   * @throws ApiError
   */
  public static apiDocumentsRequestsTaskStatusPartialUpdate(
    documentRequestId: string,
    hmacToken: string,
    taskId: string,
    data: TaskModel
  ): CancelablePromise<TaskModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/documents/requests/{document_request_id}/{hmac_token}/task-status/{task_id}',
      path: {
        document_request_id: documentRequestId,
        hmac_token: hmacToken,
        task_id: taskId,
      },
      body: data,
    });
  }

  /**
   * @param docsId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsAddToFolderCreate(docsId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/{docs_id}/add-to-folder',
      path: {
        docs_id: docsId,
      },
    });
  }

  /**
   * @param docsId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFilesCreate(docsId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/{docs_id}/files',
      path: {
        docs_id: docsId,
      },
    });
  }

  /**
   * @param docsId
   * @param fileId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFilesRead(docsId: string, fileId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/{docs_id}/files/{file_id}',
      path: {
        docs_id: docsId,
        file_id: fileId,
      },
    });
  }

  /**
   * @param docsId
   * @param fileId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFilesUpdate(docsId: string, fileId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/documents/{docs_id}/files/{file_id}',
      path: {
        docs_id: docsId,
        file_id: fileId,
      },
    });
  }

  /**
   * @param docsId
   * @param fileId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFilesPartialUpdate(docsId: string, fileId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/documents/{docs_id}/files/{file_id}',
      path: {
        docs_id: docsId,
        file_id: fileId,
      },
    });
  }

  /**
   * @param docsId
   * @param fileId
   * @returns void
   * @throws ApiError
   */
  public static apiDocumentsFilesDelete(docsId: string, fileId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/documents/{docs_id}/files/{file_id}',
      path: {
        docs_id: docsId,
        file_id: fileId,
      },
    });
  }

  /**
   * @param docsId
   * @param fileId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFilesVersionsRead(docsId: string, fileId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/{docs_id}/files/{file_id}/versions',
      path: {
        docs_id: docsId,
        file_id: fileId,
      },
    });
  }

  /**
   * @param docsId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFolderCreate(docsId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/documents/{docs_id}/folder',
      path: {
        docs_id: docsId,
      },
    });
  }

  /**
   * @param docsId
   * @param folderId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFolderRead(docsId: string, folderId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/{docs_id}/folder/{folder_id}',
      path: {
        docs_id: docsId,
        folder_id: folderId,
      },
    });
  }

  /**
   * @param docsId
   * @param folderId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFolderUpdate(docsId: string, folderId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/documents/{docs_id}/folder/{folder_id}',
      path: {
        docs_id: docsId,
        folder_id: folderId,
      },
    });
  }

  /**
   * @param docsId
   * @param folderId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsFolderPartialUpdate(docsId: string, folderId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/documents/{docs_id}/folder/{folder_id}',
      path: {
        docs_id: docsId,
        folder_id: folderId,
      },
    });
  }

  /**
   * @param docsId
   * @param folderId
   * @returns void
   * @throws ApiError
   */
  public static apiDocumentsFolderDelete(docsId: string, folderId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/documents/{docs_id}/folder/{folder_id}',
      path: {
        docs_id: docsId,
        folder_id: folderId,
      },
    });
  }

  /**
   * @param mdId
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsReferencesRead(mdId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/{md_id}/references',
      path: {
        md_id: mdId,
      },
    });
  }

  /**
   * @param mdPk
   * @returns any
   * @throws ApiError
   */
  public static apiDocumentsRead(mdPk: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/documents/{md_pk}',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param data
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageCreate(
    data: FinancialStatementFullWrite
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/financials/financial_page/',
      body: data,
    });
  }

  /**
   * @param cmdPk
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageFinancialRfydRead(
    cmdPk: string
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/financials/financial_page/{cmd_pk}/financial_rfyd/',
      path: {
        cmd_pk: cmdPk,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this financial statement.
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageRead(id: number): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/financials/financial_page/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this financial statement.
   * @param data
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageCreate1(
    id: number,
    data: FinancialStatementFullWrite
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/financials/financial_page/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this financial statement.
   * @param data
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageUpdate(
    id: number,
    data: FinancialStatementFullWrite
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/financials/financial_page/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this financial statement.
   * @param data
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPagePartialUpdate(
    id: number,
    data: FinancialStatementFullWrite
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/financials/financial_page/{id}/',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this financial statement.
   * @returns void
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/financials/financial_page/{id}/',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this financial statement.
   * @param data
   * @returns FinancialStatementVersion
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageCreateVersionUpdate(
    id: number,
    data: FinancialStatementVersion
  ): CancelablePromise<FinancialStatementVersion> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/financials/financial_page/{id}/create_version/',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this financial statement.
   * @param data
   * @returns FinancialStatementVersion
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageCreateVersionPartialUpdate(
    id: number,
    data: FinancialStatementVersion
  ): CancelablePromise<FinancialStatementVersion> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/financials/financial_page/{id}/create_version/',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param mdPk
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageFinancialLtmNtmRead(
    mdPk: string
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/financials/financial_page/{md_pk}/financial_ltm_ntm/',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param mdPk
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageFinancialsBasicDataListList(
    mdPk: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<FinancialStatementBasicData>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/financials/financial_page/{md_pk}/financials_basic_data_list/',
      path: {
        md_pk: mdPk,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param mdPk
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageGetByMeasurementDateRead(
    mdPk: string
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/financials/financial_page/{md_pk}/get_by_measurement_date/',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param mdPk
   * @param data
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageGetByMeasurementDateUpdate(
    mdPk: string,
    data: FinancialStatementFullWrite
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/financials/financial_page/{md_pk}/get_by_measurement_date/',
      path: {
        md_pk: mdPk,
      },
      body: data,
    });
  }

  /**
   * @param mdPk
   * @param data
   * @returns FinancialStatementFullWrite
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageGetByMeasurementDatePartialUpdate(
    mdPk: string,
    data: FinancialStatementFullWrite
  ): CancelablePromise<FinancialStatementFullWrite> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/financials/financial_page/{md_pk}/get_by_measurement_date/',
      path: {
        md_pk: mdPk,
      },
      body: data,
    });
  }

  /**
   * @param mdPk
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFinancialsFinancialPageListByMeasurementPeriodList(
    mdPk: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<FinancialStatementFullWrite>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/financials/financial_page/{md_pk}/list_by_measurement_period/',
      path: {
        md_pk: mdPk,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFirmsList(page?: number): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<FirmUserPermission>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms',
      query: {
        page: page,
      },
    });
  }

  /**
   * @param data
   * @returns FirmCompany
   * @throws ApiError
   */
  public static apiFirmsCompaniesNewCreate(data: FirmCompany): CancelablePromise<FirmCompany> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/firms/companies/new',
      body: data,
    });
  }

  /**
   * @param data
   * @returns FirmRequestedDocumentModel
   * @throws ApiError
   */
  public static apiFirmsCreateFirmRequestedDocumentCreate(
    data: FirmRequestedDocumentModel
  ): CancelablePromise<FirmRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/firms/create-firm-requested-document',
      body: data,
    });
  }

  /**
   * @param data
   * @returns CreateFirmRequests
   * @throws ApiError
   */
  public static apiFirmsCreateRequestsCreate(data: CreateFirmRequests): CancelablePromise<CreateFirmRequests> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/firms/create-requests',
      body: data,
    });
  }

  /**
   * @param requestedDocumentId
   * @returns FirmRequestedDocumentModel
   * @throws ApiError
   */
  public static apiFirmsFirmRequestedDocumentRead(
    requestedDocumentId: string
  ): CancelablePromise<FirmRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/firm-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
    });
  }

  /**
   * @param requestedDocumentId
   * @param data
   * @returns FirmRequestedDocumentModel
   * @throws ApiError
   */
  public static apiFirmsFirmRequestedDocumentUpdate(
    requestedDocumentId: string,
    data: FirmRequestedDocumentModel
  ): CancelablePromise<FirmRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/firms/firm-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
      body: data,
    });
  }

  /**
   * @param requestedDocumentId
   * @param data
   * @returns FirmRequestedDocumentModel
   * @throws ApiError
   */
  public static apiFirmsFirmRequestedDocumentPartialUpdate(
    requestedDocumentId: string,
    data: FirmRequestedDocumentModel
  ): CancelablePromise<FirmRequestedDocumentModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/firms/firm-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
      body: data,
    });
  }

  /**
   * @param requestedDocumentId
   * @returns void
   * @throws ApiError
   */
  public static apiFirmsFirmRequestedDocumentDelete(requestedDocumentId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/firms/firm-requested-document/{requested_document_id}',
      path: {
        requested_document_id: requestedDocumentId,
      },
    });
  }

  /**
   * @param data
   * @returns FirmStepper
   * @throws ApiError
   */
  public static apiFirmsNewCreate(data: FirmStepper): CancelablePromise<FirmStepper> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/firms/new',
      body: data,
    });
  }

  /**
   * @param questionId
   * @returns UpdateFirmQuestion
   * @throws ApiError
   */
  public static apiFirmsQuestionRead(questionId: string): CancelablePromise<UpdateFirmQuestion> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/question/{question_id}',
      path: {
        question_id: questionId,
      },
    });
  }

  /**
   * @param questionId
   * @param data
   * @returns UpdateFirmQuestion
   * @throws ApiError
   */
  public static apiFirmsQuestionUpdate(
    questionId: string,
    data: UpdateFirmQuestion
  ): CancelablePromise<UpdateFirmQuestion> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/firms/question/{question_id}',
      path: {
        question_id: questionId,
      },
      body: data,
    });
  }

  /**
   * @param questionId
   * @param data
   * @returns UpdateFirmQuestion
   * @throws ApiError
   */
  public static apiFirmsQuestionPartialUpdate(
    questionId: string,
    data: UpdateFirmQuestion
  ): CancelablePromise<UpdateFirmQuestion> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/firms/question/{question_id}',
      path: {
        question_id: questionId,
      },
      body: data,
    });
  }

  /**
   * @param questionId
   * @returns void
   * @throws ApiError
   */
  public static apiFirmsQuestionDelete(questionId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/firms/question/{question_id}',
      path: {
        question_id: questionId,
      },
    });
  }

  /**
   * @param firmId
   * @returns FirmCompaniesRequestsProgress
   * @throws ApiError
   */
  public static apiFirmsRequestsProgressRead(firmId: string): CancelablePromise<FirmCompaniesRequestsProgress> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/{firm_id}/requests-progress',
      path: {
        firm_id: firmId,
      },
    });
  }

  /**
   * @param firmPk
   * @param date
   * @returns any
   * @throws ApiError
   */
  public static apiFirmsCompaniesListRead(firmPk: string, date: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/{firm_pk}/companies-list/{date}',
      path: {
        firm_pk: firmPk,
        date: date,
      },
    });
  }

  /**
   * @param firmPk
   * @param data
   * @returns Firm
   * @throws ApiError
   */
  public static apiFirmsUsersUpdate(firmPk: string, data: Firm): CancelablePromise<Firm> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/firms/{firm_pk}/users',
      path: {
        firm_pk: firmPk,
      },
      body: data,
    });
  }

  /**
   * @param firmPk
   * @param data
   * @returns Firm
   * @throws ApiError
   */
  public static apiFirmsUsersPartialUpdate(firmPk: string, data: Firm): CancelablePromise<Firm> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/firms/{firm_pk}/users',
      path: {
        firm_pk: firmPk,
      },
      body: data,
    });
  }

  /**
   * @param firmPk
   * @param userPk
   * @param data
   * @returns Firm
   * @throws ApiError
   */
  public static apiFirmsUsersUpdate1(firmPk: string, userPk: string, data: Firm): CancelablePromise<Firm> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/firms/{firm_pk}/users/{user_pk}',
      path: {
        firm_pk: firmPk,
        user_pk: userPk,
      },
      body: data,
    });
  }

  /**
   * @param firmPk
   * @param userPk
   * @param data
   * @returns Firm
   * @throws ApiError
   */
  public static apiFirmsUsersPartialUpdate1(firmPk: string, userPk: string, data: Firm): CancelablePromise<Firm> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/firms/{firm_pk}/users/{user_pk}',
      path: {
        firm_pk: firmPk,
        user_pk: userPk,
      },
      body: data,
    });
  }

  /**
   * @param firmPk
   * @param userPk
   * @returns void
   * @throws ApiError
   */
  public static apiFirmsUsersDelete(firmPk: string, userPk: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/firms/{firm_pk}/users/{user_pk}',
      path: {
        firm_pk: firmPk,
        user_pk: userPk,
      },
    });
  }

  /**
   * @param firmPk
   * @param userPk
   * @param data
   * @returns UserFirmRole
   * @throws ApiError
   */
  public static apiFirmsUsersRolesUpdate(
    firmPk: string,
    userPk: string,
    data: UserFirmRole
  ): CancelablePromise<UserFirmRole> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/firms/{firm_pk}/users/{user_pk}/roles',
      path: {
        firm_pk: firmPk,
        user_pk: userPk,
      },
      body: data,
    });
  }

  /**
   * @param firmPk
   * @param userPk
   * @param data
   * @returns UserFirmRole
   * @throws ApiError
   */
  public static apiFirmsUsersRolesPartialUpdate(
    firmPk: string,
    userPk: string,
    data: UserFirmRole
  ): CancelablePromise<UserFirmRole> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/firms/{firm_pk}/users/{user_pk}/roles',
      path: {
        firm_pk: firmPk,
        user_pk: userPk,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this firm.
   * @returns Firm
   * @throws ApiError
   */
  public static apiFirmsRead(id: number): CancelablePromise<Firm> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this firm.
   * @param data
   * @returns Firm
   * @throws ApiError
   */
  public static apiFirmsUpdate(id: number, data: Firm): CancelablePromise<Firm> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/firms/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this firm.
   * @param data
   * @returns Firm
   * @throws ApiError
   */
  public static apiFirmsPartialUpdate(id: number, data: Firm): CancelablePromise<Firm> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/firms/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFirmsCompaniesList(id: string, page?: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/{id}/companies',
      path: {
        id: id,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id
   * @param cId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFirmsCompanyRead(
    id: string,
    cId: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<ExtendedCompanyInfo>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/{id}/company/{c_id}',
      path: {
        id: id,
        c_id: cId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this fund.
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFirmsFundsList(
    id: number,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<FundName>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/{id}/funds',
      path: {
        id: id,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiFirmsSummaryRead(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/firms/{id}/summary',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param data
   * @returns Fund
   * @throws ApiError
   */
  public static apiFundsCreate(data: Fund): CancelablePromise<Fund> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/funds',
      body: data,
    });
  }

  /**
   * @param fuId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFundsCompaniesList(fuId: string, page?: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/funds/{fu_id}/companies/',
      path: {
        fu_id: fuId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id
   * @returns Fund
   * @throws ApiError
   */
  public static apiFundsRead(id: string): CancelablePromise<Fund> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/funds/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param data
   * @returns Fund
   * @throws ApiError
   */
  public static apiFundsUpdate(id: string, data: Fund): CancelablePromise<Fund> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/funds/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @param data
   * @returns Fund
   * @throws ApiError
   */
  public static apiFundsPartialUpdate(id: string, data: Fund): CancelablePromise<Fund> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/funds/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static apiFundsDelete(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/funds/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiFundsAllocationsList(
    id: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<AllocationConclusionModel>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/funds/{id}/allocations',
      path: {
        id: id,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiFundsInvestmentsRead(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/funds/{id}/investments',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiFundsSummaryRead(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/funds/{id}/summary',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param data
   * @returns UpdateIndividualQuestion
   * @throws ApiError
   */
  public static apiIndividualQuestionsCreate(
    data: UpdateIndividualQuestion
  ): CancelablePromise<UpdateIndividualQuestion> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/individual-questions/',
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this individual question.
   * @param data
   * @returns UpdateIndividualQuestion
   * @throws ApiError
   */
  public static apiIndividualQuestionsUpdate(
    id: number,
    data: UpdateIndividualQuestion
  ): CancelablePromise<UpdateIndividualQuestion> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/individual-questions/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this individual question.
   * @param data
   * @returns UpdateIndividualQuestion
   * @throws ApiError
   */
  public static apiIndividualQuestionsPartialUpdate(
    id: number,
    data: UpdateIndividualQuestion
  ): CancelablePromise<UpdateIndividualQuestion> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/individual-questions/{id}',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this individual question.
   * @returns void
   * @throws ApiError
   */
  public static apiIndividualQuestionsDelete(id: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/individual-questions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param reportUuid
   * @returns any
   * @throws ApiError
   */
  public static apiInvestorAppCheckExcelReportCreate(reportUuid: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/investor-app/check-excel-report/{reportUUID}',
      path: {
        reportUUID: reportUuid,
      },
    });
  }

  /**
   * @param reportUuid
   * @returns any
   * @throws ApiError
   */
  public static apiInvestorAppExcelReportCreate(reportUuid: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/investor-app/excel-report/{reportUUID}',
      path: {
        reportUUID: reportUuid,
      },
    });
  }

  /**
   * This APIView is used to get the measurement dates for a firm. It will return all the measurement dates
   * excluding the repeated ones, in other words the measurement dates with the same dates but for diferent
   * companies and funds, will be "merged" into one.
   * @param firmId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiMeasurementDatesByFirmRead(firmId: string, page?: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/measurement-dates/by-firm/{firm_id}',
      path: {
        firm_id: firmId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param data
   * @returns MeasurementDateRolloverReturn Success
   * @throws ApiError
   */
  public static apiMeasurementDatesCompanyRolloverCreate(
    data: MeasurementDateRollover
  ): CancelablePromise<MeasurementDateRolloverReturn> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/measurement-dates/company/rollover',
      body: data,
    });
  }

  /**
   * @param companyId
   * @returns MeasurementDateName
   * @throws ApiError
   */
  public static apiMeasurementDatesCompanyRead(companyId: string): CancelablePromise<Array<MeasurementDateName>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/measurement-dates/company/{company_id}',
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * Concrete view for bulk delete Company/Fund measurement dates and all their related info.
   * @returns any
   * @throws ApiError
   */
  public static apiMeasurementDatesDeleteRelatedInfoCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/measurement-dates/delete-related-info',
    });
  }

  /**
   * @param firmId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiMeasurementDatesFirmRead(firmId: string, page?: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/measurement-dates/firm/{firm_id}',
      path: {
        firm_id: firmId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * This APIView is used to get the company/fund measurement dates from
   * measurement dates with the same date and firm, we merge the info into
   * one dictionary as a virtual "Measurement Date" and then we return it through
   * the serializer.
   * @param firmId
   * @param date
   * @returns any
   * @throws ApiError
   */
  public static apiMeasurementDatesFirmDateRead(firmId: string, date: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/measurement-dates/firm/{firm_id}/date/{date}',
      path: {
        firm_id: firmId,
        date: date,
      },
    });
  }

  /**
   * @param fundId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiMeasurementDatesFundsRead(
    fundId: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<MeasurementDateName>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/measurement-dates/funds/{fund_id}',
      path: {
        fund_id: fundId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * Concrete view for bulk saving a model instances.
   * @param data
   * @returns Note
   * @throws ApiError
   */
  public static apiNotesCreate(data: Note): CancelablePromise<Note> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/notes/',
      body: data,
    });
  }

  /**
   * @param pageType page_type
   * @param capTable cap_table
   * @param fundOwnership fund_ownership
   * @param financialStatement financial_statement
   * @param fundMeasurementDate fund_measurement_date
   * @param allocation allocation
   * @param valuationApproach valuation_approach
   * @param valuationApproachValuation valuation_approach__valuation
   * @param companyMeasurementDate company_measurement_date
   * @returns NoteGet
   * @throws ApiError
   */
  public static apiNotesListList(
    pageType?: 'ct' | 'fo' | 'fi' | 'va' | 'alloc' | 'brkpts' | 'cs' | 'fs' | 'wtrfl',
    capTable?: string,
    fundOwnership?: string,
    financialStatement?: string,
    fundMeasurementDate?: string,
    allocation?: string,
    valuationApproach?: string,
    valuationApproachValuation?: string,
    companyMeasurementDate?: string
  ): CancelablePromise<Array<NoteGet>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/notes/list',
      query: {
        page_type: pageType,
        cap_table: capTable,
        fund_ownership: fundOwnership,
        financial_statement: financialStatement,
        fund_measurement_date: fundMeasurementDate,
        allocation: allocation,
        valuation_approach: valuationApproach,
        valuation_approach__valuation: valuationApproachValuation,
        company_measurement_date: companyMeasurementDate,
      },
    });
  }

  /**
   * @param id A unique integer value identifying this note.
   * @returns NoteGet
   * @throws ApiError
   */
  public static apiNotesRead(id: number): CancelablePromise<NoteGet> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/notes/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiOauth2SocialCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/oauth2/social/',
    });
  }

  /**
   * @param maturity
   * @param mdDate
   * @returns any
   * @throws ApiError
   */
  public static apiObservationsReadByDate(maturity: string, mdDate: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/observations/{maturity}/{md_date}',
      path: {
        maturity: maturity,
        md_date: mdDate,
      },
    });
  }

  /**
   * @param maturity
   * @param mdPk
   * @returns any
   * @throws ApiError
   */
  public static apiObservationsRead(maturity: string, mdPk: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/observations/{maturity}/{md_pk}',
      path: {
        maturity: maturity,
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param firmId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiPendingDecisionsRead(
    firmId: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<UpdateDecisionsView>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pending-decisions/{firm_id}',
      path: {
        firm_id: firmId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param firmId
   * @param data
   * @returns UpdateDecisionsView
   * @throws ApiError
   */
  public static apiPendingDecisionsCreate(
    firmId: string,
    data: UpdateDecisionsView
  ): CancelablePromise<UpdateDecisionsView> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/pending-decisions/{firm_id}',
      path: {
        firm_id: firmId,
      },
      body: data,
    });
  }

  /**
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiProfilesList(page?: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profiles',
      query: {
        page: page,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiProfilesCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/profiles',
    });
  }

  /**
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiProfilesMe(page?: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profiles/me',
      query: {
        page: page,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiProfilesRead(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/profiles/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiProfilesUpdate(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/profiles/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiProfilesPartialUpdate(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/profiles/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static apiProfilesDelete(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/profiles/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ChoiceAnswerModel
   * @throws ApiError
   */
  public static apiQuestionnairesChoicesForDropdownsList(): CancelablePromise<Array<ChoiceAnswerModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/questionnaires/choices-for-dropdowns/',
    });
  }

  /**
   * @param questionnaireId
   * @param hmacToken
   * @returns any
   * @throws ApiError
   */
  public static apiQuestionnairesRequestsValidateTokenRead(
    questionnaireId: string,
    hmacToken: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/questionnaires/requests/validate-token/{questionnaire_id}/{hmac_token}',
      path: {
        questionnaire_id: questionnaireId,
        hmac_token: hmacToken,
      },
    });
  }

  /**
   * @param questionnaireId
   * @param hmacToken
   * @returns any
   * @throws ApiError
   */
  public static apiQuestionnairesRequestsValidateTokenAnswerQuestionCreate(
    questionnaireId: string,
    hmacToken: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/questionnaires/requests/validate-token/{questionnaire_id}/{hmac_token}/answer-question',
      path: {
        questionnaire_id: questionnaireId,
        hmac_token: hmacToken,
      },
    });
  }

  /**
   * @param reportDateId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiRetrieveInformationRequestStatusRead(
    reportDateId: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<InformationRequestStatus>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/retrieve-information-request-status/{report_date_id}',
      path: {
        report_date_id: reportDateId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param companyId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsCompanyPaymentMethodsList(companyId: string, page?: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/subscriptions/company/{company_id}/payment-methods',
      path: {
        company_id: companyId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param companyId
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsCompanyPaymentMethodsCreate(companyId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/subscriptions/company/{company_id}/payment-methods',
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * @param companyId
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsCompanyPaymentsRead(companyId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/subscriptions/company/{company_id}/payments',
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * @param companyId
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsCompanySubscriptionRead(companyId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/subscriptions/company/{company_id}/subscription',
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * @param companyId
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsCompanySubscriptionUpdate(companyId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/subscriptions/company/{company_id}/subscription',
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * @param companyId
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsCompanySubscriptionPartialUpdate(companyId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/subscriptions/company/{company_id}/subscription',
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsInitiatePaymentCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/subscriptions/initiate-payment',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiSubscriptionsSubscriptionSetupIntentCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/subscriptions/subscription/setup-intent',
    });
  }

  /**
   * @param data
   * @returns CreateOnboardingTasks
   * @throws ApiError
   */
  public static apiTasksOnboardingCreate(data: CreateOnboardingTasks): CancelablePromise<CreateOnboardingTasks> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/tasks/onboarding',
      body: data,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiTasksShareCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/tasks/share',
    });
  }

  /**
   * @param cmdId
   * @returns Portal
   * @throws ApiError
   */
  public static apiTasksPortalLinksRead(cmdId: string): CancelablePromise<Portal> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/tasks/{cmd_id}/portal-links',
      path: {
        cmd_id: cmdId,
      },
    });
  }

  /**
   * @param taskId
   * @returns TaskDetailedProgress
   * @throws ApiError
   */
  public static apiTasksProgressDetailRead(taskId: string): CancelablePromise<TaskDetailedProgress> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/tasks/{task_id}/progress-detail',
      path: {
        task_id: taskId,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiTotpCreateList(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/totp/create/',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiTotpDeleteCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/totp/delete/',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiTotpGetList(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/totp/get/',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiTotpStaticCreateCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/totp/static/create/',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiTotpStaticGetList(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/totp/static/get/',
    });
  }

  /**
   * @param token
   * @returns any
   * @throws ApiError
   */
  public static apiTotpStaticVerifyCreate(token: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/totp/static/verify/{token}/',
      path: {
        token: token,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiTotpUpdateCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/totp/update/',
    });
  }

  /**
   * @param token
   * @returns any
   * @throws ApiError
   */
  public static apiTotpVerifyCreate(token: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/totp/verify/{token}/',
      path: {
        token: token,
      },
    });
  }

  /**
   * @param informationRequestStatusId
   * @param data
   * @returns TaskDetailedProgress
   * @throws ApiError
   */
  public static apiUpdateInformationRequestStatusUpdate(
    informationRequestStatusId: string,
    data: UpdateDocumentAndQuestionnaireTasks
  ): CancelablePromise<Array<TaskDetailedProgress>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/update-information-request-status/{information_request_status_id}',
      path: {
        information_request_status_id: informationRequestStatusId,
      },
      body: data,
    });
  }

  /**
   * @param informationRequestStatusId
   * @param data
   * @returns UpdateDocumentAndQuestionnaireTasks
   * @throws ApiError
   */
  public static apiUpdateInformationRequestStatusPartialUpdate(
    informationRequestStatusId: string,
    data: UpdateDocumentAndQuestionnaireTasks
  ): CancelablePromise<UpdateDocumentAndQuestionnaireTasks> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/update-information-request-status/{information_request_status_id}',
      path: {
        information_request_status_id: informationRequestStatusId,
      },
      body: data,
    });
  }

  /**
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiUserRead(): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user',
    });
  }

  /**
   * @param id A unique integer value identifying this user.
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiUserRead1(id: number): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiUserDeleteUpdate(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/{id}/delete',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static apiUserDeletePartialUpdate(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/user/{id}/delete',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static apiUserDeleteDelete(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/user/{id}/delete',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns UserPermissionWithPath
   * @throws ApiError
   */
  public static apiUserLowerPermissionsList(id: string): CancelablePromise<Array<UserPermissionWithPath>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/{id}/lower-permissions',
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param firmId
   * @returns UserPermission
   * @throws ApiError
   */
  public static apiUserPermissionsRead(id: string, firmId: string): CancelablePromise<Array<UserPermission>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/{id}/permissions/{firm_id}',
      path: {
        id: id,
        firm_id: firmId,
      },
    });
  }

  /**
   * @param search A search term.
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiUsersList(search?: string): CancelablePromise<Array<CustomUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/users',
      query: {
        search: search,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiUsersCreateBulkUsersCreate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/users/create-bulk-users',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiUsersPermissionsUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/users/permissions',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiUsersPermissionsPartialUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/users/permissions',
    });
  }

  /**
   * @param alId
   * @returns ValuationAllocationModel
   * @throws ApiError
   */
  public static apiValuationAllocationRead(alId: string): CancelablePromise<ValuationAllocationModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation-allocation/{al_id}',
      path: {
        al_id: alId,
      },
    });
  }

  /**
   * @param alId
   * @param data
   * @returns ValuationAllocationModel
   * @throws ApiError
   */
  public static apiValuationAllocationUpdate(
    alId: string,
    data: ValuationAllocationModel
  ): CancelablePromise<ValuationAllocationModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation-allocation/{al_id}',
      path: {
        al_id: alId,
      },
      body: data,
    });
  }

  /**
   * @param alId
   * @param data
   * @returns ValuationAllocationModel
   * @throws ApiError
   */
  public static apiValuationAllocationPartialUpdate(
    alId: string,
    data: ValuationAllocationModel
  ): CancelablePromise<ValuationAllocationModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation-allocation/{al_id}',
      path: {
        al_id: alId,
      },
      body: data,
    });
  }

  /**
   * @param alId
   * @returns NewValuationAllocationVersion
   * @throws ApiError
   */
  public static apiValuationAllocationNewVersionRead(alId: string): CancelablePromise<NewValuationAllocationVersion> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation-allocation/{al_id}/new-version',
      path: {
        al_id: alId,
      },
    });
  }

  /**
   * @param alId
   * @param data
   * @returns NewValuationAllocationVersion
   * @throws ApiError
   */
  public static apiValuationAllocationNewVersionUpdate(
    alId: string,
    data: NewValuationAllocationVersion
  ): CancelablePromise<NewValuationAllocationVersion> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation-allocation/{al_id}/new-version',
      path: {
        al_id: alId,
      },
      body: data,
    });
  }

  /**
   * @param alId
   * @param data
   * @returns NewValuationAllocationVersion
   * @throws ApiError
   */
  public static apiValuationAllocationNewVersionPartialUpdate(
    alId: string,
    data: NewValuationAllocationVersion
  ): CancelablePromise<NewValuationAllocationVersion> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation-allocation/{al_id}/new-version',
      path: {
        al_id: alId,
      },
      body: data,
    });
  }

  /**
   * @param measurementDateId
   * @param allocationVersion
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiValuationApproachesRead(
    measurementDateId: string,
    allocationVersion: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<ValuationApproachModel>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/approaches/{measurement_date_id}/{allocation_version}/',
      path: {
        measurement_date_id: measurementDateId,
        allocation_version: allocationVersion,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param firmId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiValuationCompGroupFirmRead(
    firmId: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<CompGroupListCreate>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/comp-group/firm/{firm_id}',
      path: {
        firm_id: firmId,
      },
      query: {
        page: page,
      },
    });
  }

  /**
   * @param firmId
   * @param data
   * @returns CompGroupListCreate
   * @throws ApiError
   */
  public static apiValuationCompGroupFirmCreate(
    firmId: string,
    data: CompGroupListCreate
  ): CancelablePromise<CompGroupListCreate> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/valuation/comp-group/firm/{firm_id}',
      path: {
        firm_id: firmId,
      },
      body: data,
    });
  }

  /**
   * @param mdPk
   * @returns any
   * @throws ApiError
   */
  public static apiValuationGetExchangeRateRead(mdPk: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/get-exchange-rate/{md_pk}',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param transactionId
   * @returns TransactionId
   * @throws ApiError
   */
  public static apiValuationGetTransactionInformationRead(transactionId: string): CancelablePromise<TransactionId> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/get-transaction-information/{transaction_id}/',
      path: {
        transaction_id: transactionId,
      },
    });
  }

  /**
   * @param mdPk
   * @returns GPC
   * @throws ApiError
   */
  public static apiValuationGpcRead(mdPk: string): CancelablePromise<GPC> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/gpc/{md_pk}',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param mdPk
   * @param data
   * @returns GPC
   * @throws ApiError
   */
  public static apiValuationGpcUpdate(mdPk: string, data: GPC): CancelablePromise<GPC> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation/gpc/{md_pk}',
      path: {
        md_pk: mdPk,
      },
      body: data,
    });
  }

  /**
   * @param mdPk
   * @param data
   * @returns GPC
   * @throws ApiError
   */
  public static apiValuationGpcPartialUpdate(mdPk: string, data: GPC): CancelablePromise<GPC> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation/gpc/{md_pk}',
      path: {
        md_pk: mdPk,
      },
      body: data,
    });
  }

  /**
   * @param mdPk
   * @returns GPC
   * @throws ApiError
   */
  public static apiValuationPcListRead(mdPk: string): CancelablePromise<GPC> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/pc-list/{md_pk}',
      path: {
        md_pk: mdPk,
      },
    });
  }

  /**
   * @param mdPk
   * @param data
   * @returns GPC
   * @throws ApiError
   */
  public static apiValuationPcListUpdate(mdPk: string, data: GPC): CancelablePromise<GPC> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation/pc-list/{md_pk}',
      path: {
        md_pk: mdPk,
      },
      body: data,
    });
  }

  /**
   * @param mdPk
   * @param data
   * @returns GPC
   * @throws ApiError
   */
  public static apiValuationPcListPartialUpdate(mdPk: string, data: GPC): CancelablePromise<GPC> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation/pc-list/{md_pk}',
      path: {
        md_pk: mdPk,
      },
      body: data,
    });
  }

  /**
   * @param alId
   * @returns PrimaryApproachMultiples
   * @throws ApiError
   */
  public static apiValuationPrimaryApproachMultiplesRead(alId: string): CancelablePromise<PrimaryApproachMultiples> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/primary-approach-multiples/{al_id}/',
      path: {
        al_id: alId,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiValuationTickerSearchRead(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/ticker-search',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiValuationTickerSearchUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation/ticker-search',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiValuationTickerSearchPartialUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation/ticker-search',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiValuationTransferOwnershipUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation/transfer-ownership',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiValuationTransferOwnershipPartialUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation/transfer-ownership',
    });
  }

  /**
   * @param alId
   * @returns ValuationModel
   * @throws ApiError
   */
  public static apiValuationRead(alId: string): CancelablePromise<ValuationModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuation/{al_id}',
      path: {
        al_id: alId,
      },
    });
  }

  /**
   * @param alId
   * @param data
   * @returns ValuationModel
   * @throws ApiError
   */
  public static apiValuationUpdate(alId: string, data: ValuationModel): CancelablePromise<ValuationModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation/{al_id}',
      path: {
        al_id: alId,
      },
      body: data,
    });
  }

  /**
   * @param alId
   * @param data
   * @returns ValuationModel
   * @throws ApiError
   */
  public static apiValuationPartialUpdate(alId: string, data: ValuationModel): CancelablePromise<ValuationModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation/{al_id}',
      path: {
        al_id: alId,
      },
      body: data,
    });
  }

  /**
   * @param valuationId
   * @param data
   * @returns SetReadyForAudit
   * @throws ApiError
   */
  public static apiValuationReadyForAuditUpdate(
    valuationId: string,
    data: SetReadyForAudit
  ): CancelablePromise<SetReadyForAudit> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuation/{valuation_id}/ready-for-audit',
      path: {
        valuation_id: valuationId,
      },
      body: data,
    });
  }

  /**
   * @param valuationId
   * @param data
   * @returns SetReadyForAudit
   * @throws ApiError
   */
  public static apiValuationReadyForAuditPartialUpdate(
    valuationId: string,
    data: SetReadyForAudit
  ): CancelablePromise<SetReadyForAudit> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuation/{valuation_id}/ready-for-audit',
      path: {
        valuation_id: valuationId,
      },
      body: data,
    });
  }

  /**
   * @param data
   * @returns EffectiveTaxRateWriteModel
   * @throws ApiError
   */
  public static apiValuationsEffectiveTaxRateCreateCreate(
    data: EffectiveTaxRateWriteModel
  ): CancelablePromise<EffectiveTaxRateWriteModel> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/valuations/effective-tax-rate/create',
      body: data,
    });
  }

  /**
   * @param companyMeasurementDate
   * @returns EffectiveTaxRateModel
   * @throws ApiError
   */
  public static apiValuationsEffectiveTaxRateRead(
    companyMeasurementDate: string
  ): CancelablePromise<EffectiveTaxRateModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuations/effective-tax-rate/{company_measurement_date}',
      path: {
        company_measurement_date: companyMeasurementDate,
      },
    });
  }

  /**
   * @param companyMeasurementDate
   * @param data
   * @returns EffectiveTaxRateModel
   * @throws ApiError
   */
  public static apiValuationsEffectiveTaxRateUpdate(
    companyMeasurementDate: string,
    data: EffectiveTaxRateModel
  ): CancelablePromise<EffectiveTaxRateModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuations/effective-tax-rate/{company_measurement_date}',
      path: {
        company_measurement_date: companyMeasurementDate,
      },
      body: data,
    });
  }

  /**
   * @param companyMeasurementDate
   * @param data
   * @returns EffectiveTaxRateModel
   * @throws ApiError
   */
  public static apiValuationsEffectiveTaxRatePartialUpdate(
    companyMeasurementDate: string,
    data: EffectiveTaxRateModel
  ): CancelablePromise<EffectiveTaxRateModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuations/effective-tax-rate/{company_measurement_date}',
      path: {
        company_measurement_date: companyMeasurementDate,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this effective tax rate.
   * @param data
   * @returns EffectiveTaxRateWriteModel
   * @throws ApiError
   */
  public static apiValuationsEffectiveTaxRateUpdateUpdate(
    id: number,
    data: EffectiveTaxRateWriteModel
  ): CancelablePromise<EffectiveTaxRateWriteModel> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/valuations/effective-tax-rate/{id}/update',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param id A unique integer value identifying this effective tax rate.
   * @param data
   * @returns EffectiveTaxRateWriteModel
   * @throws ApiError
   */
  public static apiValuationsEffectiveTaxRateUpdatePartialUpdate(
    id: number,
    data: EffectiveTaxRateWriteModel
  ): CancelablePromise<EffectiveTaxRateWriteModel> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/valuations/effective-tax-rate/{id}/update',
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @param alId
   * @param page A page number within the paginated result set.
   * @returns any
   * @throws ApiError
   */
  public static apiValuationsRead(
    alId: string,
    page?: number
  ): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<ValuationModel>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/valuations/{al_id}',
      path: {
        al_id: alId,
      },
      query: {
        page: page,
      },
    });
  }
}
