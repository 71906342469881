import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import customCalibrationAfterCellsChanged from './customCalibrationAfterCellsChanged';
import customValidations from './customValidations';
import getRowConfigCalibrationInputs from './getRowConfigCalibrationInputs';
import parser from './parser';
import reverseParserCalibration from './reverseParserCalibration';
import rowTransformerCalibrationInputs from './rowTransformerCalibrationInputs';

const createCalibrationData = ({
  calibration,
  financials,
  company,
  companyExchangeRate,
  name,
  approaches,
  financialStatementsList,
  columnsPerformanceCalibration,
  rowsPerformanceCalibration,
  version,
  approach,
  comparisons,
  isDisabled,
}) => {
  let readOnlyData = true;

  if (version === '0') {
    readOnlyData = false;
  }

  const rowConfig = getRowConfigCalibrationInputs({
    calibration,
    isDisabled,
    financialStatementsList,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    readOnlyData,
  });

  const columns = rowTransformerCalibrationInputs(financials);

  return new SpreadsheetConfig({
    afterCellChanged: customCalibrationAfterCellsChanged,
    columns,
    rowConfig,
    parser,
    name,
    tableData: {
      calibration,
      approaches,
      isDisabled,
      financials,
      columnsPerformanceCalibration,
      rowsPerformanceCalibration,
      companyName: company.name,
      approach,
      gpcComparisonByDate: comparisons,
      financialStatementsList,
    },
    reverseParser: reverseParserCalibration,
    customValidations,
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    allowConfirmAndDeleteColumn: false,
    allowCopyColumn: false,
    allowReorderColumns: false,
    allowCopyRows: !isDisabled,
    allowAddSingleRow: !isDisabled,
    allowDeleteRow: !isDisabled,
  });
};

export default createCalibrationData;
