export const parseExpressions = scope => {
  Object.values(scope).forEach(sheet => {
    Object.values(sheet).forEach(cell => {
      cell.initializeCellValue(sheet, scope);
    });
  });
};

export const calcCells = scope => {
  const topCells = Object.values(scope).flatMap(sheet =>
    Object.values(sheet).filter(cell => cell.providers.size === 0)
  );
  topCells.forEach(cell => {
    cell.calcValue();
  });
};

const parser = data => {
  const references = Object.values(data).reduce((sofar, sheet) => {
    // eslint-disable-next-line no-param-reassign
    sofar[sheet.name] = sheet.cells;
    return sofar;
  }, {});
  parseExpressions(references);
  calcCells(references);

  Object.values(data).forEach(sheet => {
    sheet.applyConditions(references);
  });
  return references;
};

export default parser;
