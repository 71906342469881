import React from 'react';

const AIIcon = ({ fill }: { fill?: string }) => (
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.56848 6.41334V3.4947H0.936646V13.6289H5.56848V10.7102H3.25256V6.41334H5.56848Z"
      fill={fill ?? 'white'}
    />
    <path
      d="M3.85032 14.2759V17.1946H8.48216V7.06042H3.85032V9.97906H6.16624V14.2759H3.85032Z"
      fill={fill ?? 'white'}
    />
    <path
      d="M10.5105 2.62078C10.6031 2.68992 10.6731 2.78768 10.711 2.90061L11.11 4.09374C11.1373 4.17495 11.1813 4.24871 11.239 4.30965C11.2966 4.37059 11.3663 4.41718 11.4431 4.44603L12.5715 4.8679C12.6783 4.90795 12.7708 4.982 12.8362 5.07987C12.9015 5.17774 12.9366 5.29462 12.9366 5.41445C12.9366 5.53428 12.9015 5.65116 12.8362 5.74903C12.7708 5.84691 12.6783 5.92095 12.5715 5.961L11.4431 6.38287C11.3663 6.41173 11.2966 6.45832 11.239 6.51926C11.1813 6.5802 11.1373 6.65395 11.11 6.73516L10.711 7.92829C10.6731 8.04123 10.6031 8.13899 10.5105 8.20812C10.418 8.27726 10.3074 8.31438 10.1941 8.31438C10.0808 8.31438 9.97025 8.27726 9.87769 8.20812C9.78513 8.13899 9.7151 8.04123 9.67722 7.92829L9.27825 6.73516C9.25096 6.65395 9.2069 6.5802 9.14927 6.51926C9.09164 6.45832 9.02188 6.41173 8.94508 6.38287L7.81671 5.961C7.7099 5.92095 7.61745 5.84691 7.55206 5.74903C7.48668 5.65116 7.45157 5.53428 7.45157 5.41445C7.45157 5.29462 7.48668 5.17774 7.55206 5.07987C7.61745 4.982 7.7099 4.90795 7.81671 4.8679L8.94508 4.44603C9.02188 4.41718 9.09164 4.37059 9.14927 4.30965C9.2069 4.24871 9.25096 4.17495 9.27825 4.09374L9.67722 2.90061C9.7151 2.78768 9.78513 2.68992 9.87769 2.62078C9.97025 2.55164 10.0808 2.51452 10.1941 2.51452C10.3074 2.51452 10.418 2.55164 10.5105 2.62078Z"
      fill={fill ?? 'white'}
    />
    <path
      d="M7.70859 0.258335C7.76412 0.299817 7.80614 0.358472 7.82886 0.426234L8.06825 1.14211C8.08462 1.19084 8.11106 1.23509 8.14564 1.27165C8.18022 1.30822 8.22207 1.33617 8.26815 1.35348L8.94517 1.60661C9.00926 1.63064 9.06473 1.67507 9.10396 1.73379C9.14319 1.79251 9.16425 1.86264 9.16425 1.93454C9.16425 2.00643 9.14319 2.07656 9.10396 2.13529C9.06473 2.19401 9.00926 2.23844 8.94517 2.26247L8.26815 2.51559C8.22207 2.5329 8.18022 2.56086 8.14564 2.59742C8.11106 2.63398 8.08462 2.67824 8.06825 2.72696L7.82886 3.44284C7.80614 3.5106 7.76412 3.56926 7.70859 3.61074C7.65305 3.65222 7.58673 3.67449 7.51873 3.67449C7.45074 3.67449 7.38441 3.65222 7.32888 3.61074C7.27334 3.56926 7.23132 3.5106 7.2086 3.44284L6.96921 2.72696C6.95284 2.67824 6.92641 2.63398 6.89183 2.59742C6.85725 2.56086 6.8154 2.5329 6.76932 2.51559L6.09229 2.26247C6.02821 2.23844 5.97273 2.19401 5.9335 2.13529C5.89427 2.07656 5.87321 2.00643 5.87321 1.93454C5.87321 1.86264 5.89427 1.79251 5.9335 1.73379C5.97273 1.67507 6.02821 1.63064 6.09229 1.60661L6.76932 1.35348C6.8154 1.33617 6.85725 1.30822 6.89183 1.27165C6.92641 1.23509 6.95284 1.19084 6.96921 1.14211L7.2086 0.426234C7.23132 0.358472 7.27334 0.299817 7.32888 0.258335C7.38441 0.216852 7.45074 0.19458 7.51873 0.19458C7.58673 0.19458 7.65305 0.216852 7.70859 0.258335Z"
      fill={fill ?? 'white'}
    />
  </svg>
);

export default AIIcon;
