import { WATERFALL_SLUG } from 'common/constants/allocations';
import { BREAKPOINT_ANALYSIS_SLUG, CAP_TABLE_SLUG, FUND_OWNERSHIP_SLUG } from 'common/constants/cap-table';
import { DOCUMENT_GENERATION_JOBS_SLUG, DOCUMENTS_SLUG } from 'common/constants/documents';
import { FINANCIAL_SLUG } from 'common/constants/financials';
import { VALUATIONS_SLUG } from 'common/constants/valuations';
import { getSlugValue } from 'utillities';

export const COMPANIES_SUMMARY_TITLE = 'Summary';
export const COMPANIES_SUMMARY_SLUG = getSlugValue(COMPANIES_SUMMARY_TITLE);
export const COMPANIES_TITLE = 'Companies';
export const COMPANIES_NO_INVESTMENTS = 'There are no investments for this company';
export const COMPANIES_FORBIDDEN_COMPANY_SUMMARY = 'You do not have permissions to view the company summary';
export const COMPANIES_GET_COMPANY_SUMMARY_ACCESS = 'Please contact your manager to gain access';
export const COMPANIES_TOOLTIP_TEXT_COMPANY_MEASUREMENT_DATE
  = 'A company cannot be deleted if it has a measurement date';

export const COMPANIES_AVAILABLE_SLUG_PARAMS = Object.freeze([
  BREAKPOINT_ANALYSIS_SLUG,
  CAP_TABLE_SLUG,
  COMPANIES_SUMMARY_SLUG,
  DOCUMENTS_SLUG,
  DOCUMENT_GENERATION_JOBS_SLUG,
  FINANCIAL_SLUG,
  FUND_OWNERSHIP_SLUG,
  VALUATIONS_SLUG,
  WATERFALL_SLUG,
]);
