export const MULTIPLE_PREMIUM_DISCOUNT_TITLE = 'Multiple Premium / (Discount)';
export const AS_OF_DATE_TITLE = 'As of Date';
export const AS_OF_DATE_ALIAS = 'as_of_date';
export const ADJUSTMENT_METRIC_TITLE = 'Adjustment Metric';
export const ADJUSTMENT_METRIC_ALIAS = 'adjustment_metric';
export const GPC_MEDIAN_TITLE = 'Public Comps Median';
export const GPC_MEDIAN_ALIAS = 'gpc_median';
export const COMPANY_PERFORMANCE_METRIC_TITLE = 'Company Performance Metric';
export const COMPANY_PERFORMANCE_METRIC_ALIAS = 'company_performance_metric';
export const COMPUTED_PREMIUM_DISCOUNT_TITLE = 'Computed Premium / (Discount)';
export const COMPUTED_PREMIUM_DISCOUNT_ALIAS = 'computed_premium_discount';
export const OTHER_PREMIUM_DISCOUNT_TITLE = 'Other Premium / (Discount)';
export const OTHER_PREMIUM_DISCOUNT_ALIAS = 'other_premium_discount';
export const TOTAL_PREMIUM_DISCOUNT_TITLE = 'Total Premium / (Discount)';
export const TOTAL_PREMIUM_DISCOUNT_ALIAS = 'total_premium_discount';
export const AS_OF_DATE_EQUAL_TO_MD = (date: string): string => `The "As of date" should be less than ${date}.`;
export const AS_OF_DATE_COLUMN_LEGEND = 'B';
