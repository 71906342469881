import { Chip, styled } from '@material-ui/core';
import theme from 'theme';

const StatusChip = styled(Chip)({
  color: theme.palette.common?.black,
  border: `1px solid ${theme.palette.common?.black}`,
  backgroundColor: 'transparent',
  textTransform: 'none',
});
export { StatusChip };
