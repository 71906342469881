/* eslint-disable no-param-reassign */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormat } from 'common/hooks';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import CalibrationContext from 'context/CalibrationContext';
import { createColumnsCalibrationPerformance, rowConfigCalibrationPerformance } from './performance_metrics/config';
import { PerformanceCalculator, updateCompsWithMetrics as updateCompsWithMetricsUtil } from './utils';
import { performanceKeys } from '../../../../common/constants/calibration';
import { ISO_DATE_FORMAT } from '../../../../utillities';
import { isDateInPeriods, isLastQuarter } from '../../../../utillities/dateUtils';
import ValuationContext from '../../../ValuationsAllocation/ValuationContext';
import { useCustomClasses as usePerformanceMetricsCustomClasses } from '../guidelinePublicCompanies/PerformanceMetrics/config';
import { getCompanyPerfMetricsAsOfDate } from '../guidelinePublicCompanies/utils/getCompanyPerfMetricsCalculations';
import useUpdateCustomDatePerformanceMetrics from '../guidelinePublicCompanies/utils/useUpdateCustomDatePerformanceMetrics';
import {
  getAllCustomDatePerformanceMetricsCalibration,
  getFiscalYearEnd,
} from '../guidelinePublicCompanies/utils/utilities';

const Calibration = ({
  spreadsheets,
  workbook,
  onChange,
  approaches,
  financials,
  company,
  financialStatementsList,
  calibration,
}) => {
  const calibrationInputSheet = spreadsheets.calibration_inputs;
  const calibrationOutputSheet = spreadsheets.calibration_outputs;
  const calibrationPerformanceSheet = spreadsheets.calibration_performance;
  const { customClasses: performanceMetricsCustomClasses } = usePerformanceMetricsCustomClasses();
  const [format, formatDispatch] = useFormat();
  const [compsWithCustomDatePerformanceMetrics, setCompsWithCustomDatePerformanceMetrics] = useState([]);
  const { tableData: initialTableData } = calibrationInputSheet;
  const { companyName, gpcComparisonByDate } = initialTableData;
  const [newDate, setNewDate] = useState();
  const [isApproachSelected, setIsApproachSelected] = useState(
    (calibration.performances ?? []).length > 0 || (gpcComparisonByDate ?? []).length > 0
  );
  const { resetConfiguration, companyMeasurementDate, financialStatementPeriodsPerMD, gpcAttributes }
    = useContext(ValuationContext);

  const updateCompsWithMetrics = useCallback(
    (compsWithMetrics, correspondingCompsWithMetrics) =>
      updateCompsWithMetricsUtil(compsWithMetrics, correspondingCompsWithMetrics),
    []
  );

  const { getCompsWithCustomDatePerformanceMetricsCalibration, validateCustomDatePerformanceMetrics }
    = useUpdateCustomDatePerformanceMetrics({
      gpcComparison: calibration?.approach?.valuations_approach_gpc ?? {},
      format,
      gpcAttributes,
    });

  const updateApproach = useCallback(
    (approachGpc, calibration) => {
      const asOfDate = newDate;
      const customDatePerfMetrics = getAllCustomDatePerformanceMetricsCalibration(
        approachGpc.gpc_comparison,
        calibration.calibration_date
      );

      const performanceMetricsAsOfDateColumns = createColumnsCalibrationPerformance({
        gpcComparisonByDate: !isEmpty(customDatePerfMetrics) ? customDatePerfMetrics : approachGpc.gpc_comparison,
        financials,
      });

      const performanceMetricsAsOfDateCommonRowConfig = {
        asOfDate,
        company,
        customClasses: performanceMetricsCustomClasses,
        gpcComparisonByDate: !isEmpty(customDatePerfMetrics) ? customDatePerfMetrics : approachGpc.gpc_comparison,
      };

      const rowConfig = rowConfigCalibrationPerformance({
        ...performanceMetricsAsOfDateCommonRowConfig,
      });

      const performanceMetricsKeys = performanceMetricsAsOfDateColumns.filter(item =>
        Object.keys(item).some(key => key.includes('performance'))
      );

      const numRows = performanceMetricsKeys.length;
      const numCols = Object.keys(performanceMetricsKeys[0]).length;
      calibration.performances = [];
      const idCalibration = calibration.id;

      calibration.performances = PerformanceCalculator({
        numCols,
        numRows,
        performanceMetricsKeys,
        performanceKeys,
        idCalibration,
        calibration,
      });

      calibrationPerformanceSheet.reset({
        rowConfig,
        columns: performanceMetricsAsOfDateColumns.splice(1),
      });

      resetConfiguration();
    },
    [calibrationPerformanceSheet, company, financials, newDate, performanceMetricsCustomClasses, resetConfiguration]
  );

  const updateCustomDatePerformanceMetrics = useCallback(async () => {
    const updatedCompsWithMetrics = updateCompsWithMetrics(
      compsWithCustomDatePerformanceMetrics,
      calibration?.approach?.valuations_approach_gpc?.gpc_comparison ?? {}
    );

    const approachGpc
      = calibration?.approach?.valuations_approach_gpc ?? calibration?.approach?.valuations_approach_gpc ?? {};
    const asOfDate = newDate;
    const companyAsOfDatePerformanceMetricsId = approachGpc.company_as_of_date_performance_metrics?.[0]?.id;
    const fiscalYearEnd = getFiscalYearEnd(asOfDate, companyMeasurementDate);
    if (
      isDateInPeriods(asOfDate, financialStatementPeriodsPerMD, 'statement_date', ISO_DATE_FORMAT)
      || isLastQuarter(asOfDate, fiscalYearEnd)
    ) {
      const { tmpCompanyAsOfDatePerformanceMetrics, rowMustBeReadOnly } = getCompanyPerfMetricsAsOfDate({
        asOfDate,
        financialsPeriods: financialStatementPeriodsPerMD,
        companyName,
        fiscalYearEnd,
      });

      calibration.approach.valuations_approach_gpc = {
        ...calibration.approach.valuations_approach_gpc,
        gpc_comparison: updatedCompsWithMetrics,
        company_as_of_date_performance_metrics: companyAsOfDatePerformanceMetricsId
          ? [{ ...tmpCompanyAsOfDatePerformanceMetrics, id: companyAsOfDatePerformanceMetricsId }]
          : [],
        rowMustBeReadOnly,
      };
    } else {
      calibration.approach.valuations_approach_gpc = {
        ...calibration.approach.valuations_approach_gpc,
        gpc_comparison: updatedCompsWithMetrics,
        rowMustBeReadOnly: false,
        company_as_of_date_performance_metrics: companyAsOfDatePerformanceMetricsId
          ? [{ id: companyAsOfDatePerformanceMetricsId }]
          : [],
      };
    }

    updateApproach(calibration.approach.valuations_approach_gpc, calibration);
  }, [
    updateCompsWithMetrics,
    compsWithCustomDatePerformanceMetrics,
    calibration,
    newDate,
    companyMeasurementDate,
    financialStatementPeriodsPerMD,
    updateApproach,
    companyName,
  ]);

  const handleAsOfDateAlias = useCallback(
    async value => {
      if (calibration?.approach?.valuations_approach_gpc?.gpc_comparison.length > 0) {
        const tmpCompsWithCustomDatePerformanceMetrics = await getCompsWithCustomDatePerformanceMetricsCalibration(
          value,
          calibration?.approach?.valuations_approach_gpc?.gpc_comparison
        );
        const compsWithValidatedCustomDatePerformanceMetrics = validateCustomDatePerformanceMetrics(
          tmpCompsWithCustomDatePerformanceMetrics
        );
        setCompsWithCustomDatePerformanceMetrics(compsWithValidatedCustomDatePerformanceMetrics);
      }
    },
    [calibration, getCompsWithCustomDatePerformanceMetricsCalibration, validateCustomDatePerformanceMetrics]
  );

  const handleOnChange = useCallback(
    async (cell, value) => {
      onChange(cell, value);
      if (cell.alias === 'calibration_date') {
        const currentDate = value;
        setNewDate(currentDate);
        await handleAsOfDateAlias(currentDate);
      } else if (cell.alias === 'public_comps_approach_calibration') {
        setIsApproachSelected(true);
      }
    },
    [handleAsOfDateAlias, onChange]
  );

  useEffect(() => {
    if (!isEmpty(compsWithCustomDatePerformanceMetrics)) {
      updateCustomDatePerformanceMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compsWithCustomDatePerformanceMetrics]);

  const GroupContextValue = useMemo(
    () => ({
      approaches,
      calibrationPerformanceSheet,
      calibrationOutputSheet,
      calibrationInputSheet,
      financials,
      company,
      performanceMetricsCustomClasses,
      financialStatementsList,
      calibration,
    }),
    [
      approaches,
      calibrationPerformanceSheet,
      calibrationOutputSheet,
      calibrationInputSheet,
      financials,
      company,
      performanceMetricsCustomClasses,
      financialStatementsList,
      calibration,
    ]
  );

  let display;
  if (isApproachSelected) {
    display = (
      <Box width="100%" display="flex" flexDirection="column">
        <ScalarSpreadsheet
          {...calibrationPerformanceSheet}
          sheet={calibrationPerformanceSheet}
          workbook={workbook}
          onChange={onChange}
          format={format}
          formatDispatch={formatDispatch}
        />
      </Box>
    );
  } else {
    display = '';
  }

  return (
    <>
      <Box width="100%" display="flex" flexDirection="row">
        <CalibrationContext.Provider value={GroupContextValue}>
          <ScalarSpreadsheet
            {...calibrationInputSheet}
            sheet={calibrationInputSheet}
            workbook={workbook}
            onChange={handleOnChange}
            format={format}
            formatDispatch={formatDispatch}
          />

          <ScalarSpreadsheet
            {...calibrationOutputSheet}
            sheet={calibrationOutputSheet}
            workbook={workbook}
            onChange={handleOnChange}
            format={format}
            formatDispatch={formatDispatch}
          />
        </CalibrationContext.Provider>

        <br />
      </Box>

      {display}
    </>
  );
};

Calibration.propTypes = {
  spreadsheets: PropTypes.shape({
    calibration_inputs: PropTypes.object.isRequired,
    calibration_outputs: PropTypes.object.isRequired,
    calibration_performance: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  workbook: PropTypes.object.isRequired,
  approaches: PropTypes.object.isRequired,
  financials: PropTypes.object.isRequired,
  company: PropTypes.object,
  financialStatementsList: PropTypes.object,
  calibration: PropTypes.shape({
    performances: PropTypes.array,
    approach: PropTypes.shape({
      valuations_approach_gpc: PropTypes.object,
    }),
    calibration_date: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default Calibration;
