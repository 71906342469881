import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { HEADER_CONSTANTS } from '../../constants';
import AIIcon from '../assets/AIIcon';
import DocumentGenerationJobCreateModal from '../DocumentGenerationJobCreateModal/DocumentGenerationJobCreateModal';

const DocumentGenerationJobCreateButton = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const toggleModal = useCallback(() => setModalOpen(prev => !prev), []);

  return (
    <>
      {modalOpen && <DocumentGenerationJobCreateModal onClose={toggleModal} open={modalOpen} />}

      <Button variant="contained" color="primary" onClick={toggleModal}>
        <span style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          <AIIcon />
          {HEADER_CONSTANTS.ACTION}
        </span>
      </Button>
    </>
  );
};

export default DocumentGenerationJobCreateButton;
