import { RetrieveDocumentGenerationJob } from '../../api';

const DEFAULT_LOADING_COMPANY = 'Company info Loading...';

const HEADER_CONSTANTS = {
  TITLE: 'Document AI Jobs',
  ACTION: 'Start new document AI job',
};

type TableConstantsType = { [key: string]: { key: string; label: string }[] };
const EMPTY_STATE_HEADER = 'No Document AI Jobs found for this company.';
const EMPTY_STATE_SUBHEADER = 'Start by creating a new Document AI Job.';
const ERROR_STATE_HEADER = 'Error loading Document AI Jobs. Please refresh and try again.';

const TABLE_CONSTANTS: TableConstantsType = {
  HEADERS: [
    { label: 'Status', key: 'status' },
    { label: 'Type', key: 'type' },
    { label: 'Initiated By', key: 'initiatedBy' },
    { label: 'Created At', key: 'created' },
    { label: 'Reference Documents', key: 'referenceDocuments' },
    { label: 'Data Result', key: 'dataResult' },
    { label: 'Actions', key: 'actions' },
  ],
};

const QUERY_CONSTANTS = {
  getDocumentGenerationJobs: 'getDocumentGenerationJobs',
  getDocumentGenerationJobById: 'getDocumentGenerationJobById',
};

const CREATE_MODAL_CONSTANTS = {
  TITLE: 'Start New AI Document Data Extraction',
  DOCUMENT_ACTION: 'Select existing documents',
  DOCUMENT_SELECT_PLACEHOLDER: 'Select a document',
  TYPE_ACTION: 'Data Extraction Type',
  ACTION_BUTTON: 'Start Job',
  ERROR: 'Error creating Document AI Job',
  OPTIONS: [{ label: 'Cap Table', value: 'CAP_TABLE' }],
};

const TABLE_ROW_CONSTANTS = {
  COPY_ID: 'Copy Job ID',
  COPY_SUCCESS: 'Job ID Copied to clipboard',
  ERROR_COPYING: 'Error copying Job ID to clipboard',
};

const PROCESSING_TYPE_RESULT_MAPPING = {
  [RetrieveDocumentGenerationJob.processing_type.CAP_TABLE]: 'securities',
  [RetrieveDocumentGenerationJob.processing_type.FINANCIALS]: 'financial statement periods',
};

const PROCESSING_TYPE_MAPPING = {
  [RetrieveDocumentGenerationJob.processing_type.CAP_TABLE]: 'Cap Table',
  [RetrieveDocumentGenerationJob.processing_type.FINANCIALS]: 'Financials',
};

const RESULTS_DISPLAY = {
  RESULTS_COUNT: (resultsCount: number, type: RetrieveDocumentGenerationJob.processing_type) =>
    `Extracted ${PROCESSING_TYPE_MAPPING[type]} with ${resultsCount} ${PROCESSING_TYPE_RESULT_MAPPING[type]}`,
  BUTTON_CONTENT: 'Review Data',
  // 5 minutes is arbitrarily chosen based on testing results
  FAILURE_CONTENT:
    'A failure occurred during processing. Copy the Job ID (found in the \'Actions\' menu) and contact support.',
  PENDING_RESULTS_CONTENT: (documentCount: number) =>
    `Currently processing ${documentCount} document${
      documentCount !== 1 ? 's' : ''
    }, 5 minutes estimated until completion`,
  TITLE: 'Job Results',
  ERROR_PARSING_RESULTS: 'Error parsing results',
};

export {
  CREATE_MODAL_CONSTANTS,
  DEFAULT_LOADING_COMPANY,
  EMPTY_STATE_HEADER,
  EMPTY_STATE_SUBHEADER,
  ERROR_STATE_HEADER,
  HEADER_CONSTANTS,
  PROCESSING_TYPE_MAPPING,
  QUERY_CONSTANTS,
  RESULTS_DISPLAY,
  TABLE_CONSTANTS,
  TABLE_ROW_CONSTANTS,
};
