import React from 'react';
import { PropTypes } from 'prop-types';
import * as CALIBRATION from 'common/constants/calibration';
import { largeCurrencyFormat } from 'common/formats/formats';
import ApproachesList from './ApproachesList';
import CalibrationGridDate from './CalibrationGridDate';
import FinancialVersionsList from './FinancialVersionsList';
import { SelectValueViewer } from '../../../../../components';
import { GridDateEditor, GridDateValueViewer } from '../../../../../components/FeaturedSpreadsheet/components';
import {
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
} from '../../../../ValuationsAllocation/common/constants/valuationSummary/sheetAliases';

const getRowConfigCalibrationInputs = rowConfigParams => {
  const {
    financialStatementsList,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    readOnlyData,
    isDisabled,
  } = rowConfigParams;
  return [
    {
      className: 'table-header full-width-label',
      readOnly: true,
      value: 'Inputs table',
      columnSpan: 2,
      gridType: 'string',
      rowSpan: 1,
      ignoreRowCopy: true,
      isTitleOrHeader: true,
    },
    {
      alias: 'calibration_date',
      readOnly: isDisabled,
      value: 'Calibration Date',
      dbType: 'dbDate',
      gridType: 'gridDate',
      className: 'row-label-indented',
      isRequired: true,
      gridDateComponent: CalibrationGridDate,
      dataEditor: props => <GridDateEditor {...props} />,
      valueViewer: props => <GridDateValueViewer {...props} />,
    },
    {
      alias: 'transaction_allocation_method',
      readOnly: isDisabled,
      value: 'Transaction Allocation Methodology',
      gridType: 'string',
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
    },
    {
      alias: 'enterprise_value_inputs',
      readOnly: isDisabled,
      value: 'Enterprise Value',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
    },
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT,
      readOnly: readOnlyData,
      value: 'Plus Cash',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
    },
    {
      alias: VALUATION_SUMMARY_SPREADSHEET_LESS_CASH,
      readOnly: readOnlyData,
      value: '(Less Debt)',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
    },
    {
      alias: 'equity_value_inputs',
      readOnly: isDisabled,
      value: 'Equity Value',
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
    },
    {
      alias: 'empty',
      readOnly: true,
      value: '',
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented',
      style: { visibility: 'hidden', border: 'none' },
    },
    {
      value: 'Public Comps Approach',
      alias: CALIBRATION.PUBLIC_COMPS_APPROACH,
      readOnly: isDisabled,
      className: 'row-label-indented',
      isRequired: true,
      dataEditor: props => (
        <ApproachesList
          {...props}
          columnsPerformanceCalibration={columnsPerformanceCalibration}
          rowsPerformanceCalibration={rowsPerformanceCalibration}
        />
      ),
      valueViewer: props => <SelectValueViewer {...props} />,
    },
    {
      alias: CALIBRATION.FINANCIALS_VERSION_CALIBRATION,
      value: 'Financials Version',
      readOnly: isDisabled,
      className: 'row-label-indented',
      dataEditor: props => <FinancialVersionsList {...props} options={financialStatementsList} />,
      valueViewer: props => <SelectValueViewer {...props} options={financialStatementsList} />,
    },
    {
      alias: 'ltm_revenue_inputs',
      readOnly: readOnlyData,
      value: 'LTM Revenue',
      gridType: 'number',
      ...CALIBRATION.commonInputFields,
    },
    {
      alias: 'ntm_revenue_inputs',
      readOnly: readOnlyData,
      value: 'NTM Revenue',
      ...CALIBRATION.commonInputFields,
    },
    {
      alias: 'ltm_ebitda_inputs',
      readOnly: readOnlyData,
      value: 'LTM EBITDA',
      ...CALIBRATION.commonInputFields,
    },
    {
      alias: 'ntm_ebitda_inputs',
      readOnly: readOnlyData,
      value: 'NTM EBITDA',
      ...CALIBRATION.commonInputFields,
    },
  ];
};

getRowConfigCalibrationInputs.propTypes = {
  calibration: PropTypes.number,
};

export default getRowConfigCalibrationInputs;
