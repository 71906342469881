import React from 'react';
import { DocumentInAccordionSummaryProps } from './types';

const DocumentInAccordionSummary = (props: DocumentInAccordionSummaryProps) => {
  const { document, onClickHandler, areActionsDisabled, referencedDocuments } = props;
  const style = {
    marginRight: '0.2rem',
    textDecoration: areActionsDisabled ? undefined : 'underline',
    cursor: areActionsDisabled ? undefined : 'pointer',
    border: 'none',
    background: 'none',
    padding: 0,
  };

  return (
    <button
      onClick={areActionsDisabled ? undefined : () => onClickHandler(document)}
      tabIndex={0}
      style={style}
      type="button">
      {document.file.filename}.{document.file.file_type}
      {/* Don't show the ',' if the document is the last one */}
      {document.file.filename === referencedDocuments[referencedDocuments.length - 1].file.filename ? '' : ', '}
    </button>
  );
};

export default DocumentInAccordionSummary;
