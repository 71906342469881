import { ApproachType } from 'common/types/valuation';
import { BACKSOLVE_APPROACH } from 'pages/Valuations/util/constants';
import getInitials from 'utillities/getInitials';

const getApproachInitials = ({ approach_type }: { approach_type: ApproachType }): string => {
  if (approach_type === BACKSOLVE_APPROACH) return 'BV';
  return getInitials({ names: approach_type, maxInitials: 3 });
};

export default getApproachInitials;
