import { getObjectValue } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { HIDDEN_ROW_NUMBER } from '../../../../../common/constants/calibration';

const parser = async ({ columns, rowConfig }) => {
  const cells = {};

  const alphabet = alphabetGenerator([], columns.length);

  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    rowConfig.forEach((row, rowIdx) => {
      const rowNumber = rowIdx + 1;
      const key = columnLegend + rowNumber;
      const { alias, ignoreAutoScroll, isNotNavigable } = getObjectValue(row);
      let value = column[alias]?.value ?? 0;
      let { style } = column;
      const { format, dbType, expr, hidden, gridType } = row;
      if (rowNumber === HIDDEN_ROW_NUMBER) {
        style = { visibility: 'hidden', border: 'none' };
      }

      const customKey = row.alias;
      if (row.alias === 'outputs_header') {
        value = '';
      }

      const { id, order, customFormat, isParent, parentColumn } = getObjectValue(column);

      const cell = {
        key,
        alias: alias || '',
        columnId: id,
        columnLegend,
        columnOrder: order,
        customFormat: customFormat || null,
        ignoreAutoScroll,
        isNotNavigable,
        isParent,
        parentColumn,
        rowNumber,
        value,
        isGpcRow: false,
        readOnly: true,
        style,
        format,
        gridType,
        expr,
        dbType,
        hidden,
        customKey,
      };

      cells[key] = cell;
    });
  });

  return cells;
};

export default parser;
