import { isEmpty, isNil, isNull } from 'lodash';
import moment from 'moment';
import { EQUAL_OR_GREATER_THAN, GREATER_THAN, IS_REQUIRED_ERROR } from 'common/constants/messages/validations';
import { doesNotExistValue, toString } from 'utillities';
import * as c from './constants';

const customValidations = ({ cell, parsedColumns, addFeedback, addFeedbackFromCell, removeFeedbackFromCell }) => {
  const { alias, value, defaultValue, isRequired, columnLegend } = cell;

  const checkDefaultValidations = () => {
    if (isRequired && (isNull(value) || isEmpty(toString(value).trim()))) {
      addFeedback(IS_REQUIRED_ERROR);
    } else if (value === defaultValue) {
      addFeedback(c.REPLACE_DEFAULT_VALUE);
    }
  };

  switch (alias) {
    case c.NOTE_NAME_ALIAS: // 1
      if (isNull(value) || isEmpty(toString(value).trim())) {
        addFeedback(c.NOTE_NAME_REQUIRED);
      } else if (value === defaultValue) {
        addFeedback(c.REPLACE_DEFAULT_VALUE);
      } else if (parsedColumns) {
        const convertibleNoteNameValidationObject = {};
        Object.entries(parsedColumns).forEach(([key, auxCell]) => {
          if (auxCell.alias === alias) {
            if (!convertibleNoteNameValidationObject[auxCell.value]) {
              convertibleNoteNameValidationObject[auxCell.value] = [];
            }
            if (cell.key === key) {
              convertibleNoteNameValidationObject[auxCell.value].push(cell);
            } else {
              convertibleNoteNameValidationObject[auxCell.value].push(auxCell);
            }
          }
        });

        Object.entries(convertibleNoteNameValidationObject).forEach(([, itemValue]) => {
          if (itemValue.length > 1) {
            itemValue.forEach(auxCell => addFeedbackFromCell(auxCell, c.NOTE_NAME_REPEATED));
          } else {
            removeFeedbackFromCell(itemValue[0], c.NOTE_NAME_REPEATED);
          }
        });
      }
      break;
    case c.MATURITY_DATE_ALIAS: // 3
      if (isRequired && (isNull(value) || isEmpty(toString(value).trim()))) {
        addFeedback(IS_REQUIRED_ERROR);
      }
      if (parsedColumns) {
        const issuanceDateCell = parsedColumns[columnLegend + c.ISSUANCE_DATE_ROW];
        const issuanceDate = moment(new Date(issuanceDateCell.value));
        const maturityDate = moment(new Date(value));
        const isMaturitySameOrAfterThanIssuance = maturityDate.isSameOrAfter(issuanceDate);
        if (!isMaturitySameOrAfterThanIssuance) {
          addFeedback(c.MATURITY_DATE_SHOULD_BE_SAME_OR_AFTER_ISSUANCE_DATE);
        }
      }
      break;
    case c.NOTE_PRINCIPLE_AMOUNT_ALIAS: // 4
      if (isNull(value) || value <= 0) {
        addFeedback(GREATER_THAN(0));
      }
      break;
    case c.INTEREST_RATE_ALIAS: // 5
    case c.CONVERSION_DISCOUNT_ALIAS: // 8
      if (value < 0) {
        addFeedback(EQUAL_OR_GREATER_THAN(0));
      }
      break;
    case c.COMPOUNDING_PERIODS_PER_YEAR_ALIAS: // 7
      checkDefaultValidations();
      if (parsedColumns) {
        const compoundingCell = parsedColumns[columnLegend + c.COMPOUNDING_ROW];
        if (compoundingCell?.value === '1' && Number(value) < 0) {
          addFeedback(GREATER_THAN(0));
        }
      }
      break;
    case c.CONVERSION_CAP_ALIAS: // 9
    case c.CONVERSION_PRE_MONEY_ALIAS: // 20
      if (!doesNotExistValue(value) && value <= 0 && !cell.isCellCalculated) {
        addFeedback(GREATER_THAN(0));
      }
      break;
    case c.PRE_MONEY_SHARE_COUNT_ALIAS: // 21
      if (!isNil(value) && value <= 0) {
        addFeedback(GREATER_THAN(0));
      }
      break;
    case c.ISSUANCE_DATE_ALIAS: // 2
    case c.COMPOUNDING_ALIAS: // 6
      checkDefaultValidations();
      break;
    case c.EQUITY_CONVERSION_MODEL_ALIAS: // 14
      if (isRequired && Number(value) === 0) {
        addFeedback(c.EQUITY_CONVERSION_MODEL_INVALID);
      }
      break;
    case c.CONVERSION_DATE_ALIAS: // 15
      if (parsedColumns) {
        const maturityDateCell = parsedColumns[columnLegend + c.MATURITY_DATE_ROW];
        const maturityDate = moment(new Date(maturityDateCell.value));
        const conversionDate = moment(new Date(value));
        const isConversionDateAfterMaturityDate = conversionDate.isAfter(maturityDate);
        if (isConversionDateAfterMaturityDate) {
          addFeedback(c.CONVERSION_DATE_SHOULD_OCCUR_BEFORE_MATURITY_DATE, 'warning');
        } else {
          removeFeedbackFromCell(cell, c.CONVERSION_DATE_SHOULD_OCCUR_BEFORE_MATURITY_DATE, 'warning');
        }
      }
      break;
    case c.EXPECTED_SHARES_ALIAS: // 23
      if (isRequired && Math.round(Number(value)) <= 0) {
        addFeedback(c.EXPECTED_SHARES_CANT_BE_ZERO);
      }
      break;
    default:
      break;
  }
};

export default customValidations;
