export const YES_NO = ['No', 'Yes'];
export const DIVIDEND_PAYMENT_TYPE = ['Cash', 'PIK'];
export const ENTER_DATA = 'ENTER DATA';
export const SELECT_DATE = 'SELECT DATE';
export const SELECT_OPTION = 'SELECT OPTION';
export const SELECT_APPROACH = ' SELECT APPROACH';

export const SELECT_SECURITY = 'SELECT SECURITY';
export const GET_ENTER_DATA = (isRequired, enterDataMessage = null) => {
  const optionalMessage = enterDataMessage || '';
  return isRequired ? ENTER_DATA : optionalMessage;
};

export const YES = 1;
export const NO = 0;
