import { ValuationsApproach } from 'common/types/valuation';
import {
  ValuationApproachCompsMapKeys,
  ValuationApproachMapKeys,
} from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity/config/types';
import { LTM_REVENUE, SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit';
import { FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE_CUSTOM_KEY } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { relatedApproachAliasMap } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getArrayValue, getNumberValue, getObjectValue, getStringValue, shortDate, toString } from 'utillities';
import { CustomReverseParserParams, HandlersParams, RelatedApproachAliasMapKeys } from './types';

const handleExitDate = (params: HandlersParams) => {
  const { approach, cell } = params;
  approach.exit_date = getStringValue(shortDate(cell.value));
};

const handleMetricValue = (params: HandlersParams) => {
  const { approach, cell, fieldAttributes } = params;
  if (cell.customKey === FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE_CUSTOM_KEY) {
    approach.financials_metric = cell.value ? toString(cell.value) : LTM_REVENUE;
  } else {
    const fieldDecimalPlaces = getNumberValue(fieldAttributes?.referenced_approach_multiple_value?.decimal_places);
    approach.financials_metric_value = parseCellValue({
      cell,
      fieldDecimalPlaces,
    });
  }
};

const handleValuationApproach = (params: HandlersParams) => {
  const { approach, cell, approaches } = getObjectValue(params);
  const approachesData = getArrayValue(approaches);
  const selectedApproachValue = getStringValue(cell.value as string);
  const selectedApproach = approachesData.find((a: ValuationsApproach) =>
    [a.id?.toString(), a.panelId].includes(selectedApproachValue.toString())
  );
  if (!selectedApproach) return;
  const selectedApproachType = selectedApproach?.approach_type as keyof RelatedApproachAliasMapKeys;
  const relatedApproachAlias = relatedApproachAliasMap[selectedApproachType];
  const specificApproach = selectedApproach?.[relatedApproachAlias.approachProperty as keyof ValuationApproachMapKeys];
  const approachId = specificApproach?.id;

  if (!approachId) {
    approach[relatedApproachAlias.referenceName as keyof ValuationApproachCompsMapKeys] = cell?.value?.toString();
  }
  approach[relatedApproachAlias.referenceOpposite as keyof ValuationApproachCompsMapKeys] = null;
  approach[relatedApproachAlias.name as keyof ValuationApproachCompsMapKeys] = approachId?.toString();
  approach[relatedApproachAlias.opposite as keyof ValuationApproachCompsMapKeys] = null;
};

const handleMultipleValue = (params: HandlersParams) => {
  const { approach, cell, fieldAttributes } = params;
  if (cell.isTitleCell) {
    const multipleValueCell = cell.value;
    if (multipleValueCell) {
      approach.referenced_approach_multiple = getStringValue(multipleValueCell as string);
    }
  } else {
    const fieldDecimalPlaces = getNumberValue(fieldAttributes?.referenced_approach_multiple_value?.decimal_places);

    approach.referenced_approach_multiple_value = parseCellValue({
      cell,
      fieldDecimalPlaces,
    });
  }
};

const handleCash = (params: HandlersParams) => {
  const { approach, cell, fieldAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(fieldAttributes?.plus_cash?.decimal_places);
  approach.plus_cash = parseCellValue({
    cell,
    fieldDecimalPlaces,
  });
};

const handleDebt = (params: HandlersParams) => {
  const { approach, cell, fieldAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(fieldAttributes?.less_debt?.decimal_places);
  approach.less_debt = parseCellValue({
    cell,
    fieldDecimalPlaces,
  });
};

const handleDilutionFutureInvestments = (params: HandlersParams) => {
  const { approach, cell, fieldAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(fieldAttributes?.dilution_percentage?.decimal_places);
  approach.dilution_percentage = parseCellValue({
    cell,
    fieldDecimalPlaces,
  });
};

const customReverseParser = (params: CustomReverseParserParams) => {
  const { cells, approach, approaches, fieldAttributes } = getObjectValue(params);
  const { valuations_approach_future_exit: valuationFEApproach } = getObjectValue(approach);
  const updatedFEApproach = getObjectValue(valuationFEApproach);

  const {
    FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE,
    FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE,
    FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
    FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE,
    FE_FUTURE_EQUITY_SPREADSHEET_CASH,
    FE_FUTURE_EQUITY_SPREADSHEET_DEBT,
    FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS,
  } = SHEET_ALIASES_CONSTANTS;

  Object.values(getObjectValue(cells)).forEach(cell => {
    const { alias } = getObjectValue(cell);

    switch (alias) {
      // Handle Exit Date
      case FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE: {
        handleExitDate({ approach: updatedFEApproach, cell });
        break;
      }
      // Handle Metric Value
      case FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE: {
        handleMetricValue({ approach: updatedFEApproach, approaches, cell, fieldAttributes });
        break;
      }
      // Handle Valuation Approach
      case FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH: {
        handleValuationApproach({ approach: updatedFEApproach, approaches, cell });
        break;
      }
      // // Handle Multiple Value
      case FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE: {
        handleMultipleValue({ approach: updatedFEApproach, approaches, cell, fieldAttributes });
        break;
      }
      // Handle Cash
      case FE_FUTURE_EQUITY_SPREADSHEET_CASH: {
        handleCash({ approach: updatedFEApproach, cell, fieldAttributes });
        break;
      }
      // Handle Debt
      case FE_FUTURE_EQUITY_SPREADSHEET_DEBT: {
        handleDebt({ approach: updatedFEApproach, cell, fieldAttributes });
        break;
      }
      // Handle Dilution % from Future Investments
      case FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS: {
        handleDilutionFutureInvestments({ approach: updatedFEApproach, cell, fieldAttributes });
        break;
      }

      default:
        break;
    }
  });
};

export default customReverseParser;
