import React, { useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { ApiService, RetrieveDocumentGenerationJob } from 'api';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import DocumentGenerationJobsTableItem from './DocumentGenerationJobTableItem/DocumentGenerationJobTableItem';
import {
  EMPTY_STATE_HEADER,
  EMPTY_STATE_SUBHEADER,
  ERROR_STATE_HEADER,
  QUERY_CONSTANTS,
  TABLE_CONSTANTS,
} from '../../constants';
import DocumentGenerationJobCreateButton from '../DocumentGenerationJobCreateButton/DocumentGenerationJobCreateButton';

const DocumentGenerationJobsTable = () => {
  const tableHeaders = useMemo(() => TABLE_CONSTANTS.HEADERS, []);
  const { apiCompanyDocumentGenerationJobsList } = ApiService;
  const [{ companyInfo }] = useStore() as any;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: [QUERY_CONSTANTS.getDocumentGenerationJobs, companyInfo.id, page, rowsPerPage],
    queryFn: async (): Promise<RetrieveDocumentGenerationJob[]> => {
      const response = await apiCompanyDocumentGenerationJobsList(companyInfo.id, page, rowsPerPage);
      // overriding type as the api service returns back a different type than what is actually returned
      return response as unknown as RetrieveDocumentGenerationJob[];
    },
    initialData: [],
  });

  if (!companyInfo?.id) return <></>;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  if (error) {
    return <Typography>{ERROR_STATE_HEADER}</Typography>;
  }

  if (!isLoading && !data?.length) {
    return (
      <MessageBox
        title={EMPTY_STATE_HEADER}
        tagline={EMPTY_STATE_SUBHEADER}
        fullWidth={false}
        action={<DocumentGenerationJobCreateButton />}
      />
    );
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(header => (
              <TableCell key={header.key}>
                <Typography variant="body1">{header.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(generationJob => (
            <DocumentGenerationJobsTableItem key={generationJob.job_id} generationJob={generationJob} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        // -1 indicates unknown total count
        count={-1}
        labelDisplayedRows={() => ''}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ disabled: isFetching }}
      />
    </>
  );
};

export default DocumentGenerationJobsTable;
