/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useStore } from 'common/store';
import UnsavedChanges from 'context/UnsavedChanges';
import ExcelExportService from 'services/excelExport';
import { useDocuments } from 'services/hooks';
import useResponse from './useResponse';
import { globalAction } from '../../common/actions';

export const useGetInvestorAppReport = () => {
  const service = new ExcelExportService();
  const [, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  const beginReportProcess = async (reportUUID, requestData) => {
    if (requestData) {
      dispatch(globalAction.showLoadingProgress(true));

      const defaultErrorMessage = 'An error occurred while exporting the Excel Report';
      try {
        successNotification('Export is being processed');
        await service.beginReportProcess(reportUUID, requestData);
        setAction(false);
      } catch (error) {
        const action = 'Process Excel Report';

        const errors = JSON.parse(error?.response?.text || '{}');
        processErrorResponse({
          error,
          defaultErrorMessage,
          action,
        });
        return errors;
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return beginReportProcess;
};

export const useCheckReportFile = () => {
  const service = new ExcelExportService();
  const [, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);
  const { downloadDocument } = useDocuments();

  const [doReportPollCheck, setDoReportPollCheck] = useState(false);
  const [reportRequestData, setReportRequestData] = useState(null);
  const [reportUUID, setReportUUID] = useState(null);

  useEffect(() => {
    if (doReportPollCheck && reportUUID && reportRequestData) {
      checkReport(reportUUID, reportRequestData, 0);
      return () => {};
    }
  }, [doReportPollCheck, reportUUID, reportRequestData]);

  const { processErrorResponse, successNotification, infoNotification } = useResponse();

  const maxAttempts = 24; //  24 attempts * 5 seconds = 120 seconds
  const pollInterval = 5000;

  const checkReport = async (reportUUID, requestData, attemps) => {
    if (requestData) {
      dispatch(globalAction.showLoadingProgress(true));

      const defaultErrorMessage = 'An error occurred while checking the Excel Report';
      const maxAttemptsMessage
        = 'Looks like the report is taking longer than expected. Please check the document\'s page later under the \'Reports\' folder.';
      const currentAttemps = attemps + 1;
      try {
        const reportFileData = await service.checkReport(reportUUID, requestData);
        successNotification('Excel is downloading');
        downloadDocument({
          file: reportFileData,
          firmId: requestData.firm,
        });
      } catch (error) {
        const errorResponse = await error.response;
        if (errorResponse.statusCode === 404 && attemps <= maxAttempts) {
          // the report is not ready yet
          setTimeout(() => {
            checkReport(reportUUID, requestData, currentAttemps);
            setAction(false);
          }, pollInterval);
        } else if (errorResponse.statusCode === 404 && attemps > maxAttempts) {
          // If we reach the max attempts (this means that we check the report along 120 seconds)
          // and the status is still 404, we show a message to the user
          // because the report is taking longer than expected and the file is not ready yet. Also
          // this could be that the report generation failed because of an error.
          // If the user see this message and after a while the report is not in the reports folder
          // that means that the report generation failed because a bug or something else.
          infoNotification(maxAttemptsMessage);
        } else {
          // If the error is different from 404, we show the error message to the user
          // this means a error occurred while checking the report, not in report generation
          const action = 'Excel Report';
          const errors = JSON.parse(error?.response?.text || '{}');
          processErrorResponse({
            error,
            defaultErrorMessage,
            action,
          });
          return errors;
        }
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return {
    doReportPollCheck,
    reportRequestData,
    reportUUID,
    setDoReportPollCheck,
    setReportRequestData,
    setReportUUID,
  };
};
