/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RefFileDocGen } from './RefFileDocGen';
import type { UserProfile } from './UserProfile';

export type RetrieveDocumentGenerationJob = {
  readonly id?: number;
  created_by: UserProfile;
  reference_files: Array<RefFileDocGen>;
  readonly created_at?: string;
  readonly updated_at?: string;
  is_deleted?: boolean | null;
  readonly deleted_at?: string | null;
  job_id: string;
  status?: RetrieveDocumentGenerationJob.status;
  results?: string | null;
  processing_type?: RetrieveDocumentGenerationJob.processing_type;
  company: number;
};

export namespace RetrieveDocumentGenerationJob {
  export enum status {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
  }

  export enum processing_type {
    CAP_TABLE = 'CAP_TABLE',
    FINANCIALS = 'FINANCIALS',
  }
}
