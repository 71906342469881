/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ApiCreateDocumentGenerationJob = {
  reference_files: Array<number>;
  processing_type?: ApiCreateDocumentGenerationJob.processing_type;
};

export namespace ApiCreateDocumentGenerationJob {
  export enum processing_type {
    CAP_TABLE = 'CAP_TABLE',
    FINANCIALS = 'FINANCIALS',
  }
}
