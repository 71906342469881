import { SHEET_TITLES_CONSTANTS } from 'pages/Valuations/approaches/guidelineCalibration/performance_metrics/config/index';
import { GPC } from '../../../../api';
import { NOT_APPLICABLE } from '../../../../common/constants/general';
import { Performances } from '../../../../common/types/valuation';
import { GenerateNTMRevenueGrowthExpressionParams } from '../guidelinePublicCompanies/PerformanceMetrics/config/customParser/types';

const { PERFORMANCE_METRICS_SPREADSHEET_COLUMNS } = SHEET_TITLES_CONSTANTS;

export function mapPerformancesToGPCWithCapIQ(performances: Performances[], existingGPCArray: GPC[]): GPC[] {
  return performances
    .filter(performance => performance.name !== null)
    .map(performance => {
      const matchedGPC = existingGPCArray.find(gpc => gpc.company_name === performance.name);

      return {
        ltm_ebitda: performance.ltm_ebitda?.toString() ?? null,
        ltm_revenue: performance.ltm_revenue?.toString() ?? null,
        ltm_revenue_growth: performance.ltm_revenue_growth?.toString() ?? null,
        ntm_ebitda: performance.ntm_ebitda?.toString() ?? null,
        ntm_revenue: performance.ntm_revenue?.toString() ?? null,
        ntm_revenue_growth: performance.ntm_revenue_growth?.toString() ?? null,
        ltm_ebitda_margin: performance.ebitda_margin?.toString() ?? null,
        ltm_gross_margin: performance.gross_margin?.toString() ?? null,
        enterprise_value: performance.enterprise_value ?? null,
        name: performance.name ?? 'Unnamed',
        cap_iq_id: matchedGPC?.cap_iq_id ?? null,
      };
    });
}

export const generateNTMRevenueGrowthExpression = (params: GenerateNTMRevenueGrowthExpressionParams): string => {
  const { alphabet, rowNumber } = params;

  // Column legend (-1 excluding Company Column)
  const columnLTMRevenueLegend = alphabet[PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE - 1];
  const columnNTMRevenueLegend = alphabet[PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE - 1];

  const columnLTMRevenueKey = columnLTMRevenueLegend + rowNumber;
  const columnNTMRevenueKey = columnNTMRevenueLegend + rowNumber;

  // NTM Revenue Growth formula expression
  const NTMRevenueGrowthExpression = `=IF(OR(${columnNTMRevenueKey} == 0, ${columnLTMRevenueKey} == 0), "${NOT_APPLICABLE}", (${columnNTMRevenueKey} / ${columnLTMRevenueKey}) - 1)`;

  return NTMRevenueGrowthExpression;
};
