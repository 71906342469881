import { isEmpty, isNil } from 'lodash';
import { NewAllocationScenario, ScenarioValues } from 'common/types/allocation';
import {
  ALLOCATION_ALLOCATION_METHOD_PREFIX,
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_CSE_KEY,
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHOD_OPM_KEY,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetTitles';
import {
  getAllocationBacksolve,
  getAllocationFutureExit,
  getAllocationSpecifiedShareValues,
  getBacksolveScenarioValues,
} from 'pages/ValuationsAllocation/util';
import { generateColumnKey, getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { CreateColumns, EquityAllocationColumn, EquityAllocationColumnCellData } from './types';

const {
  EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION,
  EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
  EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  EQUITY_ALLOCATION_SPREADSHEET_TOTAL,
  EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = SHEET_ALIASES_CONSTANTS;

const createColumns: CreateColumns = params => {
  const {
    allocationScenarios,
    allocationScenariosValues,
    approaches,
    isUniformCurrency,
    primaryCapTable,
    riskFreeRates,
  } = params;

  const { id: primaryCapTableId } = getObjectValue(primaryCapTable);

  const approachesColumns: EquityAllocationColumn[] = Array.from(
    { length: FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER - 1 },
    () => ({})
  );

  // If Cap Table and Financials are not the same currency, add Cap Table Enterprise Value to Column before Enterprise Value (Financials)
  if (!isUniformCurrency) approachesColumns.push({} as EquityAllocationColumn);

  // Allocation Scenarios
  const allocationScenariosColumns = getArrayValue(
    allocationScenarios.map(scenario => {
      const {
        approach_uuid: scenarioApproachId,
        cap_table_id: scenarioCapTable,
        exit_equity_value: futureEquityValue,
        id: scenarioId,
        maturity,
        scenario_method: scenarioMethod,
        scenario_ref: scenarioRef,
        scenario_type: scenarioType,
        scenario_values: scenariosValues,
        volatility,
        weighting_probability: weightingProbability,
      } = getObjectValue(scenario as NewAllocationScenario);

      const currentScenario = getObjectValue(scenario);

      // Scenario Id or Ref
      const scenarioIdOrRef = getStringValue(scenarioId ? scenarioId?.toString() : scenarioRef);

      // Risk Free Rate
      const riskFreeRate = getNumberValue(riskFreeRates?.[scenarioIdOrRef]);

      // Backsolve
      const { backsolveValuationApproach, isBacksolveWithOPM } = getAllocationBacksolve({
        approaches,
        scenario,
      });

      const {
        applied_methodologies: backsolveAppliedMethodologies,
        maturity: backsolveMaturity,
        opm_backsolve_date: backsolveDate,
        volatility: backsolveVolatility,
      } = getObjectValue(backsolveValuationApproach);

      const backsolveCapTable = backsolveAppliedMethodologies?.[0]?.cap_table;

      // Future Exit
      const { futureExitValuationApproach, isFutureExitWithOPM } = getAllocationFutureExit({
        approaches,
        scenario,
      });

      const {
        cap_table: futureExitCapTable,
        maturity: futureExitMaturity,
        volatility: futureExitVolatility,
      } = getObjectValue(futureExitValuationApproach);

      // Specified Share Values
      const { specifiedShareValuesApproach } = getAllocationSpecifiedShareValues({
        approaches,
        scenario,
      });

      const { cap_table: specifiedShareValuesCapTable } = getObjectValue(specifiedShareValuesApproach);

      const isApproachWithOPM = isBacksolveWithOPM || isFutureExitWithOPM;

      // Allocation Method
      let allocationMethodKey: string | null = null;

      // Set Allocation Method Key based on Scenario Type
      switch (scenarioType) {
        case ALLOCATION_SCENARIO_TYPE_BACKSOLVE:
        case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT:
        case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES:
          allocationMethodKey = !isNil(scenarioApproachId)
            ? generateColumnKey({
              id: getStringValue(scenarioApproachId),
              prefix: ALLOCATION_ALLOCATION_METHOD_PREFIX,
            })
            : null;
          break;

        // Handle Current Value Scenario Type
        default:
          switch (scenarioMethod) {
            case ALLOCATION_SCENARIO_METHOD_WATERFALL:
              allocationMethodKey = ALLOCATION_SCENARIO_METHOD_WATERFALL_KEY;
              break;

            case ALLOCATION_SCENARIO_METHOD_CSE:
              allocationMethodKey = ALLOCATION_SCENARIO_METHOD_CSE_KEY;
              break;

            case ALLOCATION_SCENARIO_METHOD_OPM:
              allocationMethodKey = ALLOCATION_SCENARIO_METHOD_OPM_KEY;
              break;

            default:
              break;
          }
          break;
      }

      // Get Scenario Values
      const updatedScenariosValues = allocationScenariosValues?.[
        generateColumnKey({
          id: scenarioIdOrRef,
          name: scenarioMethod?.toString(),
        })
      ] as ScenarioValues;

      // Backsolve Scenario Values
      const backsolveScenarioValues = getBacksolveScenarioValues({ allocationScenariosValues, approaches, scenario });

      // Securities Values
      const { aggregate_values: aggregateValues, present_values: presentValues } = getObjectValue(
        backsolveScenarioValues ?? updatedScenariosValues ?? scenariosValues
      );

      // If no Scenario Values, set Scenario Values to Backsolve Scenario Values or Updated Scenario Values
      if (isEmpty(scenariosValues)) {
        currentScenario.scenario_values = backsolveScenarioValues ?? updatedScenariosValues;
      }

      // Value Allocated to Security Class
      const valueAllocatedToSecurityClass = aggregateValues?.reduce((accumulator, current) => {
        const { security, value: securityValue } = current;
        const { name: securityName } = getObjectValue(security);

        return {
          ...accumulator,
          [generateColumnKey({
            name: securityName,
            prefix: EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
          })]: {
            value: getNumberValue(securityValue),
          } as EquityAllocationColumnCellData,
        };
      }, {} as EquityAllocationColumn);

      // Present Value Per Share
      const presentValuePerShare = presentValues?.reduce((accumulator, current) => {
        const { security, value: securityValue } = current;
        const { name: securityName } = getObjectValue(security);

        return {
          ...accumulator,
          [generateColumnKey({
            name: securityName,
            prefix: EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
          })]: {
            value: getNumberValue(securityValue),
          } as EquityAllocationColumnCellData,
        };
      }, {} as EquityAllocationColumn);

      return {
        ...valueAllocatedToSecurityClass,
        ...presentValuePerShare,
        [EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD]: {
          backsolveDate,
          isApproachWithOPM,
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: allocationMethodKey,
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: getNumberValue(
            backsolveCapTable
              ?? futureExitCapTable
              ?? specifiedShareValuesCapTable
              ?? scenarioCapTable
              ?? primaryCapTableId
          ),
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: null,
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_MATURITY]: {
          backsolveDate,
          isApproachWithOPM,
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: getNumberValue(backsolveMaturity ?? futureExitMaturity ?? maturity),
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE]: {
          backsolveDate,
          isApproachWithOPM,
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: riskFreeRate,
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY]: {
          isApproachWithOPM,
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: getNumberValue(backsolveVolatility ?? futureExitVolatility ?? volatility),
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: getNumberValue(futureEquityValue),
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: null,
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: getNumberValue(weightingProbability),
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: null,
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: null,
        } as EquityAllocationColumnCellData,
        [EQUITY_ALLOCATION_SPREADSHEET_TOTAL]: {
          scenarioId,
          scenarioMethod,
          scenarioRef,
          scenarioType,
          value: null,
        } as EquityAllocationColumnCellData,
      } as EquityAllocationColumn;
    })
  );

  return [...approachesColumns, ...allocationScenariosColumns];
};

export default createColumns;
