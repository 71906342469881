import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useQuery } from '@tanstack/react-query';
import { ApiService, RetrieveDocumentGenerationJob } from 'api';
import { useStore } from 'common/store';
import { useResponse } from 'services/hooks';
import { formatDate } from 'utillities';
import { titleCaseEnumValue } from 'utillities/titleCaseEnumValue';
import DocumentGenerationJobTableItemProps from './DocumentGenerationJobTableItem.props';
import { StatusChip } from './DocumentGenerationJobTableItem.styles';
import { QUERY_CONSTANTS, RESULTS_DISPLAY, TABLE_ROW_CONSTANTS } from '../../../constants';
import ResultsButton from '../../ResultsButton/ResultsButton';
import ResultsDisplayModal from '../../ResultsDisplayModal/ResultsDisplayModal';

const DocumentGenerationJobsTableItem = ({ generationJob }: DocumentGenerationJobTableItemProps) => {
  const [resultsDisplayModalOpen, setResultsDisplayModalOpen] = useState<boolean>(false);
  const [currentGenerationJob, setCurrentGenerationJob] = useState<RetrieveDocumentGenerationJob>(generationJob);
  const DEFAULT_REFETCH_INTERVAL_IN_MS = 10_000;
  const [{ companyInfo }] = useStore() as any;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { successNotification, errorNotification } = useResponse();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      errorNotification(TABLE_ROW_CONSTANTS.ERROR_COPYING);
    }
  };

  const { apiCompanyDocumentGenerationJobsRead } = ApiService;

  const { data } = useQuery({
    queryKey: [QUERY_CONSTANTS.getDocumentGenerationJobById, companyInfo.id, currentGenerationJob.job_id],
    queryFn: async (): Promise<RetrieveDocumentGenerationJob> => {
      const response = await apiCompanyDocumentGenerationJobsRead(companyInfo.id, currentGenerationJob.job_id);
      return response as any;
    },
    refetchInterval:
      currentGenerationJob.status === RetrieveDocumentGenerationJob.status.COMPLETED
        ? false
        : DEFAULT_REFETCH_INTERVAL_IN_MS,
  });

  const toggleResultsModal = useCallback(() => {
    setResultsDisplayModalOpen(prev => !prev);
  }, []);

  useEffect(() => {
    if (!data) return;

    setCurrentGenerationJob(data);
  }, [data]);

  return (
    <>
      {currentGenerationJob.results && currentGenerationJob.results?.length > 8 && (
        <ResultsDisplayModal
          onClose={toggleResultsModal}
          open={resultsDisplayModalOpen}
          resultsJsonString={currentGenerationJob.results}
        />
      )}
      <TableRow>
        <TableCell>
          <StatusChip
            avatar={
              currentGenerationJob.status !== RetrieveDocumentGenerationJob.status.COMPLETED
              && currentGenerationJob.status !== RetrieveDocumentGenerationJob.status.FAILED ? (
                  <CircularProgress size={'1rem'} color={'secondary'} />
                ) : (
                  <></>
                )
            }
            label={titleCaseEnumValue(currentGenerationJob.status!)}
          />
        </TableCell>
        <TableCell>
          <Typography>{titleCaseEnumValue(currentGenerationJob.processing_type!)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {currentGenerationJob.created_by.first_name} {currentGenerationJob.created_by.last_name}
          </Typography>
        </TableCell>
        <TableCell>
          {currentGenerationJob.created_at && (
            <Typography>{formatDate({ date: new Date(currentGenerationJob.created_at) })}</Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {currentGenerationJob.reference_files.map(i => `${i.filename}.${i.file_type}`).join('\n')}
          </Typography>
        </TableCell>
        <TableCell width={'30%'} style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
          {currentGenerationJob.results && currentGenerationJob.results?.length > 8 ? (
            <ResultsButton
              onClick={toggleResultsModal}
              jsonResultsString={currentGenerationJob.results}
              processingType={currentGenerationJob.processing_type!}
            />
          ) : (
            <Typography variant="caption" style={{ fontSize: '1rem' }}>
              {currentGenerationJob.status === RetrieveDocumentGenerationJob.status.FAILED
                ? RESULTS_DISPLAY.FAILURE_CONTENT
                : RESULTS_DISPLAY.PENDING_RESULTS_CONTENT(currentGenerationJob.reference_files.length)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                copyToClipboard(currentGenerationJob.job_id);
                successNotification(TABLE_ROW_CONSTANTS.COPY_SUCCESS);
                handleClose();
              }}>
              {TABLE_ROW_CONSTANTS.COPY_ID}
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DocumentGenerationJobsTableItem;
