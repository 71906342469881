export const DOCUMENTS_TITLE = 'Documents';
export const DOCUMENTS_SLUG = 'documents';
export const DOCUMENT_GENERATION_JOBS_SLUG = 'document-generation-jobs';
export const DOCUMENT_GENERATION_JOBS_TITLE = 'Document Generation Jobs';
export const MEASUREMENT_DATE_TITLE = 'Measurement Date';
export const CURRENT_DOCUMENTS_LABEL = 'Selected Measurement Date';
export const RECENT_LABEL = 'Recent Documents';
export const PREVIOUS_LABEL = 'Previous Documents';
export const EMPTY_TABLE = 'There are no documents to display';
export const ADD_FIRST_FILE = 'Please add a file';
export const ADD_SUBFOLDER_LABEL = 'Add Subfolder';
export const CREATING_SUBFOLDER = 'Creating subfolder...';
export const NO_DOCUMENTS_FOR_THIS_COMPANY = 'The company does not have any documents.';
export const ADD_NEW_DOCUMENT = 'Add New Document';
export const ADD_NEW_FOLDER = 'Add New Folder';
export const REQUEST_NEW_DOCUMENTS = 'Request New Documents';
export const SUBTITLES_FOR_DIALOGS = item => `The ${item} will be added to the currently selected measurement date.`;

// constants used for drag and drop functions
export const DRAGGING = 'dragging';
export const DRAGGING_ELEMENT = 'dragging_element';
export const DRAGGING_ELEMENT_TYPE = 'dragging_element_type';
export const DRAG_OVER = 'drag-over';
export const DROPPABLE = 'droppable';
export const DRAGGABLE = 'draggable';
export const FILE = 'file';
export const FOLDER = 'folder';

// constants used for request documents
export const HAS_REQUESTED_FROM = 'has requested documents from';
export const THESE_DOCUMENTS_ARE_NEEDED = 'These documents are needed to complete a valuation of the fund.';
export const IS_USING_SCALAR_FOR_THEIR_PORTFOLIO_VALUATIONS
  = 'is using Scalar for their portfolio valuations. For your convenience, Scalar provides a secure file upload portal that will upload files directly to the current valuation.';
export const EXPIRES_ON = expirationDateFormatted => `This secure upload link expires on ${expirationDateFormatted}.`;
export const PLEASE_UPLOAD_THE_FOLLOWING_DOCUMENTS = 'Please upload the following documents';
export const FOOTER = firmName =>
  `Your files will be uploaded securely to ${firmName}'s Scalar account. This secure upload portal is the preferred method for collecting these documents. All uploaded documents are encrypted in transit and at rest with 256-bit Advanced Encryption Standard (AES-256). Only users at ${firmName} will have access to your documents. Do not share this with unauthorized users.`;
export const SWITCH_LABEL = 'Done uploading all necessary documents';
export const DOCUMENT_REQUEST_EXPIRED = 'This document request upload link is not valid or has already expired.';
export const MEASUREMENT_DATE_HEADER = 'Measurement Date';
export const UPLOADS_COMPLETED = 'Uploads for this document request have already been completed.';
export const ARE_YOU_SURE_YOU_ARE_DONE_UPLOADING_FILES = 'Are you sure you are done uploading files?';
export const DONE_UPLOADING_FILES = 'You will need to get a new document request link to upload more files later.';
export const ADD_ANOTHER_DOCUMENT = 'Add another document';
