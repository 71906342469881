import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useResponse } from 'services/hooks';
import ResultsButtonProps from './ResultsButton.props';
import { RESULTS_DISPLAY } from '../../constants';
import AIIcon from '../assets/AIIcon';

const ResultsButton = ({ jsonResultsString, processingType, onClick }: ResultsButtonProps) => {
  const [securityCount, setSecurityCount] = useState(0);
  const { errorNotification } = useResponse();

  useEffect(() => {
    try {
      const parsedResults = JSON.parse(jsonResultsString);
      const securityNames = parsedResults.data.securityData.map((i: any) => i.securityName);
      const securityNameCount = securityNames.length;
      setSecurityCount(securityNameCount);
    } catch (e) {
      errorNotification(RESULTS_DISPLAY.ERROR_PARSING_RESULTS);
    }
  }, [jsonResultsString, errorNotification]);

  return (
    <div>
      <Typography>{RESULTS_DISPLAY.RESULTS_COUNT(securityCount, processingType)}</Typography>
      <Button onClick={onClick} variant="outlined" color="primary">
        <span style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          <AIIcon fill={'#037DE8'} />
          {RESULTS_DISPLAY.BUTTON_CONTENT}
        </span>
      </Button>
    </div>
  );
};

export default ResultsButton;
