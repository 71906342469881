import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GridDate as GridDateBase } from 'components/Grid';
import FeaturedSpreadsheetContext from '../../../../../components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';

const CalibrationGridDate = ({ cell, minDate }) => {
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const handleOnChange = date => {
    onCellsChanged([{ cell, value: date }]);
  };

  const { value, readOnly, disabled } = cell;

  return (
    <GridDateBase
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      onCellsChanged={handleOnChange}
      minDate={minDate}
    />
  );
};

CalibrationGridDate.propTypes = {
  cell: PropTypes.object,
  minDate: PropTypes.string,
};

export default CalibrationGridDate;
