import React from 'react';
import { Grid, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { NO_REFERENCED_DOCUMENTS } from 'common/constants/documents';
import useDocumentsContext from 'context/DocumentsContext';
import theme from 'theme';
import PendingReference from './PendingReference';
import ReferencedDocument from './ReferencedDocument';

const useStyles = makeStyles({
  listItem: {
    padding: 0,
  },
  noReferencedDocuments: {
    margin: '3rem 0',
    fontWeight: 'bold',
    color: theme.palette.grey[500],
  },
  fileName: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
});

const ReferencedDocumentsList = ({
  documents,
  selectedMeasurementDate,
  currentPage,
  isDisabled,
  areDocumentsActionsDisabled,
}) => {
  const classes = useStyles();
  const { filesToSave } = useDocumentsContext();

  if (isEmpty(documents) && isEmpty(filesToSave)) {
    return (
      <Typography align="center" className={classes.noReferencedDocuments}>
        {NO_REFERENCED_DOCUMENTS}
      </Typography>
    );
  }

  return (
    <List style={{ margin: '1rem 0' }}>
      <Grid container style={{ marginBottom: '1rem' }}>
        {documents.map(document => (
          <Grid key={document.document_reference_id} item xs={12} md={6}>
            <ListItem className={classes.listItem} button={!areDocumentsActionsDisabled}>
              <ReferencedDocument
                document={document}
                selectedMeasurementDateId={selectedMeasurementDate.id}
                currentPage={currentPage}
                isDisabled={isDisabled}
                areDocumentsActionsDisabled={areDocumentsActionsDisabled}
              />
            </ListItem>
          </Grid>
        ))}
      </Grid>
      {!isEmpty(filesToSave)
        && filesToSave.map(({ document_reference_id, filename, file_type }) => (
          <PendingReference key={document_reference_id} filename={filename} fileType={file_type} />
        ))}
    </List>
  );
};

ReferencedDocumentsList.propTypes = {
  documents: PropTypes.array,
  selectedMeasurementDate: PropTypes.object,
  currentPage: PropTypes.string,
  isDisabled: PropTypes.bool,
  areDocumentsActionsDisabled: PropTypes.bool,
};

export default ReferencedDocumentsList;
