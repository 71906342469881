import React, { useCallback, useContext, useMemo } from 'react';
import { isEmpty, toString } from 'lodash';
import PropTypes from 'prop-types';
import createColumnsCalibrationPerformance from 'pages/Valuations/approaches/guidelineCalibration/performance_metrics/config/createColumns/createColumnsCalibrationPerformance';
import rowConfigCalibrationPerformance from 'pages/Valuations/approaches/guidelineCalibration/performance_metrics/config/rowConfig/rowConfigCalibrationPerformance';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { getObjectValue } from 'utillities';
import { performanceKeys } from '../../../../../common/constants/calibration';
import { useFormat } from '../../../../../common/hooks';
import { useStore } from '../../../../../common/store';
import { CustomSelect, withTooltip } from '../../../../../components';
import FeaturedSpreadsheetContext from '../../../../../components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import CalibrationContext from '../../../../../context/CalibrationContext';
import useUpdateCustomDatePerformanceMetrics from '../../guidelinePublicCompanies/utils/useUpdateCustomDatePerformanceMetrics';
import { getAllCustomDatePerformanceMetricsCalibration } from '../../guidelinePublicCompanies/utils/utilities';
import getRowConfigCalibrationOutputs from '../Outputs/getRowConfigCalibrationOutputs';
import rowTransformerCalibrationOutputs from '../Outputs/rowTransformerCalibrationOutputs';
import { getFinancials, PerformanceCalculator, updateCompsWithMetrics as updateCompsWithMetricsUtil } from '../utils';

const ApproachesList = ({ cell, columnsPerformanceCalibration, rowsPerformanceCalibration, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const [globalStore] = useStore();
  const { valuationInfo } = globalStore;
  const { valuations_approaches: valuationsApproaches } = valuationInfo;
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);
  const {
    approaches,
    calibrationPerformanceSheet,
    calibrationOutputSheet,
    company,
    customClasses,
    financialStatementsList,
    calibration,
  } = useContext(CalibrationContext);
  const { resetConfiguration, gpcAttributes } = useContext(ValuationContext);
  const [format] = useFormat();

  const updateCompsWithMetrics = useCallback(
    (compsWithMetrics, correspondingCompsWithMetrics) =>
      updateCompsWithMetricsUtil(compsWithMetrics, correspondingCompsWithMetrics),
    []
  );

  const { getCompsWithCustomDatePerformanceMetricsCalibration, validateCustomDatePerformanceMetrics }
    = useUpdateCustomDatePerformanceMetrics({
      format,
      gpcAttributes,
      gpcComparison: {},
    });

  const handleChange = async value => {
    const newValue = toString(value);

    if (newValue !== '') {
      const approachNew = approaches.find(item => item.panelId === newValue);
      const { valuations_approach_gpc: valuationsApproachGpc } = getObjectValue(approachNew);
      calibration.approach = approachNew;
      const UpdatedApproach = approachNew;

      const financials = getFinancials(cell.sheet.tableData.calibration.financials_version, financialStatementsList);

      const asOfDate = calibration.calibration_date;

      let columnsPerformance = [];
      let rowConfig = [];

      if (calibration.calibration_date !== '') {
        const tmpCompsWithCustomDatePerformanceMetrics = await getCompsWithCustomDatePerformanceMetricsCalibration(
          asOfDate,
          UpdatedApproach?.valuations_approach_gpc?.gpc_comparison
        );
        const compsWithValidatedCustomDatePerformanceMetrics = validateCustomDatePerformanceMetrics(
          tmpCompsWithCustomDatePerformanceMetrics
        );
        const updatedCompsWithMetrics = updateCompsWithMetrics(
          compsWithValidatedCustomDatePerformanceMetrics,
          UpdatedApproach?.valuations_approach_gpc?.gpc_comparison ?? {}
        );
        const customDatePerfMetrics = getAllCustomDatePerformanceMetricsCalibration(updatedCompsWithMetrics, asOfDate);
        const performanceMetricsAsOfDateCommonRowConfig = {
          asOfDate,
          company,
          customClasses,
          gpcComparisonByDate: !isEmpty(customDatePerfMetrics) ? customDatePerfMetrics : {},
        };

        columnsPerformance = createColumnsCalibrationPerformance({
          gpcComparisonByDate: !isEmpty(customDatePerfMetrics) ? customDatePerfMetrics : {},
          financials,
        });

        rowConfig = rowConfigCalibrationPerformance({
          ...performanceMetricsAsOfDateCommonRowConfig,
        });
      } else {
        const columns = {
          valuationsApproachGpc,
          financials,
        };

        rowConfig = rowConfigCalibrationPerformance({ company, customClasses, valuationsApproachGpc });
        columnsPerformance = createColumnsCalibrationPerformance(columns);
      }

      const performanceMetricsKeys = columnsPerformance.filter(item =>
        Object.keys(item).some(key => key.includes('performance'))
      );

      const numRows = performanceMetricsKeys.length;
      const numCols = Object.keys(performanceMetricsKeys[0]).length;
      const idCalibration = cell.sheet.tableData.calibration.id;
      calibration.performances = [];

      calibration.performances = PerformanceCalculator({
        numCols,
        numRows,
        performanceMetricsKeys,
        performanceKeys,
        idCalibration,
        calibration,
      });
      columnsPerformance.splice(0, 1);
      calibrationPerformanceSheet.reset({
        rowConfig,
        columns: columnsPerformance,
      });

      const rowConfigOutputs = getRowConfigCalibrationOutputs({
        calibration,
        columnsPerformance,
      });

      const columnsOutputs = rowTransformerCalibrationOutputs([]);

      calibrationOutputSheet.reset({
        rowConfig: rowConfigOutputs,
        columns: columnsOutputs,
      });

      onCellsChanged([{ cell, value: newValue }]);

      resetConfiguration();
    }
  };

  const getOptions = useMemo(() => {
    const tmpOptions = [];
    if (Array.isArray(valuationsApproaches)) {
      valuationsApproaches
        .filter(option => option.approach_type === 'Guideline Public Companies')
        .forEach(option => {
          tmpOptions.push({
            value: option.id,
            label: option.name,
          });
        });
    }

    return tmpOptions;
  }, [valuationsApproaches]);

  return (
    <CustomSelect
      id={`approaches-list-${cell.key}`}
      value={cell.value}
      options={getOptions}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      onEmptyValue="SELECT-APPROACH"
      disabled={cell.readOnly}
      disableUnderline
    />
  );
};

ApproachesList.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

ApproachesList.propTypes = {
  cell: PropTypes.object,
  columnsPerformanceCalibration: PropTypes.object,
  rowsPerformanceCalibration: PropTypes.object,
};

export default withTooltip(ApproachesList);
