import React, { FC, useContext, useEffect, useMemo } from 'react';
import { AppBar, Grid, LinearProgress, Toolbar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { isEmpty, isNil } from 'lodash';
import { useParams } from 'react-router-dom';
import { COMPANY_TYPE, FIRM_TYPE, FUND_TYPE } from 'common/constants/pageTypes';
import { VALUATIONS_SLUG } from 'common/constants/valuations';
import { useStore } from 'common/store';
import { Currency } from 'common/types/format';
import { LayoutContextValues, UseStoreValues } from 'common/types/store';
import { ActionButtons, Breadcrumbs, ExtraActionsMenu, HorizontalNavigation, ValueCard } from 'components';
import { DEFAULT_CURRENCY, REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import LayoutContext from 'context/LayoutContext';
import ExcelExportDialog from 'layouts/Main/components/ExcelExportDialog';
import { useGetAllocationValuesByCompany } from 'services/hooks/companies';
import { useAllocationValuesStore } from 'store';
import { getBooleanValue, getNumberValue, getStringValue } from 'utillities';
import { Title } from './components';
import useStyles from './styles';
import { CompanyParams, HeaderProps, ValueCardsProps } from './types';

const EQUITY_VALUE_CARD_TITLE = 'Equity Value';
const FIRM_TOTAL_CARD_TITLE = 'Firm Total';

const LIVE_VALUE_CARDS = [VALUATIONS_SLUG];
const VALID_PAGE_TYPES_TO_EXPORT = [COMPANY_TYPE, FIRM_TYPE, FUND_TYPE];

const ValueCards = (props: ValueCardsProps) => {
  const setCurrentAllocationId = useAllocationValuesStore(state => state.setCurrentAllocationId);

  const { company, measurementDate, pageType } = props;

  const { tableSlugParam } = useParams<CompanyParams>();

  const currentAllocationVersion = useAllocationValuesStore(state => state.currentAllocationVersion);

  const shouldRenderValueCards = useMemo(
    () => [COMPANY_TYPE].includes(getStringValue(pageType)) && !isEmpty(company) && !isEmpty(measurementDate),
    [company, measurementDate, pageType]
  );

  const isValidCompanyMeasurementDate = useMemo(
    () => measurementDate?.company_id === company?.id,
    [company, measurementDate]
  );

  const { data: allocationValues, isLoading } = useGetAllocationValuesByCompany({
    company,
    measurementDate,
    version: currentAllocationVersion,
    shouldQueryAutomatically: shouldRenderValueCards && isValidCompanyMeasurementDate,
  });

  useEffect(() => {
    if (!isNil(allocationValues?.id)) setCurrentAllocationId?.(allocationValues?.id);
  }, [allocationValues, setCurrentAllocationId]);

  const isValuationAllocationPage = useMemo(() => LIVE_VALUE_CARDS.includes(tableSlugParam), [tableSlugParam]);

  const allocationValuesEquityValue = useMemo(() => Number(allocationValues?.equity_value ?? 0), [allocationValues]);

  const allocationValuesFirmTotal = useMemo(
    () => Number(allocationValues?.fund_conclusions_totals?.firm_total ?? 0),
    [allocationValues]
  );

  const currentEquityValue = useAllocationValuesStore(state => state.currentEquityValue);
  const currentFirmTotal = useAllocationValuesStore(state => state.currentFirmTotal);

  const companyCurrency = useMemo(
    () =>
      ({
        code: company?.financials_currency ?? DEFAULT_CURRENCY,
        units: REGULAR_UNIT,
      } as Currency),
    [company]
  );

  const isLoadingLiveValues = useMemo(
    () =>
      isValuationAllocationPage
        ? isNil(currentEquityValue) || isNil(currentFirmTotal)
        : isNil(currentEquityValue) || isLoading,
    [currentEquityValue, currentFirmTotal, isValuationAllocationPage, isLoading]
  );

  const shouldDisplayLiveValues = useMemo(
    () => LIVE_VALUE_CARDS.includes(getStringValue(tableSlugParam)),
    [tableSlugParam]
  );

  const equityValue = useMemo(
    () => (shouldDisplayLiveValues ? getNumberValue(currentEquityValue) : allocationValuesEquityValue),
    [allocationValuesEquityValue, currentEquityValue, shouldDisplayLiveValues]
  );

  const firmTotal = useMemo(() => {
    if (shouldDisplayLiveValues)
      return getNumberValue(isValuationAllocationPage ? currentFirmTotal : allocationValuesFirmTotal);

    return allocationValuesFirmTotal;
  }, [allocationValuesFirmTotal, currentFirmTotal, isValuationAllocationPage, shouldDisplayLiveValues]);

  if (!shouldRenderValueCards) return null;

  // Display Header Cards on Companies pages
  return (
    <>
      {/* Equity Value */}
      <ValueCard
        currency={companyCurrency}
        isLoading={shouldDisplayLiveValues ? isLoadingLiveValues : isLoading}
        title={EQUITY_VALUE_CARD_TITLE}
        value={equityValue}
      />
      {/* Firm Total */}
      <ValueCard
        currency={companyCurrency}
        isLoading={shouldDisplayLiveValues ? isLoadingLiveValues : isLoading}
        title={FIRM_TOTAL_CARD_TITLE}
        value={firmTotal}
      />
    </>
  );
};

const Header: FC<HeaderProps> = props => {
  const { children } = props;

  const {
    currentMeasurementDate,
    extraPageActions,
    navItems,
    openExportDialog,
    pageActions,
    pageBreadcrumbs,
    pageTitle,
    pageType,
    toggleExportDialog,
  } = useContext(LayoutContext) as unknown as LayoutContextValues;

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { companyInfo, isShowLoadingProgress } = storeValue;

  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.root} color="inherit" elevation={1} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Grid alignItems="center" container direction="row" justifyContent="space-between" wrap="nowrap">
            {/* Title and Breadcrumbs */}
            <Grid className={classes.titleContainer} item>
              {/* Title */}
              {pageTitle ? <Title pageTitle={pageTitle} /> : <Skeleton height={50} variant="text" width={550} />}
              {/* BreadCrumbs */}
              {pageBreadcrumbs && <Breadcrumbs />}
            </Grid>

            {/* Values Cards and Filters */}
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                wrap="nowrap">
                {/* Value Cards */}
                <ValueCards company={companyInfo} measurementDate={currentMeasurementDate} pageType={pageType} />

                {/* Filters */}
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>

        {/* Navigation */}
        {!isEmpty(navItems) && (
          <HorizontalNavigation>
            <>
              {pageActions && <ActionButtons />}
              {extraPageActions && <ExtraActionsMenu />}
            </>
          </HorizontalNavigation>
        )}
      </AppBar>

      {/* Loading Progress Bar */}
      {!isEmpty(navItems) && (
        <Grid className={classes.progressBar}>{isShowLoadingProgress && <LinearProgress color="primary" />}</Grid>
      )}

      {/* Excel Export */}
      {VALID_PAGE_TYPES_TO_EXPORT.includes(getStringValue(pageType)) && (
        <ExcelExportDialog onClose={toggleExportDialog} open={getBooleanValue(openExportDialog)} />
      )}
    </>
  );
};

export default Header;
